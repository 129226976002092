import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconAdd: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1276 3.18012C16.8152 3.18012 16.5092 3.19444 16.2067 3.22218C12.2585 3.59535 7.82253 4.86698 4.86672 11.473C4.79424 11.6171 1.10909 18.8693 4.4515 24.7585C4.45507 24.7648 4.45865 24.7719 4.46223 24.7782C7.01534 29.4585 10.279 32.2049 14.1619 32.9396C20.419 34.1226 26.2232 29.845 26.6143 29.5453C26.676 29.4871 26.744 29.4343 26.8174 29.3878C34.6575 24.4274 33.5165 17.5457 33.4646 17.2549C33.4485 17.1538 32.8176 12.9684 29.4958 9.32262C26.0505 5.5632 21.1966 3.18012 17.1276 3.18012ZM16.3275 35.8354C11.624 35.8363 6.2708 33.6949 2.11137 26.074C-1.95142 18.9006 2.26976 10.6515 2.45232 10.3025C5.99607 2.37562 11.8567 0.935744 15.9544 0.550048C21.0695 0.0668091 27.1592 2.79979 31.4771 7.51137C35.385 11.8006 36.0866 16.624 36.1144 16.8271C36.1716 17.1189 37.6742 25.6194 28.3664 31.5838C27.9843 31.9266 27.3176 32.3731 26.1543 33.0299C24.9211 33.7262 22.9658 34.6792 20.6525 35.269C19.3227 35.6081 17.8587 35.8354 16.3275 35.8354Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7584 26.934C17.7115 26.934 17.6646 26.9318 17.6166 26.9284C16.6951 26.8513 16.0104 26.0415 16.0863 25.1189C16.0897 25.0798 16.4125 21.1626 16.3052 18.3969C16.1936 15.81 16.2997 11.2259 16.3041 11.0338C16.3265 10.1212 17.0726 9.3974 17.9785 9.3974H18.0187C18.9447 9.41974 19.6774 10.1871 19.6551 11.112C19.654 11.1589 19.5467 15.7787 19.654 18.2606C19.769 21.2396 19.4406 25.2306 19.4261 25.3981C19.3535 26.2727 18.6196 26.934 17.7584 26.934Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50039 18.0045C9.50039 17.0797 10.251 16.329 11.1759 16.329C11.33 16.329 14.9669 16.3313 17.4712 16.5591C19.8928 16.7714 24.4545 16.5569 24.5003 16.5547C25.4241 16.51 26.2093 17.2249 26.2529 18.1486C26.3132 19.0913 25.5827 19.8576 24.6589 19.9011C24.4635 19.9112 19.8246 20.1279 17.174 19.8967C14.8127 19.6822 11.2116 19.68 11.1759 19.68C10.251 19.68 9.50039 18.9294 9.50039 18.0045Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import { HTMLAttributes, HTMLElement } from 'shared/types/react.d'
import { ButtonBorder1 } from './borders/ButtonBorder1'
import { ButtonBorder2 } from './borders/ButtonBorder2'
import { CardBorder1 } from './borders/CardBorder1'
import { CardBorder2 } from './borders/CardBorder2'
import { CircleBorder1 } from './borders/CircleBorder1'
import { CircleBorder2 } from './borders/CircleBorder2'
import { ModalBorder } from './borders/ModalBorder'
import { OptionBorder1 } from './borders/OptionBorder1'
import { OptionBorder2 } from './borders/OptionBorder2'
import { SelectBorder1 } from './borders/SelectBorder1'
import { SelectBorder2 } from './borders/SelectBorder2'
import { TitleBorder } from './borders/TitleBorder'

export interface BorderPropsModel extends HTMLAttributes<HTMLElement> {
  type: BorderType
  stroke?: string
  fill?: string
  image?: string
  disabled?: boolean
  dropShadow?: boolean
  transform?: boolean
  withTitle?: boolean
  titleColor?: string
  titleHeight?: number
  wideTitle?: boolean
}

export enum BorderType {
  Button1 = 'Button1',
  Button2 = 'Button2',
  Card1 = 'Card1',
  Card2 = 'Card2',
  Circle1 = 'Circle1',
  Circle2 = 'Circle2',
  Modal = 'Modal',
  Option1 = 'Option1',
  Option2 = 'Option2',
  Select1 = 'Select1',
  Select2 = 'Select2',
  Title = 'Title'
}

export const borderComponents = {
  Button1: ButtonBorder1,
  Button2: ButtonBorder2,
  Card1: CardBorder1,
  Card2: CardBorder2,
  Circle1: CircleBorder1,
  Circle2: CircleBorder2,
  Modal: ModalBorder,
  Option1: OptionBorder1,
  Option2: OptionBorder2,
  Select1: SelectBorder1,
  Select2: SelectBorder2,
  Title: TitleBorder
}

import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'
import React, { useEffect, useState } from 'react'
import { TimeClockPropsModel } from './TimeClock.model'
import styles from './TimeClock.module.scss'

export const TimeClock: React.FC<TimeClockPropsModel> = (props) => {
  const { start, finish } = props

  const [hour, setHour] = useState<number>(start.hour)
  const [minutes, setMinutes] = useState<number>(start.minutes)
  const [seconds, setSeconds] = useState<number>(start.seconds)

  const renderTimer = () => {
    let hourText: string | undefined = hour.toString()
    let minutesText = minutes.toString()
    let secondsText = seconds.toString()

    if (hour < 10) {
      if (hour === 0) {
        hourText = undefined
      } else {
        hourText = `0${hourText}`
      }
    }

    if (minutes < 10) {
      minutesText = `0${minutesText}`
    }

    if (seconds < 10) {
      secondsText = `0${secondsText}`
    }

    return `${hourText ? hourText + ':' : ''}${minutesText}:${secondsText}`
  }

  useEffect(() => {
    if (!finish) {
      setTimeout(() => {
        if (seconds === 59) {
          if (minutes === 59) {
            setHour(hour + 1)
            setMinutes(0)
          } else {
            setMinutes(minutes + 1)
          }
          setSeconds(0)
        } else {
          setSeconds(seconds + 1)
        }
      }, 1000)
    }
  })

  return (
    <div className={styles.timeclock}>
      <Icon icon={IconType.Clock} size={36} />
      <p>{renderTimer()}</p>
    </div>
  )
}

import {
  FollowUpQuestionModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel,
  SpecialEventModel,
  SurveyDataScreenModel
} from 'api/client.model'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import { OptionalButton } from 'components/OptionalButtons/OptionalButtons.model'
import { PieChartPiece } from 'components/PieChart/PieChart.model'
import { AudioContextProps } from 'context/AudioContext'
import { MultipleChoiceOptionsModel } from 'screens/survey/MultipleChoiceImageScreen/MultipleChoiceImageScreen.model'
import { genericObject } from 'screens/survey/type'

export type DataOptionVoices = MultipleChoiceModel &
  OptionalButton &
  PieChartPiece

/**
 * Load a voices object and set to context
 * @param ctx Audio files object
 * @param key Root key to use for audio urls
 * @param voices Voices object of audio files to load
 */
export const loadVoices = (
  ctx: AudioContextProps,
  key: string,
  voices?: genericObject | null
): void => {
  if (voices) {
    for (const [voicepiece, url] of Object.entries(voices || {})) {
      if (!ctx.files[key]) {
        ctx.files[key] = {}
      }

      if (!ctx.files[key][voicepiece]) {
        ctx.files[key][voicepiece] = url
      }
    }
  }
}

/**
 * Load audio for an array of options
 * @param ctx Audio files object
 * @param key Key tto append to data object id for audio urls reference
 * @param options Option array with voices to load
 */
export const loadOptionsAudio = (
  ctx: AudioContextProps,
  key: string,
  options: DataOptionVoices[]
): void => {
  if (options?.length) {
    options?.forEach((option) => {
      if (option?.voices && option?.id) {
        loadVoices(ctx, `${option.id}-${key}`, option.voices)
      }

      // Catch follow up data model in Outro Effects Pie chart
      if (option?.followUp) {
        loadVoices(ctx, `${option.id}-${key}-followup`, option.followUp?.voices)

        loadDataAudio(ctx, option?.followUp, `${option.id}-${key}-followup`)
      }
    })
  }
}

/**
 * Load audio from different question/screen types
 * @param ctx Audio files object
 * @param data Screen data object
 * @param key Root key to use for audio urls
 */
export const loadDataAudio = (
  ctx: AudioContextProps,
  data: any,
  key: string
): void => {
  // Load MC question option voices
  const options =
    data?.multipleChoice?.options ||
    data?.effectsPieChart?.options ||
    data?.symptomsCircle?.options ||
    data?.outroTips?.options

  if (options?.length) {
    loadOptionsAudio(ctx, 'option', options)
  }

  // Load MC Iamge question option voices for each option group
  if (data?.multipleChoiceImage?.optionGroups?.length) {
    data?.multipleChoiceImage?.optionGroups?.forEach(
      (optionGroup: MultipleChoiceOptionsModel) => {
        if (optionGroup?.options?.length) {
          loadOptionsAudio(ctx, 'option', optionGroup?.options)
        }
      }
    )
  }

  // Set optional buttons from different question contexts
  const optionalButtons =
    data?.slider?.optionalButtons ||
    data?.dynamicSlider?.optionalButtons ||
    data?.multipleChoice?.optionalButtons ||
    data?.multipleChoiceImage?.optionalButtons

  // Load optional button voices
  if (optionalButtons?.length) {
    loadOptionsAudio(ctx, 'button', optionalButtons)
  }

  // Use key for these voices as they do not have individual IDs to reference
  // Load voices for "I Dont know" button on Postcode screen
  loadVoices(ctx, `${key}-dontknow`, data?.postCode?.dontKnowVoices)

  // Load voices for levels of Risk triagle screen
  const alcoholRisk = data?.outroAlcoholRiskTriangle
  if (alcoholRisk) {
    loadVoices(ctx, `${key}-highrisk`, alcoholRisk?.highRiskVoices)
    loadVoices(ctx, `${key}-risky`, alcoholRisk?.riskyVoices)
    loadVoices(ctx, `${key}-lowrisk`, alcoholRisk?.lowRiskVoices)
    loadVoices(ctx, `${key}-notdrinking`, alcoholRisk?.notDrinkingVoices)
  }

  // Load voices for symptoms of follow up body
  const symptoms = data?.followUpBody?.symptoms
  if (symptoms && Object.keys(symptoms)?.length) {
    Object.keys(symptoms).forEach((symptom) => {
      loadVoices(
        ctx,
        `${symptoms?.[symptom]?.id}-symptom`,
        symptoms?.[symptom]?.voices
      )
    })
  }

  // Load voices for symptoms of follow up body, similar to options
  if (data?.outroEffectsCarousel) {
    const {
      grog = [],
      tobacco = [],
      cannabis = [],
      meth = [],
      heroin = [],
      opioid = [],
      benzos = [],
      other = []
    } = data.outroEffectsCarousel

    const effects = [
      ...grog,
      ...tobacco,
      ...cannabis,
      ...meth,
      ...heroin,
      ...opioid,
      ...benzos,
      ...other
    ]

    loadOptionsAudio(ctx, 'option', effects)
  }
}

/**
 * Load audio for a screen and add to audio context
 * @param ctx Audio files object
 * @param screen Screen object to load audio from
 */
export const loadScreenAudio = (
  ctx: AudioContextProps,
  screen: SurveyDataScreenModel,
  eventId?: string
) => {
  // Load screen voices
  // Check for applicable event audio first
  let eventAudio = false

  if (
    eventId &&
    screen?.specialEventsVoices?.isActive &&
    screen?.specialEventsVoices?.events?.length
  ) {
    // Match added event audio by eventId
    const activeEvent = screen?.specialEventsVoices?.events?.filter(
      (event): boolean => event?.eventId === eventId
    )

    if (activeEvent?.[0]?.voices) {
      loadVoices(ctx, screen.id, activeEvent?.[0]?.voices)
      eventAudio = true
    }
  }

  // If not event audio found use screen audio
  if (!eventAudio && screen?.voices) {
    loadVoices(ctx, screen.id, screen.voices)
  }

  // Load data audio (options and optional buttons)
  loadDataAudio(ctx, screen?.data, screen?.id)

  // Load follow up question voices
  const followUpQuestion = screen?.data?.followUpQuestion
  if (followUpQuestion?.isActive) {
    // Load main follow up question audio
    loadVoices(ctx, `${screen.id}-modal`, followUpQuestion?.voices)

    loadDataAudio(ctx, followUpQuestion?.data, `${screen.id}-modal`)

    // Load audio for additional follow up questions
    const additionalQuestions = followUpQuestion?.additionalQuestions
    if (additionalQuestions?.length) {
      additionalQuestions?.forEach((question: FollowUpQuestionModel) => {
        loadVoices(ctx, `${question?.id}-modal`, question?.voices)

        loadDataAudio(ctx, question?.data, `${question?.id}-modal`)
      })
    }
  }
}

/**
 * Load audio for an array of grog types/subtypes
 * @param ctx Audio files object
 * @param screenId Screen id used to prepend to option id as audio key
 * @param options Grog type array with voices to load audio for MC question type
 */
export const loadGrogOptionsAudio = (
  ctx: AudioContextProps,
  screenId: string,
  options: Array<GrogTypeCategoryModel | GrogSubTypeModel>
): void => {
  if (options?.length) {
    options?.forEach((option) => {
      if (option?.voices && option?.id) {
        loadVoices(ctx, `${screenId}-${option?.id}-option`, option.voices)
      }
    })
  }
}

/**
 * Load audio for a screen and add to audio context
 * @param ctx Audio files object
 * @param screen Screen object to load audio from
 */
export const loadGrogAudio = (
  ctx: AudioContextProps,
  screenId: string,
  typesCategories?: GrogTypeCategoryModel[]
) => {
  if (typesCategories?.length != null && typesCategories?.length > 0) {
    // Load top level category grogshop voices
    loadGrogOptionsAudio(ctx, screenId, typesCategories)

    // Load subtype grog shop audio
    typesCategories?.forEach((cat) => {
      if (cat?.subTypes?.length != null && cat?.subTypes?.length > 0) {
        loadGrogOptionsAudio(ctx, screenId, cat?.subTypes)
      }
    })
  }
}

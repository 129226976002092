import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconReplay: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="39"
    height="36"
    viewBox="0 0 39 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6868 35.3966C20.6358 35.3966 20.6018 35.3957 20.5857 35.3957C19.8447 35.3823 19.2532 34.772 19.2657 34.031C19.2791 33.2901 19.8948 32.6851 20.6313 32.7111C20.7083 32.7191 28.9529 32.7594 32.6631 27.1234C35.6583 22.7868 36.6686 18.7016 35.6842 14.9539C33.9911 8.51873 26.9993 5.05999 26.9286 5.02599C26.8946 5.00898 26.8597 4.99019 26.8266 4.9705C18.8756 0.227606 13.2234 4.34229 12.9872 4.51948C12.9183 4.56959 9.43182 7.09675 7.66889 11.6428C6.82054 13.863 6.34893 16.272 6.30866 18.6059C6.29524 19.3477 5.64197 19.933 4.94217 19.9249C4.20121 19.9115 3.61058 19.3003 3.624 18.5594C3.66964 15.9168 4.2021 13.1918 5.16321 10.6781C7.23219 5.34367 11.2332 2.47288 11.4024 2.35296C11.652 2.16056 18.6125 -3.02352 28.1564 2.63753C28.935 3.02681 36.3419 6.90077 38.2803 14.272C39.474 18.8108 38.3331 23.6396 34.8886 28.6241C30.5708 35.1854 21.7884 35.3966 20.6868 35.3966Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00288 21.5286C4.50622 21.5286 4.0015 21.3737 3.57912 21.057C3.29007 20.8672 2.62159 20.3751 2.08376 19.7039C1.4824 18.9423 0.443435 17.9821 0.432697 17.9732C-0.112289 17.4703 -0.14719 16.6219 0.354842 16.076C0.855978 15.5319 1.70433 15.4934 2.25111 15.9982C2.29943 16.042 3.45652 17.1105 4.18585 18.0331C4.37288 18.2676 4.66103 18.5316 4.97335 18.7589C5.08163 18.6757 5.18007 18.6041 5.27851 18.5316C5.49507 18.3732 5.79038 18.1566 5.89508 18.051C6.38727 17.6063 7.65711 16.3794 7.6589 16.3776L9.52385 18.3097C9.52385 18.3097 8.19942 19.5867 7.74302 19.9974C7.54525 20.1988 7.21504 20.4404 6.86603 20.6972C6.72733 20.7992 6.58683 20.9003 6.46244 20.9979C6.06332 21.3478 5.53802 21.5286 5.00288 21.5286Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6276 11.8615C17.7279 11.0973 18.9324 10.6839 19.7423 11.2208C20.7436 11.8848 21.9383 12.6374 23.0328 13.2209C24.3581 13.9368 25.551 14.9211 26.3465 15.7265C27.1421 16.4424 27.1421 17.5163 26.2141 18.2322C25.2861 19.0376 24.2256 20.1115 23.4301 20.6484C22.8323 21.0959 21.6036 21.9711 20.5262 22.7317C19.7709 23.2651 18.4366 22.9295 18.3829 22.1921C18.3829 22.1921 17.1095 15.8116 17.6276 11.8615Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

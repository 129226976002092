import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const ButtonBorder1: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="205"
    height="59"
    viewBox="0 0 205 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.01087 27.0249C1.92291 33.2752 2.39824 41.4455 2.39824 47.401C2.39824 53.3404 4.62868 56.1232 9.34076 56.1232C21.6255 56.1232 47.0374 57.8734 98.6884 56.4282L131.253 55.4729C168.989 55.5443 184.299 54.5873 192.495 54.5873C198.656 54.5873 202.002 52.4767 202.387 47.0094C202.77 41.5791 203.121 32.6636 202.96 28.3575C202.793 23.8867 202.429 17.8581 202.122 13.179C201.836 8.80227 200.545 5.27252 194.04 4.80116C184.975 4.14495 162.227 1.17059 101.732 2.22338L67.4767 3.1787C31.2374 3.73156 17.8108 5.54727 10.0157 5.54727C3.68474 5.54727 2.06584 9.88867 2.07768 14.8317C2.0853 18.2976 2.07346 22.5406 2.01087 27.0249Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

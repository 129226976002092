import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconPhone: React.FC<Partial<IconPropsModel>> = () => (
  <svg
    width="29"
    height="21"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72877 11.5568C2.5522 11.2036 1.4757 8.77804 1.00977 7.5519C0.899416 7.28214 0.678711 6.16171 0.678711 5.83801C0.678711 5.43339 0.722241 4.21312 0.837891 3.63489C1.00977 2.77551 1.78224 1.55608 2.00294 1.37216C2.22365 1.18824 2.62827 0.673251 3.62145 0.378977C4.60777 0.0867317 5.01364 0.193554 5.70374 0.375185L5.71815 0.378977C6.27727 0.526114 6.92755 1.84083 7.02564 2.30676C7.39349 2.98114 7.88106 4.08682 8.1459 4.49881C8.47696 5.01379 8.73445 5.38163 8.66088 5.74947C8.58731 6.11731 8.1459 6.59551 7.81484 6.74264C7.48379 6.88978 6.30669 7.7726 5.90207 8.21401C5.49744 8.65542 5.68136 8.69221 5.90207 9.31754C6.12277 9.94287 8.47696 13.2534 9.47013 14.2466C10.4633 15.2398 13.4428 16.8215 13.921 16.8215C14.3992 16.8215 14.3256 16.6744 14.5464 16.5272C14.7671 16.3801 14.9878 15.7548 15.1717 15.4605C15.3556 15.1662 15.9074 14.5777 16.2384 14.2466C16.5695 13.9156 17.0845 13.9156 17.4155 13.9156C17.7466 13.9156 18.3089 14.5831 18.5881 14.7755C19.2913 15.2599 19.8433 15.6322 20.5422 16.049C21.0449 16.3188 22.1018 16.9024 22.3078 17.079C22.5653 17.2997 21.9768 19.2861 21.8664 19.5435C21.7781 19.7495 21.1791 20.4896 20.5422 20.8678C20.1498 21.1007 19.1297 21.6108 18.188 21.7874C17.0109 22.0081 15.0245 21.6035 13.7371 21.1988C12.4497 20.7942 9.94833 19.3228 8.1459 17.9986C6.34348 16.6744 2.94947 11.9982 2.72877 11.5568Z"
      fill="white"
    />
  </svg>
)

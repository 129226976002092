import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconClose: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17995 23.0053C5.13292 30.2219 13.6598 32.8624 13.6598 32.8624C20.5871 35.4159 25.0277 33.4786 28.6681 30.4868C32.3103 27.4942 34.4408 21.685 34.1744 15.8749C33.9089 10.5938 31.1557 6.897 31.1557 6.897C31.1557 6.897 26.6254 0.295609 16.5905 2.49607C16.5017 2.49607 -2.23846 4.96147 3.17995 23.0053Z"
      fill={props.fill || 'white'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1968 24.047C25.1968 24.047 21.2604 20.047 18.9565 18.0013C16.3646 15.6755 13.1968 12.047 13.1968 12.047"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1968 12.047C25.1968 12.047 21.1968 15.9834 19.1501 18.2873C16.8253 20.8792 13.1968 24.047 13.1968 24.047"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

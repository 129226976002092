import React, { useMemo, useState } from 'react'
import { DrinkingCircleSliderPropsModel } from './DrinkingCircleSlider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './DrinkingCircleSlider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icon/Icon.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { IconType } from 'components/Icon/Icon.model'
import { SliderCustomLabel } from 'components/Slider/Slider.model'

export const DrinkingCircleSlider: React.FC<DrinkingCircleSliderPropsModel> = (
  props
) => {
  const {
    className,
    color = colors.black,
    title,
    subtitle,
    images,
    defaultValue,
    setValue,
    ...componentProps
  } = props

  const handleDefaultValue = () => {
    if (!defaultValue) {
      return 1
    }

    if (isNaN(defaultValue as number)) {
      return 1
    }

    // Clamp value between 1 and 10
    return Math.max(Math.min(Number(defaultValue), 10), 1)
  }

  const [currentValue, setCurrentValue] = useState<number>(handleDefaultValue())

  const IconSlider = styled(MuiSlider)({
    color: color,
    height: 10,
    '& .MuiSlider-valueLabel': {
      backgroundColor: color
    }
  })

  const labels = useMemo((): SliderCustomLabel[] => {
    return new Array(10).fill(0).map((_c, index) => {
      const value = index + 1
      if (index === 0) {
        return {
          label: 'Just me',
          value
        }
      } else if (index === 1) {
        return {
          label: '1 other person',
          value
        }
      } else {
        return {
          label: `${index}${index === 9 ? '+' : ''} other people`,
          value
        }
      }
    }) as SliderCustomLabel[]
  }, [])

  /**
   * Generate slider thumb component
   * @param thumbProps Html attributes for slider thumb
   * @returns {JSX.Element} Slider thumb component
   */
  const SliderThumbComponent = (
    thumbProps: React.HTMLAttributes<HTMLElement>
  ) => {
    const { children, ...other } = thumbProps
    return (
      <SliderThumb {...other}>
        {children}
        <Icon
          icon={IconType.Hand}
          size={47}
          stroke={colors.white}
          fill={color}
        />
      </SliderThumb>
    )
  }

  /**
   * Generate static image
   * @param src String value for image source
   * @returns {(JSX.Element | null)} image
   */
  const generateImage = (src: string) => (
    <img className={styles.image} src={src} role="presentation" alt="" />
  )

  /**
   * Current memoized image based on value
   */
  const currentImage = useMemo(() => {
    if (!images) return null

    // Loop from current value back to first existing image
    for (let i = currentValue - 1; i >= 0; i--) {
      const image = images[i]

      if (image) {
        return generateImage(image)
      }
    }

    // If no image found backward, loop forward until first existing image
    for (let i = currentValue; i < 11; i++) {
      const image = images[i]

      if (image) {
        return generateImage(image)
      }
    }
  }, [currentValue, images])

  /**
   * Match label with value
   * @param value Input string for format
   * @returns {string} Formatted string label
   */
  const handleLabelFormat = (value: number) =>
    labels?.[value - 1]?.label || value

  const handleValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    setValue(value as number)
    setCurrentValue(value as number)
  }

  return (
    <div
      className={classnames(styles.drinkingcircleslider, className)}
      {...componentProps}
    >
      {title && (
        <h1
          className="screen-titles--title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className="screen-titles--subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={styles.sliderAlign}>
        <div className={styles['drinkingcircleslider-images']}>
          {currentImage}
        </div>

        <div
          className={classnames(styles['drinkingcircleslider-innerWrapper'])}
        >
          <IconSlider
            className={classnames(styles.slider)}
            components={{ Thumb: SliderThumbComponent }}
            valueLabelFormat={handleLabelFormat}
            valueLabelDisplay="on"
            defaultValue={handleDefaultValue()}
            min={1}
            max={10}
            step={1}
            onChangeCommitted={handleValueChange}
          />
        </div>
      </div>
    </div>
  )
}

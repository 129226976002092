import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconFourPieThird: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="186"
    height="193"
    preserveAspectRatio="none"
    viewBox="0 0 186 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="img3"
        patternUnits="userSpaceOnUse"
        width="100%"
        height="100%"
      >
        <image xlinkHref={props.image} x="0" y="0" width="100%" height="100%" />
      </pattern>
    </defs>
    <path
      d="M183.182 27.8618C183.182 12.0658 184.216 10.4259 183.182 3.34692H167.75C164.36 3.34692 155.979 2.45158 152.212 2.00391H97.3009C88.1195 2.00391 52.2771 3.41638 48.3927 3.59294C45.2852 3.73419 24.4978 2.59244 14.4925 2.00391H2.30957V15.5993C2.30957 18.7068 3.95732 28.665 4.78119 33.2557C5.25199 37.1401 7.53546 50.0998 12.903 70.8637C19.6124 96.8186 37.7987 123.48 46.98 134.073C56.1613 144.667 74.2886 157.586 81.5623 162.69C88.836 167.795 125.332 184.001 133.499 186.553C140.033 188.595 169.344 190.565 183.182 191.295C184.144 189.853 183.987 184.081 183.182 171.587C182.291 157.747 183.182 154.248 183.182 141.784V114.385V85.544C184.304 75.4496 183.182 49.9457 183.182 27.8618Z"
      fill={props.image ? 'url(#img3)' : props.fill || '#8C625E'}
      fillOpacity={props.image ? '1' : '0.3'}
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'
import React from 'react'
import styles from './LandingScreen.module.scss'

export const LandingScreen: React.FC = () => {
  return (
    <div className={styles.landingscreen}>
      <Icon icon={IconType.Logo} size={168} />
    </div>
  )
}

import { SpecialEventDataModel, SurveyDataScreenModel } from 'api/client.model'

/**
 * Parses a date string from umbraco (i.e 25/05/2023) to JS date object at specific year
 * @param year Year to create date object fromm using date string as day and month
 * @param date String representation of date as dd/mm/yyyy or %d/%m/%Y
 * @returns date object
 */
const parseDateStringAtYear = (year: number, date: string): Date => {
  // Split date component into parts
  const dateParts = date.split('/')
  return new Date(year, Number(dateParts[1]) - 1, +dateParts[0])
}

/**
 * Create an array of dates before and after specified year, using day and month of date string
 * @param year Year centred for range amount before and after. Dates create using years derived and day month from string
 * @param date String representation of date as dd/mm/yyyy or %d/%m/%Y used for day and month at specified year range
 * @param range Number of years before nad after to create year range from
 * @returns date object
 */
const calcDateRangeFromYear = (
  year: number,
  date: string,
  range = 1
): Date[] => {
  // Create an array of years from year param of range before and after
  const years = Array.from(Array(range * 2 + 1).keys()).map(
    (i) => i - range + year
  )

  // Create dates for each year in array using the day/month supplied in date string param
  return years.map((yr: number): Date => parseDateStringAtYear(yr, date))
}

/**
 * Finds the nearest date in date array from reference date and returns the time difference in milliseconds
 * @param refDate Reference date to calculate tiem difference and nearness
 * @param dates Array of dates to compare with reference date
 * @returns time difference of nearest date to date reference
 */
const nearestTimeDiff = (refDate: Date, dates: Date[]): number => {
  const nearestDate = dates.sort((a: Date, b: Date): number => {
    const distancea = Math.abs(refDate.getTime() - a.getTime())
    const distanceb = Math.abs(refDate.getTime() - b.getTime())
    return distancea - distanceb // sort a before b when the distance is smaller
  })[0]

  return Math.abs(refDate.getTime() - nearestDate.getTime())
}

/**
 * Calculates a new date offest by a number milliseconds from supplied date.
 * @param date Date to offset from
 * @param offset Number of ms to calculate from date param
 * @returns date
 */
const calcDateOffsetByDays = (date: Date, offset: number): Date => {
  return new Date(date.getTime() - offset)
}

/**
 * Replaces the dynamic event shortcode with a reference to the provided dynamic event, removes the shortcode if no event name provided
 * @param title Title to replace dyanmic shortcode in
 * @param event Event to reference for shortcode
 * @returns The new title with the event name replaced or the dynamic event shortcode removed
 */
export const renderSpecialEvent = (
  title: string,
  event: SpecialEventDataModel | null
): string => {
  return title.replace(
    '{{special-event}}',
    event?.name ? `since ${event?.name}` : ''
  )
}

/**
 * Handles the replacement of the dynamic event shortcode on different screen types
 * @param screen Title to replace dyanmic shortcode in
 * @param event Event to reference for shortcode
 * @returns The screen data with the shorcode replaced or removed
 */
export const parseSpecialEvent = (
  screen: SurveyDataScreenModel,
  event: SpecialEventDataModel | null
): SurveyDataScreenModel => {
  if (!screen) return screen

  // Deep copy object to write rendered title to read only property from redux
  const screeenData = structuredClone(screen)

  // Iterate over data keys to catch all screen types
  Object.keys(screeenData?.data).forEach((key) => {
    // Only render dynamic event if active on screen and a title attribute exists on this screen type
    if (
      screeenData?.specialEventsVoices?.isActive &&
      screeenData?.data?.[key]?.title
    ) {
      screeenData.data[key].title = renderSpecialEvent(
        screeenData.data[key].title,
        event
      )
    }
  })

  return screeenData
}

/**
 * Finds nearest dynamic event 12 months ago from current date.
 * @param events Dynamic events array returned from umbraco
 * @returns event The nearest Dynamic event at the current device time
 */
export const calculateSpecialEvent = (
  events: SpecialEventDataModel[]
): SpecialEventDataModel | null => {
  if (!events?.length) {
    return null
  }

  const currentDate = new Date()

  // 12 months in milliseconds
  const offset = 1000 * 60 * 60 * 24 * 365

  // Date 12 months earlier
  const offsetDate = calcDateOffsetByDays(currentDate, offset)

  // Year value for offset date
  const offsetYear = currentDate.getFullYear()

  const sortedEvents = structuredClone(events).sort(
    (a: SpecialEventDataModel, b: SpecialEventDataModel) => {
      //console.log(a, b)
      // Calculate nearest date of event day in current, next and previous years
      const diffa = nearestTimeDiff(
        offsetDate,
        calcDateRangeFromYear(offsetYear, a.date)
      )
      const diffb = nearestTimeDiff(
        offsetDate,
        calcDateRangeFromYear(offsetYear, b.date)
      )

      // Sort a before b when the time difference is smaller
      return diffa - diffb
    }
  )

  // Return earliest event as first object in array
  return sortedEvents?.[0] ?? null
}

import { configureStore, Store } from '@reduxjs/toolkit'
import reducer from './reducer'
import { DispatchType, SurveyState } from './type'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
  throttle: 5000,
  whitelist: [
    'service',
    'geoLocations',
    'grogShops',
    'demoService',
    'demoGeoLocations',
    'demoGrogShops',
    'surveyResponses'
  ] // only data and surveyResponses will be persisted
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store: Store<SurveyState> & {
  dispatch: DispatchType
} = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store

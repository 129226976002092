import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconArrowRight: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_3091_3927" fill={props.fill || 'white'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53621 33.2473C4.58773 44.5233 17.9109 48.6491 17.9109 48.6491C28.7349 52.6389 35.6733 49.6118 41.3614 44.9372C47.0523 40.2613 50.3814 31.1843 49.9651 22.1061C49.5501 13.8543 45.2483 8.07812 45.2483 8.07812C45.2483 8.07812 38.1697 -2.23654 22.4903 1.20168C22.3515 1.20168 -6.93006 5.05386 1.53621 33.2473Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.53621 33.2473C4.58773 44.5233 17.9109 48.6491 17.9109 48.6491C28.7349 52.6389 35.6733 49.6118 41.3614 44.9372C47.0523 40.2613 50.3814 31.1843 49.9651 22.1061C49.5501 13.8543 45.2483 8.07812 45.2483 8.07812C45.2483 8.07812 38.1697 -2.23654 22.4903 1.20168C22.3515 1.20168 -6.93006 5.05386 1.53621 33.2473Z"
      fill={props.fill || 'white'}
      stroke={props.stroke || '#222222'}
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      mask="url(#path-1-inside-1_3091_3927)"
    />
    <path
      d="M22.4891 14.4062C22.4891 14.4062 26.3746 18.3932 27.6235 19.7685C28.4561 20.595 29.5663 22.2454 30.5376 23.4832C31.509 24.582 31.509 26.2337 30.6764 27.3326C29.8438 28.5717 28.5949 30.0832 27.2072 31.1848C24.5706 33.2477 21.3789 36.6859 21.3789 36.6859"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

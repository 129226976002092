import { Screen } from 'components/Screen/Screen.component'
import { MainColor, SecondaryColor } from 'components/Screen/Screen.model'
import { DynamicSlider } from 'components/DynamicSlider/DynamicSlider.component'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import { ModalScreen } from '../ModalScreen/ModalScreen'
import { DynamicSliderScreenPropsModel } from './DynamicSliderScreen.model'
import store from 'store/store'
import { MultipleChoiceScreenData } from '../MultipleChoiceScreen/MultipleChoiceScreen.model'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'

export const DynamicSliderScreen: React.FC<DynamicSliderScreenPropsModel> = (
  props
) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId, showFollowUpQuestion } = matchCondition()
  const {
    currentScreenId,
    name,
    theme,
    headerProps,
    screenData: {
      title,
      subtitle,
      image,
      defaultValue,
      isOptional,
      optionalButtons,
      labelPrefix,
      labelSuffix,
      min,
      max,
      step = 1,
      dynamicImage,
      minLabel,
      maxLabel,
      customLabels,
      dependingScreen
    },
    footerProps,
    followUpQuestion,
    shortcodes
  } = props

  const [sliderValue, setSliderValue] = useState<number | string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleSetValue = (value: number | string) => {
    dispatch(
      updateSurveyAnswer({
        [name]: value.toString()
      } as ISurveyData)
    )

    setSliderValue(value)

    // Remove follow up data if main screen data changed and doesn't match any conditions
    if (
      followUpQuestion &&
      followUpQuestion.isActive &&
      !showFollowUpQuestion(value, followUpQuestion) &&
      followUpQuestion.surveyField
    ) {
      dispatch(
        updateSurveyAnswer({
          [followUpQuestion.surveyField]: undefined
        } as ISurveyData)
      )

      followUpQuestion?.additionalQuestions?.forEach((question) => {
        const { surveyField } = question
        if (surveyField) {
          dispatch(
            updateSurveyAnswer({
              [surveyField]: undefined
            } as ISurveyData)
          )
        }
      })

      setIsFollowUpValueSet(false)
    }
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (!sliderValue) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    const formatSliderValue = sliderValue
    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(formatSliderValue, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        formatSliderValue,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    }
  }

  const handleImage = () => {
    if (dependingScreen && dependingScreen.length > 0) {
      const reduxData = store.getState()
      const screens = reduxData.survey?.screens.filter(
        (item) => item.surveyField === dependingScreen[0]
      )

      const reduxValue = reduxData.surveyData
        ? reduxData.surveyData[dependingScreen[0]]
        : undefined

      if (screens && screens.length > 0 && reduxValue) {
        let selected: MultipleChoiceModel | undefined
        screens.some((screen) => {
          if (screen.data.multipleChoice) {
            selected = (
              screen.data.multipleChoice as MultipleChoiceScreenData
            ).options.find((option) => option.value === reduxValue)

            return selected
          }
        })
        return selected ? selected.image : image
      }
    }
    return image
  }

  useEffect(() => {
    if (defaultValue != null && defaultValue !== 0) {
      setSliderValue(defaultValue)
    }
  }, [defaultValue])

  // Clear Redux
  useEffect(() => {
    // reset value when name changes
    setSliderValue(undefined)
    setIsFollowUpValueSet(false)

    const reset: ISurveyData = {
      [name]: undefined
    }

    if (followUpQuestion && followUpQuestion.surveyField) {
      reset[followUpQuestion.surveyField] = undefined
    }

    dispatch(updateSurveyAnswer(reset))
    setSliderValue(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div className={`drug-app-screen`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <DynamicSlider
          color={SecondaryColor[theme]}
          maskColor={MainColor[theme]}
          title={shortcodesRender(shortcodes, title)}
          subtitle={shortcodesRender(shortcodes, subtitle)}
          image={handleImage()}
          labelPrefix={labelPrefix}
          labelSuffix={labelSuffix}
          min={min}
          max={max}
          step={step}
          defaultValue={sliderValue || min}
          setValue={handleSetValue}
          minLabel={minLabel}
          maxLabel={maxLabel}
          customLabels={customLabels}
          optionalButtons={isOptional ? optionalButtons : undefined}
          dynamicImage={dynamicImage}
          playAudio={footerProps?.playAudio}
        />
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              theme={theme}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              playAudio={footerProps?.playAudio}
              value={sliderValue}
            />
          )}
      </Screen>
    </div>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconDelete: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.92552 19.4317C4.54039 25.399 11.5911 27.5824 11.5911 27.5824C17.3191 29.6938 20.991 28.0919 24.0011 25.6181C27.0128 23.1435 28.7745 18.34 28.5542 13.5357C28.3346 9.1689 26.0581 6.11211 26.0581 6.11211C26.0581 6.11211 22.3121 0.653558 14.0145 2.47308C13.941 2.47308 -1.55486 4.51166 2.92552 19.4317Z"
      fill={props.fill || '#D35555'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1121 3.49896C13.8937 3.52878 7.54357 4.46352 4.58131 8.99114C2.83332 11.6634 2.57765 15.1199 3.82248 19.2636C3.82546 19.2741 3.82844 19.2838 3.83143 19.2935C5.29094 24.6887 11.8043 26.7632 11.8699 26.7833C17.576 28.8854 20.9058 27.0584 23.4156 24.9958C26.1423 22.7551 27.8344 18.1992 27.6264 13.6574C27.4244 9.65158 25.335 6.77206 25.3134 6.74299C25.1531 6.51415 21.766 1.82999 14.2373 3.47884C14.1956 3.48778 14.1546 3.49449 14.1121 3.49896ZM16.6123 29.967C15.0111 29.967 13.2035 29.6569 11.1529 28.9003C10.8935 28.822 3.46173 26.4509 1.67648 19.8928C0.150624 14.7979 0.632159 10.5267 3.10841 7.19694C6.5127 2.62013 12.4954 1.4871 13.7588 1.30001L13.7581 1.29479C22.8044 -0.688747 26.9623 5.19328 27.1337 5.44449C27.2112 5.54735 29.6226 8.8361 29.8597 13.5501C30.1027 18.847 28.1311 24.0156 24.8356 26.7237C22.8692 28.339 20.2804 29.9662 16.6123 29.967Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3396 20.9027C20.7467 21.499 19.7815 21.5016 19.1852 20.9087C19.0858 20.8098 16.7425 18.4765 15.2741 16.7239C13.8489 15.0344 10.7704 12.2478 10.7394 12.2199C10.1152 11.6564 10.0674 10.6921 10.6315 10.0686C11.1971 9.42212 12.1594 9.3965 12.7829 9.96069C12.9153 10.0795 16.045 12.9141 17.6056 14.7627C18.9904 16.4149 21.3107 18.7253 21.3337 18.7483C21.93 19.3413 21.9326 20.3065 21.3396 20.9027Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5655 20.7248C9.96921 20.1318 9.96655 19.1666 10.5595 18.5703C10.6584 18.4709 12.9917 16.1276 14.7443 14.6592C16.4338 13.234 19.2204 10.1555 19.2483 10.1246C19.8118 9.50038 20.7761 9.45248 21.3996 10.0167C22.0461 10.5822 22.0717 11.5445 21.5075 12.168C21.3887 12.3005 18.5541 15.4301 16.7055 16.9907C15.0533 18.3755 12.7429 20.6958 12.7199 20.7189C12.1269 21.3151 11.1617 21.3178 10.5655 20.7248Z"
      fill="#222222"
    />
  </svg>
)

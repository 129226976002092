import React from 'react'
import { CardPropsModel } from './Card.model'
import styles from './Card.module.scss'
import classnames from 'classnames'
import MuiCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { Button } from 'components/Button/Button.component'
import { colors } from 'shared/theme/theme'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'

export const Card: React.FC<CardPropsModel> = (props) => {
  const {
    className,
    title,
    titleColor,
    titleHeight,
    wideTitle,
    children,
    buttonText,
    buttonDisabled,
    extraAction,
    onButtonClick,
    centerAlign = true
  } = props

  return (
    <MuiCard
      className={classnames(
        className,
        styles.card,
        { [styles['card--centerAlign']]: centerAlign },
        'u-border-style-sketch'
      )}
    >
      <Border
        type={BorderType.Modal}
        withTitle
        titleColor={titleColor || colors.capePalliser}
        titleHeight={
          titleHeight != null && titleHeight !== 0 ? titleHeight : 122
        }
        dropShadow
        wideTitle={wideTitle}
        fill={colors.white}
      />
      <CardContent className={styles['card-content']}>
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
        <div className={styles.children}>{children}</div>
      </CardContent>
      {buttonText && onButtonClick && (
        <CardActions className={styles['card-action']}>
          <div className={styles['card-action-button']}>
            <Border
              disabled={buttonDisabled}
              type={BorderType.Button2}
              fill={colors.capePalliser}
              stroke={colors.capePalliser}
            />
            <Button
              className="align-center"
              noBorder
              disabled={buttonDisabled}
              style={{
                color: `${colors.white} !important`,
                padding: '12px 0'
              }}
              onClick={onButtonClick}
            >
              {buttonText}
            </Button>
          </div>
          {extraAction && (
            <p className={styles['card-action-extraAction']}>
              <button onClick={extraAction.onActionClick}>
                {extraAction.title}
              </button>
            </p>
          )}
        </CardActions>
      )}
    </MuiCard>
  )
}

import { CircleChoice } from 'components/CircleChoice/CircleChoice.component'
import { CircleChoiceOptionModel } from 'components/CircleChoice/CircleChoice.model'
import { Screen } from 'components/Screen/Screen.component'
import React, { useEffect, useState } from 'react'
import { ModalScreen } from 'screens/survey/ModalScreen/ModalScreen'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import store from 'store/store'
import { SymptomsCircleScreenPropsModel } from './SymptomsCircleScreen.model'
import styles from './SymptomsCircleScreen.module.scss'

export const SymptomsCircleScreen: React.FC<SymptomsCircleScreenPropsModel> = (
  props
) => {
  const {
    currentScreenId,
    theme,
    headerProps,
    screenData: { title, subtitle, options: AllOptions, dependingScreen },
    followUpQuestion,
    footerProps,
    shortcodes
  } = props

  const { getNextScreenId, showFollowUpQuestion } = matchCondition()

  const [options, setOptions] = useState<CircleChoiceOptionModel[]>(AllOptions)
  const [selectedValue, setSelectedValue] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (!selectedValue) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(selectedValue, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        selectedValue,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    }
  }

  useEffect(() => {
    if (!AllOptions) {
      return
    }

    // Depending Screen Logic
    const reduxValues = store.getState().surveyData
    if (dependingScreen && dependingScreen.length > 0 && reduxValues) {
      let dependingValues: string[] = []

      dependingScreen.forEach((screen) => {
        if (reduxValues[screen]) {
          dependingValues = dependingValues.concat(
            reduxValues[screen].includes('[', 0)
              ? JSON.parse(reduxValues[screen])
              : [reduxValues[screen]]
          )
        }
      })

      setOptions(
        AllOptions.filter(
          (item) => item.value && dependingValues.includes(item.value)
        )
      )
    } else {
      setOptions(AllOptions)
    }
  }, [AllOptions, dependingScreen])

  return (
    <div className={`drug-app-screen ${styles.symptomscirclescreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['symptomscirclescreen-choices']}>
          <CircleChoice
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle)}
            options={options || []}
            setValue={(value) => setSelectedValue(value)}
            playAudio={footerProps?.playAudio}
          />
        </div>
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              theme={theme}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              playAudio={footerProps?.playAudio}
            />
          )}
      </Screen>
    </div>
  )
}

import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconFourPieFirst: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="186"
    height="194"
    viewBox="0 0 186 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="img1"
        patternUnits="userSpaceOnUse"
        width="100%"
        height="100%"
      >
        <image xlinkHref={props.image} x="0" y="0" width="100%" height="100%" />
      </pattern>
    </defs>
    <path
      d="M183.472 26.1434V2.45703C178.341 3.35867 166.015 5.45692 157.756 6.63678C147.432 8.1116 139.173 11.3562 125.31 15.1907C111.446 19.0253 99.6477 29.0541 89.029 34.9534C78.4103 40.8527 59.7635 55.6771 47.734 70.939C37.6104 83.7829 25.0217 106.335 20.8922 112.824C16.7627 119.313 7.6188 144.68 5.25892 158.838C3.37103 170.165 2.50606 178.699 2.30957 181.551V191.58H5.25892H30.3309C48.8154 191.088 87.8492 190.105 96.1082 190.105C104.367 190.105 114.435 189.613 118.466 190.105H146.842C152.505 190.105 161.393 191.088 165.13 191.58H183.472C182.766 186.86 183.472 174.593 183.472 162.673V124.623V103.09C184.142 96.7976 183.472 81.372 183.472 69.4642V26.1434Z"
      fill={props.image ? 'url(#img1)' : props.fill || '#8C625E'}
      fillOpacity={props.image ? '1' : '0.3'}
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

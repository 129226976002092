import React, { useState } from 'react'
import { SliderPropsModel } from './Slider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './Slider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icon/Icon.component'
import { colors } from 'shared/theme/theme'
import { DEFAULT_EMPTY_IMAGE_PATH } from 'shared/constants/Constants.d'
import classnames from 'classnames'
import { IconType } from 'components/Icon/Icon.model'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'

export const Slider: React.FC<SliderPropsModel> = (props) => {
  const {
    className,
    color = colors.black,
    range = false,
    title,
    subtitle,
    image,
    labelPrefix,
    labelSuffix,
    icon = IconType.Hand,
    min = 0,
    max = 100,
    step = 1,
    defaultValue,
    setValue,
    minLabel,
    maxLabel,
    customLabels,
    optionalButtons,
    playAudio,
    ...componentProps
  } = props

  const [resetOptionalButtons, setResetOptionalButtons] =
    useState<boolean>(false)

  const IconSlider = styled(MuiSlider)({
    color: color,
    height: 10,
    '& .MuiSlider-valueLabel': {
      backgroundColor: color
    }
  })

  /**
   * Generate slider thumb component
   * @param thumbProps Html attributes for slider thumb
   * @returns {JSX.Element} Slider thumb component
   */
  const SliderThumbComponent = (
    thumbProps: React.HTMLAttributes<HTMLElement>
  ) => {
    const { children, ...other } = thumbProps
    return (
      <SliderThumb {...other}>
        {children}
        {icon && (
          <Icon icon={icon} size={47} stroke={colors.white} fill={color} />
        )}
      </SliderThumb>
    )
  }

  /**
   * Format prefix or suffix to number
   * @param value Input string for format
   * @returns {string} Formatted string with prefix and suffix
   */
  const handleLabelFormat = (value: number) => {
    let displayValue = value.toString()
    // Check Custom Label
    if (customLabels && customLabels.length > 0) {
      const customLabel = customLabels.find((item) => item.value === value)
      if (customLabel) return customLabel.label
    }

    // Add Prefix & Suffix
    if (labelPrefix) {
      displayValue = labelPrefix + ' ' + displayValue
    }
    if (labelSuffix) {
      displayValue = displayValue + ' ' + labelSuffix
    }
    return displayValue
  }

  /**
   * Generate default value for slider
   * @returns {(number | number[])} default slider value or range value
   */
  const sliderDefault = () => {
    return range ? [min, max] : min
  }

  /**
   * Generate static image
   * @returns {(JSX.Element | null)} image or placeholder
   */
  const generateImage = () => {
    if (!image) return null

    if (image == DEFAULT_EMPTY_IMAGE_PATH) {
      return <div className="image-space-holder">Picture / illustration</div>
    }

    return <img className={styles.image} src={image} alt={title} />
  }

  const handleDefaultValue = () => {
    if (defaultValue === undefined) {
      return sliderDefault()
    }

    if (
      typeof defaultValue === 'string' ||
      typeof (defaultValue as any)[0] === 'string'
    ) {
      return range ? [min, max] : min
    }

    return defaultValue
  }

  const handleValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    setResetOptionalButtons(!resetOptionalButtons)
    setValue(value)
  }

  return (
    <div
      className={classnames(styles.sliderWrapper, className)}
      style={
        !image
          ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }
          : {}
      }
      {...componentProps}
    >
      {title && (
        <h1
          className="screen-titles--title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className="screen-titles--subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div
        className={classnames(styles.sliderAlign, {
          [styles['sliderAlign--middle']]: !title && !subtitle && !image
        })}
        style={
          !image
            ? {
                margin: 'auto',
                width: '100%'
              }
            : {}
        }
      >
        <>
          {generateImage()}
          <div className={classnames(styles['slider__inner-wrapper'])}>
            <IconSlider
              className={classnames(styles.slider, {
                [styles['slider--round']]:
                  !labelPrefix &&
                  !labelSuffix &&
                  (!customLabels || customLabels.length === 0)
              })}
              components={{ Thumb: SliderThumbComponent }}
              valueLabelFormat={handleLabelFormat}
              valueLabelDisplay="on"
              defaultValue={handleDefaultValue()}
              min={min}
              max={max}
              step={step}
              marks={
                minLabel && maxLabel
                  ? [
                      {
                        value: min,
                        label: minLabel
                      },
                      {
                        value: max,
                        label: maxLabel
                      }
                    ]
                  : false
              }
              onChangeCommitted={handleValueChange}
            />
          </div>
          {optionalButtons && (
            <OptionalButtons
              optionalButtons={optionalButtons}
              color={color}
              reset={resetOptionalButtons}
              setValue={setValue}
              playAudio={playAudio}
            />
          )}
        </>
      </div>
    </div>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconComplete: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.85227 19.3158C4.46715 25.2831 11.5178 27.4665 11.5178 27.4665C17.2459 29.5779 20.9177 27.976 23.9279 25.5022C26.9395 23.0276 28.7013 18.2241 28.481 13.4199C28.2614 9.05303 25.9849 5.99624 25.9849 5.99624C25.9849 5.99624 22.2388 0.537683 13.9412 2.3572C13.8678 2.3572 -1.6281 4.39579 2.85227 19.3158Z"
      fill={props.fill || '#8FA26F'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0389 3.28772C13.8205 3.31754 7.47032 4.25228 4.50807 8.7799C2.76008 11.4522 2.5044 14.9087 3.74924 19.0524C3.75222 19.0628 3.7552 19.0725 3.75818 19.0822C5.2177 24.4775 11.7311 26.552 11.7967 26.5721C17.5028 28.6741 20.8325 26.8471 23.3423 24.7846C26.069 22.5439 27.7611 17.9879 27.5532 13.4462C27.3512 9.44033 25.2618 6.56082 25.2402 6.53174C25.0799 6.3029 21.6927 1.61875 14.1641 3.2676C14.1224 3.27654 14.0814 3.28325 14.0389 3.28772ZM16.539 29.7557C14.9379 29.7557 13.1303 29.4456 11.0796 28.6891C10.8202 28.6108 3.38849 26.2396 1.60324 19.6815C0.0773816 14.5866 0.558917 10.3154 3.03517 6.9857C6.43946 2.40888 12.4221 1.27586 13.6856 1.08876L13.6848 1.08355C22.7311 -0.89999 26.889 4.98204 27.0605 5.23324C27.138 5.33611 29.5494 8.62486 29.7864 13.3388C30.0294 18.6357 28.0578 23.8044 24.7624 26.5125C22.796 28.1278 20.2072 29.755 16.539 29.7557Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5263 20.197C13.9665 20.197 13.4045 20.0427 12.8999 19.7303C12.2424 19.3151 11.7109 18.683 11.2421 18.1247C11.0326 17.8765 10.7732 17.5672 10.6539 17.477C10.1612 17.105 10.0628 16.4036 10.4348 15.9109C10.8067 15.4189 11.5074 15.3205 12.0001 15.6917C12.3222 15.934 12.6151 16.2828 12.9543 16.6861C13.2994 17.0976 13.7302 17.6097 14.0851 17.834C14.4801 18.077 14.8841 17.9332 15.1092 17.7326C15.4805 17.3898 16.0962 16.3544 16.5457 15.5978C16.7454 15.2616 16.9325 14.9478 17.095 14.6921C18.0387 13.0477 19.0905 10.725 19.1009 10.7019C19.3558 10.1399 20.017 9.89019 20.5798 10.1429C21.1426 10.3971 21.3931 11.059 21.1389 11.6218C21.0941 11.7209 20.0327 14.066 19.007 15.8512C18.8281 16.1345 18.6537 16.4267 18.4681 16.739C17.8911 17.711 17.2374 18.8127 16.6127 19.3889C16.0127 19.9241 15.271 20.197 14.5263 20.197Z"
      fill="#222222"
    />
  </svg>
)

import { Carousel } from 'components/Carousel/Carousel.component'
import { GrogDiary } from 'components/GrogConsumptions/GrogDiary/GrogDiary.component'
import { GrogDiaryPropsModel } from 'components/GrogConsumptions/GrogDiary/GrogDiary.model'
import { Screen } from 'components/Screen/Screen.component'
import { ScreenTheme } from 'components/Screen/Screen.model'
import React from 'react'
import {
  CANNABIS_AMOUNT,
  CANNABIS_TYPE,
  GROG_DIARY,
  OPIOIDS_AMOUNT,
  OPIOIDS_TYPE,
  TOBACCO_AMOUNT,
  TOBACCO_TYPE,
  BENZOS_TYPE,
  BENZOS_AMOUNT,
  METH_TYPE,
  HEROIN_TYPE,
  OTHER_TYPE
} from 'shared/constants/SurveyFields.d'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { UsingAmountScreenPropsModel } from './UsingAmountScreen.model'
import styles from './UsingAmountScreen.module.scss'
import { DynamicSlider } from 'components/DynamicSlider/DynamicSlider.component'
import { SurveyState } from 'store/type'
import { MultipleChoiceScreenData } from 'screens/survey/MultipleChoiceScreen/MultipleChoiceScreen.model'
import { connect } from 'react-redux'
import { UsingKeys } from 'shared/constants/Constants.d'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'

export const UsingAmountScreenComponent: React.FC<
  UsingAmountScreenPropsModel
> = (props) => {
  const {
    currentScreenId,
    theme,
    headerProps,
    screenData: { title, subtitle, options, image },
    footerProps,
    shortcodes,
    states,
    survey
  } = props

  const { getNextScreenId } = matchCondition()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const generateGrogArgs = () => {
    if (states && states[GROG_DIARY]) {
      let grogStates = states[GROG_DIARY]

      if (typeof grogStates === 'string') {
        grogStates = JSON.parse(grogStates)
      }

      const grogKey = Object.keys(grogStates).sort().reverse()[0]

      return {
        date: grogKey,
        consumptions: grogStates[grogKey].consumptions,
        theme: ScreenTheme.initial,
        outro: true
      } as GrogDiaryPropsModel
    }
  }

  const generateTobaccoArgs = () => {
    if (states && survey && states[TOBACCO_TYPE]) {
      const amount = TOBACCO_AMOUNT.reduce((acc, cv) => {
        if (states[cv]) {
          acc = cv
        }
        return acc
      })

      const screenData = survey.screens.reduce((acc, cv) => {
        if (cv.surveyField === TOBACCO_TYPE) {
          acc = cv
        }
        return acc
      })

      const imagetobacco = (
        screenData.data.multipleChoice as MultipleChoiceScreenData
      ).options.reduce((acc, cv) => {
        if (cv.value === states[TOBACCO_TYPE]) {
          acc = cv.image as string
        }
        return acc
      }, '')

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imagetobacco,
        defaultValue: states[amount],
        dynamicImage: true,
        outro: true
      }
    }
  }

  const generateCannabisArgs = () => {
    if (states && survey && states[CANNABIS_TYPE]) {
      const amount = CANNABIS_AMOUNT.reduce((acc, cv) => {
        if (states[cv]) {
          acc = cv
        }
        return acc
      })

      const screenData = survey.screens.reduce((acc, cv) => {
        if (cv.surveyField === CANNABIS_TYPE) {
          acc = cv
        }
        return acc
      })

      const imagecannabis = (
        screenData.data.multipleChoice as MultipleChoiceScreenData
      ).options.reduce((acc, cv) => {
        if (cv.value === states[CANNABIS_TYPE]) {
          acc = cv.image as string
        }
        return acc
      }, '')

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imagecannabis,
        defaultValue: states[amount],
        dynamicImage: true,
        outro: true
      }
    }
  }

  const generateMethArgs = () => {
    if (states && survey && states[METH_TYPE]) {
      const screenData = survey.screens.reduce((acc, cv) => {
        if (cv.surveyField === METH_TYPE) {
          acc = cv
        }
        return acc
      })

      const imagemeth = (
        screenData.data.multipleChoice as MultipleChoiceScreenData
      ).options.reduce((acc, cv) => {
        if (cv.value === states[METH_TYPE]) {
          acc = cv.image as string
        }
        return acc
      }, '')

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imagemeth,
        defaultValue: 1,
        dynamicImage: false,
        outro: true
      }
    }
  }

  const generateHeroinArgs = () => {
    if (states && survey && states[HEROIN_TYPE]) {
      const screenData = survey.screens.reduce((acc, cv) => {
        if (cv.surveyField === HEROIN_TYPE) {
          acc = cv
        }
        return acc
      })

      const imageheroin = (
        screenData.data.multipleChoice as MultipleChoiceScreenData
      ).options.reduce((acc, cv) => {
        if (cv.value === states[HEROIN_TYPE]) {
          acc = cv.image as string
        }
        return acc
      }, '')

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageheroin,
        defaultValue: 1,
        dynamicImage: false,
        outro: true
      }
    }
  }

  const generateBenzosArgs = () => {
    if (states && survey && states[BENZOS_AMOUNT]) {
      const amount = states[BENZOS_AMOUNT]

      const screenData = survey.screens.reduce((acc, cv) => {
        if (cv.surveyField === BENZOS_TYPE) {
          acc = cv
        }
        return acc
      })

      const imagebenzos = (
        screenData.data.multipleChoice as MultipleChoiceScreenData
      ).options.reduce((acc, cv) => {
        if (cv.value === states[BENZOS_TYPE]) {
          acc = cv.image as string
        }
        return acc
      }, '')

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imagebenzos,
        defaultValue: amount,
        dynamicImage: true,
        outro: true
      }
    }
  }

  const generateOpioidsArgs = () => {
    if (states && survey && states[OPIOIDS_AMOUNT]) {
      const amount = states[OPIOIDS_AMOUNT]

      const isSub = states[OPIOIDS_TYPE[0]] ? true : false

      const screenData = survey.screens.reduce((acc, cv) => {
        if (!isSub && cv.surveyField === OPIOIDS_TYPE[1]) {
          acc = cv
        } else if (isSub && cv.surveyField === OPIOIDS_TYPE[0]) {
          if (
            cv.displayScreenCondition
              ?.map((c) => c.value)
              .includes(states[OPIOIDS_TYPE[1]])
          ) {
            acc = cv
          }
        }

        return acc
      })

      const imageopioids = (
        screenData.data.multipleChoice as MultipleChoiceScreenData
      ).options.reduce((acc, cv) => {
        if (cv.value === states[screenData.surveyField]) {
          acc = cv.image as string
        }
        return acc
      }, '')

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageopioids,
        defaultValue: amount,
        dynamicImage: true,
        outro: true
      }
    }
  }

  const generateOtherArgs = () => {
    if (states && survey && states[OTHER_TYPE]) {
      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: image,
        defaultValue: states[OTHER_TYPE] ? 1 : undefined,
        dynamicImage: false,
        outro: true
      }
    }
  }

  const GrogArgs = generateGrogArgs()
  const TobaccoArgs = generateTobaccoArgs()
  const CannabisArgs = generateCannabisArgs()
  const OpiodsArgs = generateOpioidsArgs()
  const MethArgs = generateMethArgs()
  const HeroinArgs = generateHeroinArgs()
  const BenzosArgs = generateBenzosArgs()
  const OtherArgs = generateOtherArgs()

  const formatTitle = (orgTitle: string, type: string) => {
    return orgTitle.replace('[DRUG_TYPE]', type)
  }

  const generateAmountMarkup = (option: keyof typeof UsingKeys) => {
    let type: string = option
    if (states) {
      switch (option) {
        case 'tobacco':
          type = states[TOBACCO_TYPE]
          break
        case 'cannabis':
          type = states[CANNABIS_TYPE]
          break
        case 'meth':
          type = 'ice or meth'
          break
        case 'opioid':
          type = states[OPIOIDS_TYPE[0]] || states[OPIOIDS_TYPE[1]]
          break
        case 'benzos':
          type = states[BENZOS_TYPE]
          break
        case 'other':
          type = JSON.parse(states[OTHER_TYPE].replace(/'/g, '"')).join(' and ')
          break
        default:
          break
      }
    }

    return (
      <div className={styles['usingamountscreen-content']}>
        <div className={styles['usingamountscreen-titles']}>
          {title && (
            <h1
              className="screen-titles--title"
              dangerouslySetInnerHTML={{
                __html:
                  shortcodesRender(shortcodes, formatTitle(title, type)) ||
                  formatTitle(title, type)
              }}
            />
          )}
          {subtitle && (
            <p
              className="screen-titles--subtitle"
              dangerouslySetInnerHTML={{
                __html:
                  shortcodesRender(shortcodes, formatTitle(subtitle, type)) ||
                  formatTitle(subtitle, type)
              }}
            />
          )}
        </div>
        <div className={styles['usingamountscreen-amount']}>
          {option === 'grog' && GrogArgs && <GrogDiary {...GrogArgs} />}
          {option === 'tobacco' && TobaccoArgs && (
            <DynamicSlider {...TobaccoArgs} />
          )}
          {option === 'cannabis' && CannabisArgs && (
            <DynamicSlider {...CannabisArgs} />
          )}
          {option === 'meth' && MethArgs && <DynamicSlider {...MethArgs} />}
          {option === 'heroin' && HeroinArgs && (
            <DynamicSlider {...HeroinArgs} />
          )}
          {option === 'opioid' && OpiodsArgs && (
            <DynamicSlider {...OpiodsArgs} />
          )}
          {option === 'benzos' && BenzosArgs && (
            <DynamicSlider {...BenzosArgs} />
          )}
          {option === 'other' && OtherArgs && <DynamicSlider {...OtherArgs} />}
        </div>
      </div>
    )
  }

  const filteredOptions = options.filter((option) => {
    switch (option) {
      case 'benzos':
        return BenzosArgs && BenzosArgs.image && BenzosArgs.defaultValue
      case 'cannabis':
        return CannabisArgs && CannabisArgs.image && CannabisArgs.defaultValue
      case 'grog':
        return states && states[GROG_DIARY]
      case 'heroin':
        return HeroinArgs && HeroinArgs.image && HeroinArgs.defaultValue
      case 'meth':
        return MethArgs && MethArgs.image && MethArgs.defaultValue
      case 'opioid':
        return OpiodsArgs && OpiodsArgs.image && OpiodsArgs.defaultValue
      case 'other':
        return OtherArgs?.defaultValue != null && OtherArgs?.defaultValue !== 0
          ? true
          : false
      case 'tobacco':
        return TobaccoArgs && TobaccoArgs.image && TobaccoArgs.defaultValue
    }
  })

  return (
    <div className={`drug-app-screen ${styles.usingamountscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        {options.length > 1 ? (
          <div className={styles['usingamountscreen-container']}>
            <Carousel slidesToShow={1}>
              {filteredOptions.map((option, index) => (
                <div key={index}>{generateAmountMarkup(option)}</div>
              ))}
            </Carousel>
          </div>
        ) : (
          <>{generateAmountMarkup(filteredOptions[0])}</>
        )}
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData,
  survey: state.survey
})

export const UsingAmountScreen = connect(mapStateToProps)(
  UsingAmountScreenComponent
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconSurveys: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="28"
    height="33"
    viewBox="0 0 28 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1396 1.72786C13.1396 1.72786 13.5667 2.18274 13.6965 2.86086C15.2203 2.83969 16.327 2.80451 17.1547 2.77821C18.7217 2.72841 19.2884 2.7104 19.7916 2.87933C20.4116 3.08765 20.5595 4.57988 20.5974 6.47417C19.376 6.50877 17.7086 6.54408 15.3778 6.54408H11.4789L11.4035 6.54941C7.8255 6.80255 6.57222 6.89122 5.79558 6.89122C5.16041 6.89122 4.99799 8.91115 4.99918 11.211C4.99994 12.8236 4.99875 14.7978 4.99247 16.8842C4.98759 18.4928 5 20.3748 5.01207 22.2045C5.02181 23.6825 5.03134 25.1264 5.03134 26.3646C5.03134 26.5102 5.03196 26.6518 5.03321 26.7894C3.0678 26.7581 2.00574 26.7581 1.26114 26.7581C0.788387 26.7581 0.564611 25.4633 0.564611 22.6998C0.564611 21.4617 0.55509 20.0178 0.545343 18.5398C0.533277 16.7101 0.520866 14.8281 0.525748 13.2194C0.532027 11.133 0.533214 9.15883 0.532451 7.54625C0.531263 5.24641 0.693684 3.22648 1.32886 3.22648C2.10549 3.22648 3.35876 3.13781 6.93671 2.88467L6.93674 2.88467L7.01213 2.87933H7.52369C7.87318 1.29673 10.2798 1.04591 10.2938 1.04591C12.2544 0.704928 13.1396 1.72786 13.1396 1.72786Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2846 8.54014C18.298 8.54014 19.186 8.53346 19.9665 8.52307C19.9615 8.56474 19.959 8.60739 19.959 8.65079C19.959 8.66497 19.9581 10.0868 19.8735 11.0149C19.7786 12.1022 19.8668 13.9838 19.8708 14.0637C19.8932 14.5326 20.2015 14.8937 20.5814 14.8608C20.5823 14.8608 20.5831 14.8607 20.5839 14.8606C20.7106 14.901 20.8524 14.9215 21.0015 14.9171C21.0172 14.9166 22.5904 14.8703 23.6197 14.9241C24.8256 14.9829 26.9048 14.8324 26.9931 14.8257C26.9982 14.8253 27.0034 14.8249 27.0085 14.8245C27.0294 16.4396 27.0491 18.1277 27.0601 19.5002C27.0763 21.5037 27.0411 25.6519 27.0027 28.1785C26.964 30.7222 26.6283 31.7043 26.0102 31.7043C25.7511 31.7043 25.5318 31.7785 25.2346 31.8792C24.5886 32.098 23.5741 32.4416 20.9812 32.4189L16.5988 32.5608C10.9695 32.4189 8.86718 32.4189 7.63468 32.4189C7.16192 32.4189 6.93815 31.1241 6.93815 28.3606C6.93815 27.1225 6.92862 25.6786 6.91888 24.2006C6.90681 22.3709 6.8944 20.4889 6.89928 18.8802C6.90556 16.7938 6.90675 14.8196 6.90599 13.207C6.9048 10.9072 7.06722 8.88728 7.70239 8.88728C8.47903 8.88728 9.73231 8.79861 13.3103 8.54547L13.3857 8.54014H17.2846ZM26.9907 13.4875C26.9897 13.4174 26.9887 13.3476 26.9878 13.2782C26.7683 13.0883 26.5542 12.9024 26.3471 12.7219C24.8992 11.4598 23.5262 10.269 22.4037 9.29754C22.0901 9.02619 21.7879 8.75681 21.5076 8.49561L21.2909 8.50033C21.2978 8.54917 21.3014 8.59945 21.3014 8.65079C21.3014 8.71259 21.3005 10.186 21.2074 11.2059C21.149 11.8754 21.1751 12.9832 21.1952 13.5692C21.6975 13.5577 22.8994 13.5378 23.7911 13.5845C24.8317 13.6352 26.8194 13.4915 26.8388 13.49C26.8902 13.4862 26.9409 13.4855 26.9907 13.4875Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

import {
  FollowUpQuestionConditionModel,
  FollowUpQuestionModel,
  NextScreenConditionModel,
  Operator,
  Stratification,
  SurveyDataScreenModel
} from 'api/client.model'
import store from 'store/store'
import { ISurveyData, ISurveyDataType } from 'store/type'
import { MatchCondition } from './matchCondition.model'

export const matchCondition = (): MatchCondition => {
  const isMatchCondition = (
    condition:
      | NextScreenConditionModel
      | FollowUpQuestionConditionModel
      | Stratification,
    orgValue: ISurveyDataType
  ) => {
    let value = orgValue
    if (orgValue && typeof orgValue === 'string' && orgValue.includes('[', 0)) {
      value = JSON.parse(orgValue)
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return false
      }

      let testArray: string[]
      const typeTestValue = value[0]
      if (typeof typeTestValue === 'number') {
        const min = value[0] as number
        const max = value[1] as number
        testArray = Array.from({ length: max - min + 1 }, (_, i) =>
          (i + min).toString()
        )
      } else {
        testArray = value as string[]
      }

      switch (true) {
        case condition.operator === Operator.LONGER_THAN &&
          testArray.length > Number(condition.value):
          return true
        case condition.operator === Operator.SHORTER_THAN &&
          testArray.length < Number(condition.value):
          return true
        case condition.operator === Operator.CONTAIN &&
          testArray.includes(condition.value.toString()):
          return true
        case condition.operator === Operator.NOT_CONTAIN &&
          !testArray.includes(condition.value.toString()):
          return true
        case condition.operator === Operator.EQUAL_TO &&
          condition.value != 'null' &&
          testArray.length === 1 &&
          testArray[0] === condition.value.toString():
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value != 'null' &&
          (testArray.length > 1 || testArray[0] != condition.value.toString()):
          return true
        case condition.operator === Operator.EQUAL_TO &&
          condition.value === 'null' &&
          (testArray.length === 0 || !testArray):
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value === 'null' &&
          testArray &&
          testArray.length > 0:
          return true
      }
    } else {
      // Stringify other type of values if not null or undefined
      if (value !== null && value !== undefined) {
        value = value.toString()
      }

      switch (true) {
        case condition.operator === Operator.EQUAL_TO &&
          condition.value != 'null' &&
          condition.value === value:
          return true
        case condition.operator === Operator.EQUAL_TO &&
          condition.value === 'null' &&
          (value === 'null' || !value):
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value != 'null' &&
          condition.value != value:
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value === 'null' &&
          value &&
          value != 'null':
          return true
        case condition.operator === Operator.GREATER_THAN &&
          Number(value) > Number(condition.value):
          return true
        case condition.operator === Operator.GREATER_AND_EQUAL &&
          Number(value) >= Number(condition.value):
          return true
        case condition.operator === Operator.LESS_THAN &&
          Number(value) < Number(condition.value):
          return true
        case condition.operator === Operator.LESS_AND_EQUAL &&
          Number(value) <= Number(condition.value):
          return true
      }
    }
    return false
  }

  const checkScreenDisplayCondition = (
    screenId?: string,
    screens?: SurveyDataScreenModel[],
    surveyData?: ISurveyData
  ): string | undefined => {
    if (!screens || !surveyData) return screenId
    let finalScreenId = screenId

    const screen = screens.find((item) => item.id == screenId)

    if (
      screen &&
      screen.displayScreenCondition &&
      screen.displayScreenCondition.length > 0
    ) {
      let matchScreenConditions = false
      screen.displayScreenCondition.some((condition) => {
        const { screenSurveyField, value, operator, and } = condition
        const fieldValue = surveyData[screenSurveyField]
        if (isMatchCondition({ operator, value }, fieldValue)) {
          let matchAndConditions = true
          if (and && and.length > 0) {
            and.some((andCondition) => {
              const {
                screenSurveyField: andScreenSurveyField,
                value: andValue,
                operator: andOperator
              } = andCondition
              const andFieldValue = surveyData[andScreenSurveyField]

              if (
                !isMatchCondition(
                  { operator: andOperator, value: andValue },
                  andFieldValue
                )
              ) {
                matchAndConditions = false
                return true
              }
              matchAndConditions = true
            })
          }

          if (matchAndConditions) {
            matchScreenConditions = true
            return true
          }
        }
      })

      // recursive call to check next screen until it matches condition/no condition
      if (!matchScreenConditions) {
        finalScreenId = checkScreenDisplayCondition(
          screen.nextScreen?.nextScreenId,
          screens,
          surveyData
        )
      }
    }
    return finalScreenId
  }

  const getNextScreenId = (
    value?: ISurveyDataType,
    conditions?: NextScreenConditionModel[],
    defaultScreenId?: string
  ) => {
    const storeData = store.getState()
    const { survey, surveyData } = storeData
    const screens = survey?.screens

    let result = defaultScreenId

    if (
      value !== null &&
      value !== undefined &&
      conditions &&
      conditions.length > 0
    ) {
      conditions.some((condition) => {
        if (isMatchCondition(condition, value)) {
          result = condition.nextScreenId
          return true
        }
      })
    }

    return checkScreenDisplayCondition(result, screens, surveyData)
  }

  const showFollowUpQuestion = (
    value: ISurveyDataType,
    followUpQuestion?: FollowUpQuestionModel
  ) => {
    let result = false

    const mainConditions: FollowUpQuestionConditionModel[] =
      followUpQuestion?.conditions || []

    const additionalConditions: FollowUpQuestionConditionModel[] =
      followUpQuestion?.additionalQuestions?.flatMap(
        (questions): FollowUpQuestionConditionModel[] =>
          questions?.conditions || []
      ) || []

    const conditions: FollowUpQuestionConditionModel[] = [
      ...mainConditions,
      ...additionalConditions
    ]

    if (conditions && conditions.length > 0) {
      result = conditions.some((condition) =>
        isMatchCondition(condition, value)
      )
    } else {
      result = true
    }
    return result
  }

  const showAdditionalFollowUpQuestion = (
    value: ISurveyDataType,
    conditions?: FollowUpQuestionConditionModel[]
  ) => {
    let result = false
    if (conditions && conditions.length > 0) {
      result = conditions.some((condition) =>
        isMatchCondition(condition, value)
      )
    } else {
      result = true
    }
    return result
  }

  return {
    isMatchCondition,
    getNextScreenId,
    showFollowUpQuestion,
    showAdditionalFollowUpQuestion
  }
}

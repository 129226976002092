import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconPerson: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="49"
    height="56"
    viewBox="0 0 49 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6443 22.8879C38.545 30.6454 29.379 33.4839 29.379 33.4839C21.9325 36.2287 17.1591 34.1462 13.2458 30.9302C9.33067 27.7133 7.04038 21.4686 7.32678 15.2231C7.61224 9.54613 10.5718 5.57227 10.5718 5.57227C10.5718 5.57227 15.4416 -1.52389 26.2286 0.841497C26.324 0.841497 46.4688 3.49168 40.6443 22.8879Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6212 34.2718C4.70233 38.5795 0.097168 46.2542 0.097168 55.0045V55.3589H23.4173H48.907V55.0045C48.907 45.8973 43.9186 37.9553 36.5251 33.7617C33.303 35.9983 30.2774 36.9353 30.2774 36.9353C21.7415 40.0816 16.172 37.871 11.6212 34.2718Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

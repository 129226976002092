import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconStop: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7755 2.68506C16.4632 2.68506 16.1571 2.69938 15.8547 2.72712C11.9064 3.10029 7.47048 4.37192 4.51467 10.978C4.44219 11.1221 0.757044 18.3742 4.09944 24.2635C4.10302 24.2697 4.1066 24.2769 4.11018 24.2831C6.66329 28.9634 9.92694 31.7098 13.8099 32.4445C20.0669 33.6275 25.8711 29.35 26.2622 29.0502C26.3239 28.992 26.392 28.9392 26.4653 28.8927C34.3054 23.9323 33.1645 17.0507 33.1126 16.7598C33.0964 16.6587 32.4656 12.4733 29.1437 8.82756C25.6984 5.06815 20.8446 2.68506 16.7755 2.68506ZM15.9755 35.3404C11.272 35.3413 5.91875 33.1998 1.75932 25.5789C-2.30347 18.4055 1.91771 10.1565 2.10027 9.80746C5.64402 1.88056 11.5046 0.440688 15.6023 0.0549916C20.7175 -0.428247 26.8072 2.30474 31.125 7.01631C35.033 11.3055 35.7346 16.1289 35.7623 16.3321C35.8196 16.6238 37.3221 25.1243 28.0144 31.0888C27.6323 31.4315 26.9656 31.878 25.8022 32.5349C24.5691 33.2311 22.6137 34.1842 20.3005 34.7739C18.9707 35.1131 17.5066 35.3404 15.9755 35.3404Z"
      fill={props.fill || '#222222'}
    />
    <path
      d="M22.1531 13.4484C23.1548 15.7022 23.1295 22.3059 22.6989 22.3674C19.6153 22.8079 16.4491 22.4685 13.3922 22.1089C12.9841 22.0609 12.7611 15.1485 13.0044 14.224C13.2786 13.1819 13.1345 12.8021 14.3688 12.8021L17.3274 12.8021C18.1454 12.8021 21.7446 12.5292 22.1531 13.4484Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

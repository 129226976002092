import { Screen } from 'components/Screen/Screen.component'
import React from 'react'
import { TextBoxScreenPropsModel } from './TextBoxScreen.model'
import styles from './TextBoxScreen.module.scss'
import classnames from 'classnames'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'

export const TextBoxScreen: React.FC<TextBoxScreenPropsModel> = (props) => {
  const {
    currentScreenId,
    theme,
    headerProps,
    footerProps,
    shortcodes,
    screenData: { title, subtitle, image, sentences }
  } = props

  const { getNextScreenId } = matchCondition()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  return (
    <div className={classnames('drug-app-screen', styles.textboxscreen)}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['textboxscreen-titles']}>
          {title && (
            <h1
              className="screen-titles--title"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, title) || title
              }}
            />
          )}
          {subtitle && (
            <p
              className="screen-titles--subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
        </div>
        <div className={styles['textboxscreen-container']}>
          {image && <img src={image} />}
          {sentences && (
            <div className={styles['textboxscreen-textbox']}>
              <Border
                type={BorderType.Option1}
                fill={colors.white}
                dropShadow
                transform
              />
              <ul>
                {sentences.map((sentence, index) => (
                  <li key={index}>{sentence}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Screen>
    </div>
  )
}

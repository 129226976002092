import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconCake: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="47"
    height="49"
    viewBox="0 0 47 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.48194 24.7601H8.48202C9.7478 24.7509 25.0575 24.7821 35.1439 24.8026C39.7784 24.812 43.3101 24.8192 43.87 24.8192C44.2894 24.8192 44.6304 24.8333 44.9078 24.8677C45.1901 24.9027 45.3502 24.9524 45.4334 24.9916C45.447 24.998 45.457 25.0033 45.4642 25.0075C45.4715 25.0351 45.4822 25.0939 45.4812 25.1984C45.4645 26.9818 45.7146 35.8756 45.7465 37.0021C45.7486 37.0748 45.7497 37.146 45.7499 37.2173C45.7554 40.0087 45.5985 42.2251 45.44 43.8989C45.3817 44.5147 45.3238 45.0522 45.2725 45.527C45.2537 45.702 45.2357 45.8684 45.219 46.0272C45.1597 46.5903 45.1107 47.0999 45.1107 47.488C45.1107 47.5219 45.1087 47.5464 45.1066 47.5633C45.0183 47.6141 44.7394 47.7241 44.0694 47.7484L44.0693 47.7484C43.3279 47.7753 36.1175 47.5435 28.5302 47.228C20.9326 46.912 13.2124 46.5219 11.4258 46.2521L11.3449 46.2398L11.2631 46.2383C10.6927 46.2273 9.3486 46.0502 7.85309 45.8488L7.68203 45.8257C6.32329 45.6425 4.872 45.4469 4.1291 45.4218L8.48194 24.7601ZM8.48194 24.7601C7.14853 24.7697 5.88733 24.7715 4.85762 24.7703H4.85749M8.48194 24.7601L4.85749 24.7703M4.85749 24.7703C4.15442 24.7694 3.58695 24.8269 3.20938 24.9604C2.8909 25.0729 2.90462 25.1544 2.90646 25.1654C2.90656 25.166 2.90663 25.1663 2.9066 25.1665C2.87301 25.3691 2.83108 26.021 2.79278 27.0075C2.75612 27.9518 2.72538 29.1092 2.70055 30.2477C2.65091 32.5234 2.62532 34.7031 2.62296 34.9083L2.62269 34.9395C2.58972 39.5259 2.61712 41.468 2.6734 42.4326C2.70753 43.0174 2.73698 43.1494 2.78313 43.3563C2.8153 43.5005 2.85559 43.6811 2.91125 44.0769M4.85749 24.7703L2.91125 44.0769M2.91125 44.0769C2.94217 44.2967 3.09675 44.6683 3.37927 44.9853M2.91125 44.0769L3.37927 44.9853M3.37927 44.9853C3.65733 45.2972 3.92794 45.415 4.12905 45.4218L3.37927 44.9853ZM45.4577 24.9878C45.4576 24.9878 45.4579 24.9886 45.4587 24.99C45.458 24.9885 45.4577 24.9877 45.4577 24.9878ZM45.4779 25.0162C45.4791 25.0174 45.4799 25.0179 45.4799 25.0179C45.48 25.0179 45.4794 25.0173 45.4779 25.0162Z"
      fill="#E2C8B6"
      stroke="#222222"
      strokeWidth="2.5"
    />
    <path
      d="M3.13333 24.5L45.825 26.4758L43.0833 30.8226L3.13333 28.4516V24.5Z"
      fill="#EB9FC8"
    />
    <path
      d="M23.5 17.6316L5.15781 23.5679C4.10185 23.9096 4.30553 25.4605 5.41392 25.518L38.6803 27.2433C43.3465 27.4853 46.5492 24.1558 43.9293 20.287C43.2149 19.232 42.4049 18.2882 41.5507 17.6316C37.5456 14.5528 28.1546 16.0183 23.5 17.6316Z"
      fill="#EB9FC8"
    />
    <path
      d="M5.15781 23.5679L5.5427 24.7572H5.5427L5.15781 23.5679ZM23.5 17.6316L23.8849 18.8209L23.8972 18.8169L23.9094 18.8127L23.5 17.6316ZM41.5507 17.6316L40.7889 18.6226L40.7889 18.6226L41.5507 17.6316ZM43.9293 20.287L44.9643 19.5861V19.5861L43.9293 20.287ZM38.6803 27.2433L38.7451 25.995L38.6803 27.2433ZM5.41392 25.518L5.34918 26.7663L5.41392 25.518ZM5.5427 24.7572L23.8849 18.8209L23.1151 16.4423L4.77291 22.3786L5.5427 24.7572ZM23.9094 18.8127C26.1291 18.0433 29.5253 17.2971 32.8356 17.1203C34.4874 17.0321 36.0811 17.0878 37.4758 17.3365C38.8805 17.5869 40.0037 18.019 40.7889 18.6226L42.3125 16.6406C41.0952 15.7048 39.5426 15.1655 37.9146 14.8753C36.2765 14.5832 34.4807 14.5289 32.7023 14.6238C29.1521 14.8134 25.5255 15.6066 23.0906 16.4505L23.9094 18.8127ZM40.7889 18.6226C41.5002 19.1694 42.2234 19.9972 42.8943 20.9879L44.9643 19.5861C44.2064 18.4668 43.3095 17.4069 42.3125 16.6406L40.7889 18.6226ZM38.7451 25.995L5.47867 24.2696L5.34918 26.7663L38.6156 28.4916L38.7451 25.995ZM42.8943 20.9879C43.4362 21.7881 43.6237 22.4792 43.6206 23.0331C43.6176 23.5801 43.4294 24.0774 43.0713 24.5131C42.335 25.4092 40.8032 26.1017 38.7451 25.995L38.6156 28.4916C41.2236 28.6269 43.6263 27.7756 45.0029 26.1003C45.7013 25.2503 46.1142 24.205 46.1206 23.047C46.127 21.8961 45.7324 20.7203 44.9643 19.5861L42.8943 20.9879ZM4.77291 22.3786C2.39704 23.1475 2.85527 26.6369 5.34918 26.7663L5.47867 24.2696C5.50733 24.2711 5.58003 24.2914 5.64267 24.3542C5.69395 24.4056 5.70953 24.4559 5.71359 24.4867C5.71765 24.5176 5.71558 24.5703 5.67931 24.6331C5.63501 24.71 5.57001 24.7483 5.5427 24.7572L4.77291 22.3786Z"
      fill="#222222"
    />
    <rect
      x="3.13333"
      y="35.5645"
      width="42.3"
      height="3.95161"
      fill="#EB9FC8"
    />
    <path
      d="M3.40579 35.2627C4.93946 35.5368 6.47973 35.3395 8.03189 35.3395C10.0625 35.3395 12.1065 35.4321 14.1252 35.6468C16.467 35.8959 18.8005 36.0978 21.1578 36.223C23.8069 36.3637 26.4571 36.6455 29.111 36.6455C30.9598 36.6455 32.803 36.5791 34.6483 36.5687C36.653 36.5575 38.6652 36.3382 40.6669 36.3382C41.4734 36.3382 42.2751 36.2614 43.0874 36.2614C43.5536 36.2614 44.5465 36.4342 44.9565 36.1846"
      stroke="#222222"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M3.40579 38.7197C5.05382 38.9803 6.78417 38.9272 8.45845 38.9272C9.24961 38.9272 10.0325 38.9891 10.8189 38.9963C12.6077 39.0128 14.3967 39.1134 16.1654 39.3458C17.2197 39.4844 18.2615 39.7695 19.3221 39.8567C20.0913 39.92 20.8739 39.9377 21.6447 39.9681C23.6827 40.0487 25.7075 40.1026 27.7496 40.1026C30.9147 40.1026 34.1159 40.0133 37.2766 39.8913C39.3085 39.8129 41.3204 39.8372 43.3578 39.8913C44.1149 39.9114 44.8952 39.9643 45.6377 39.9643"
      stroke="#222222"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M32.6956 7.60547V21.4339"
      stroke="#222222"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M34.1251 5.28031C33.394 4.5558 32.9146 3.83732 32.6458 3.17785C32.15 3.79792 31.626 4.47149 31.1343 5.13599C29.6794 7.10209 29.767 8.80815 29.976 9.46724C30.0071 9.5653 30.155 9.77812 30.5629 10.0231C30.9419 10.2507 31.3616 10.3938 31.581 10.4432L31.3063 11.6627L31.581 10.4432C31.6922 10.4683 31.8089 10.4976 31.93 10.528C32.8779 10.7659 34.0983 11.0722 35.0631 9.94222C35.6024 9.31066 35.7339 8.55901 35.55 7.73047C35.3589 6.86964 34.828 5.9769 34.1251 5.28031Z"
      fill="#FFB648"
      stroke="#222222"
      strokeWidth="2.5"
    />
    <path
      d="M3.40579 26.2744C3.41742 26.9528 3.64178 27.8158 4.22408 28.1617C4.93791 28.5858 5.6649 28.7114 6.48194 28.7228C7.72714 28.7403 8.9925 28.4045 9.95208 27.4873C10.1259 27.3211 10.2127 27.1321 10.2248 26.8865C10.2308 26.7669 10.2709 26.8273 10.2981 26.895C10.4086 27.1706 10.5004 27.4645 10.6365 27.7253C11.3229 29.041 12.6966 29.361 13.9324 29.6665C14.699 29.856 15.4523 29.8748 16.2307 29.7657C16.63 29.7097 17.0262 29.6542 17.3672 29.3973C17.6028 29.2198 17.7215 28.8441 17.8874 28.5925C18.006 28.4127 18.3839 28.0452 18.4077 27.8047C18.4455 27.4228 18.7523 28.0365 18.8522 28.1504C19.4494 28.8315 20.0212 29.365 20.8221 29.709C21.9099 30.1761 23.2142 30.3685 24.3756 30.4033C25.0875 30.4246 25.4717 29.989 26.0274 29.5276C26.3515 29.2585 26.8996 28.8755 26.9568 28.3941C26.9834 28.1696 26.9985 27.9318 27.0477 27.7112C27.0512 27.6952 27.1563 27.9526 27.1714 27.986C27.2792 28.2236 27.4064 28.4622 27.5983 28.6236C29.5777 30.2894 31.8441 31.2785 34.339 31.0919C34.9091 31.0493 35.4771 30.9945 36.021 30.7858C36.3102 30.6749 36.4804 30.3798 36.6348 30.1001C36.8884 29.6403 37.001 29.1948 37.001 28.6605C37.001 28.5895 36.959 28.2315 37.001 28.1844C37.0154 28.1683 37.0176 28.2261 37.0262 28.2467C37.0718 28.3564 37.0952 28.4744 37.1525 28.5783C37.3392 28.9168 37.6372 29.2362 37.9253 29.4653C38.8041 30.1641 39.979 30.5026 41.0217 30.7235C41.4885 30.8224 41.8824 30.8468 42.3426 30.715C42.9796 30.5325 43.6304 30.4717 44.0246 29.7912C44.2997 29.3163 44.7361 28.8603 44.9565 28.3658"
      stroke="#222222"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
)

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const ButtonBorder2: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="205"
    height="59"
    viewBox="0 0 205 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M202.989 31.9751C203.077 25.7248 202.602 17.5545 202.602 11.599C202.602 5.65955 200.371 2.87676 195.659 2.87676C183.375 2.87676 157.963 1.12658 106.312 2.57176L73.7475 3.52708C36.0111 3.45566 20.7009 4.41267 12.5048 4.41267C6.34386 4.41267 2.99779 6.52329 2.61294 11.9906C2.22978 17.4209 1.87876 26.3364 2.04031 30.6425C2.20694 35.1133 2.57149 41.1419 2.87768 45.821C3.16441 50.1977 4.45514 53.7275 10.9604 54.1988C20.025 54.855 42.7734 57.8294 103.268 56.7766L137.523 55.8213C173.763 55.2684 187.189 53.4527 194.984 53.4527C201.315 53.4527 202.934 49.1113 202.922 44.1683C202.915 40.7024 202.927 36.4594 202.989 31.9751Z"
      fill={props.fill || 'none'}
      vectorEffect="non-scaling-stroke"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const CardBorder2: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="202"
    height="275"
    viewBox="0 0 202 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M76.9384 4.69999L76.9728 4.70009L77.0073 4.6986L116.049 3.01218C116.049 3.01216 116.05 3.01214 116.05 3.01212C162.916 1.0814 174.515 1.44178 183.806 1.73049C186.784 1.82304 189.526 1.90821 193.115 1.90821C195.194 1.90821 196.831 3.04406 198.037 5.63082C199.276 8.28975 199.939 12.2942 199.939 17.4504C199.939 20.7394 199.995 41.8778 200.068 69.7351C200.23 131.105 200.477 225.082 200.404 232.666C200.329 240.589 200.315 248.086 200.324 254.209C200.331 258.503 199.85 262.774 198.533 265.916C197.881 267.474 197.054 268.676 196.055 269.483C195.08 270.271 193.87 270.741 192.306 270.741C188.713 270.741 188.507 270.752 187.223 270.818C186.824 270.839 186.322 270.865 185.581 270.898C182.475 271.036 175.102 271.297 153.376 271.785L153.363 271.785L153.349 271.786L112.303 273.471C50.0759 273.811 28.462 271.035 16.7312 269.528C14.8901 269.291 13.2925 269.086 11.8196 268.929C8.38994 268.563 6.50146 267.073 5.34174 264.904C4.10785 262.596 3.63867 259.403 3.46785 255.564C3.10078 247.304 2.66425 236.673 2.46485 228.796L2.46484 228.796C2.27159 221.211 2.69325 27.7485 3.15019 18.2134L3.15019 18.2132C3.37338 13.5449 4.44726 10.6177 6.08454 8.85332C7.68532 7.12831 10.0631 6.26343 13.5123 6.26343C16.624 6.26343 19.6184 6.09474 23.6167 5.86948C25.9855 5.73603 28.7066 5.58273 32.0132 5.4329C40.9588 5.02754 54.3352 4.63701 76.9384 4.69999Z"
      stroke={props.stroke || '#222222'}
      fill={props.fill || 'none'}
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import React, { useEffect } from 'react'
import 'shared/styles/app.scss'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { LandingScreen } from 'screens/general/LandingScreen/LandingScreen'
import { RADashboardScreen } from 'screens/ra-dashboard'
import { SurveyScreen } from 'screens/survey'
import { EnterParticipantsSLKScreen } from 'screens/ra-dashboard/EnterParticipantsSLKScreen/EnterParticipantsSLKScreen'

export const App = () => {
  const navigate = useNavigate()

  /** Quit Landing Page after 3 seconds */
  useEffect(() => {
    setTimeout(() => {
      navigate('/ra-dashboard')
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Routes>
      <Route index element={<LandingScreen />} />
      <Route path="ra-dashboard/*" element={<RADashboardScreen />} />
      <Route path="survey/demo/*" element={<SurveyScreen demoMode={true} />} />
      <Route path="survey/*" element={<SurveyScreen />} />
      <Route
        path="enter-participants-slk"
        element={<EnterParticipantsSLKScreen isContinueSurvey={false} />}
      />
      <Route
        path="enter-participants-slk/continue"
        element={<EnterParticipantsSLKScreen isContinueSurvey={true} />}
      />
    </Routes>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconCalendar: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="31"
    height="27"
    viewBox="0 0 31 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_203_3041)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00199315 3.90514C-0.0131494 4.92331 0.0686762 22.7947 0.0686761 23.7648C0.0686761 24.7324 0.452621 25.3373 1.26376 25.3373C3.37844 25.3373 3.44577 25.4708 12.337 25.2353L17.9425 25.0797C24.4385 25.0914 25.6383 24.9355 27.0491 24.9355C28.1097 24.9355 28.6857 24.5917 28.7519 23.701C28.8179 22.8164 28.8783 4.96151 28.8505 4.26005C28.8218 3.53177 28.759 2.54973 28.7063 1.7875C28.657 1.07454 28.4348 0.499554 27.315 0.422769C25.7546 0.315874 23.2128 -0.0335336 12.861 0.002852L6.96419 0.158474C0.725979 0.248533 2.72179 0.254806 1.37995 0.254806C0.290135 0.254806 0.0114557 1.11368 0.0134946 1.91888C0.0148049 2.48347 0.0127678 3.17466 0.00199315 3.90514Z"
        fill={props.fill || '#E2C8B6'}
      />
      <path
        d="M1.50183 3.92744L1.50183 3.92726C1.51276 3.18609 1.51481 2.48605 1.51349 1.9154L1.51349 1.91508C1.51334 1.8544 1.51602 1.8009 1.52053 1.75477C1.90244 1.75452 1.95094 1.75298 2.13661 1.74708C2.46423 1.73667 3.21892 1.7127 6.98584 1.65832L6.9948 1.65819L7.00376 1.65795L12.8834 1.50278C21.7643 1.47191 24.8351 1.72575 26.5083 1.86405C26.7717 1.88582 27.0005 1.90474 27.2119 1.91922C27.264 2.67545 27.3242 3.62342 27.3516 4.31908L27.3517 4.31948L27.3517 4.31985C27.3518 4.32287 27.3523 4.34411 27.353 4.39431C27.3536 4.44408 27.3542 4.51055 27.3547 4.59372C27.3558 4.75979 27.3565 4.98529 27.3569 5.26289C27.3578 5.81769 27.3575 6.57436 27.3561 7.4669C27.3533 9.25165 27.3463 11.5755 27.3364 13.9055C27.3264 16.2356 27.3136 18.5702 27.2991 20.3768C27.2919 21.2804 27.2842 22.0499 27.2764 22.6198C27.2724 22.9051 27.2685 23.1376 27.2646 23.3107C27.2636 23.3541 27.2626 23.393 27.2617 23.4275C27.2039 23.4323 27.1336 23.4355 27.0491 23.4355C26.5828 23.4355 26.1196 23.4522 25.5261 23.4736C25.1877 23.4858 24.8069 23.4996 24.3591 23.5126C23.0887 23.5496 21.1804 23.5855 17.9452 23.5797L17.9231 23.5797L17.9009 23.5803L12.2973 23.7359C12.2969 23.7359 12.2966 23.7359 12.2963 23.7359C5.59645 23.9133 3.95179 23.8805 2.61848 23.8539C2.27532 23.847 1.95279 23.8406 1.57001 23.8383C1.56915 23.8155 1.56868 23.791 1.56868 23.7648C1.56868 23.447 1.56046 21.4393 1.54971 18.8127C1.52655 13.1512 1.49161 4.61422 1.50183 3.92744Z"
        stroke={props.stroke || '#B77548'}
        strokeWidth="3"
      />
    </g>
    <mask
      id="mask0_203_3041"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="0"
      width="30"
      height="27"
    >
      <path
        d="M1.76567 4.65514C1.75525 5.35574 1.79074 14.0358 1.81389 19.6985C1.82439 22.2654 1.83235 24.2122 1.83235 24.5148C1.83235 25.4824 2.2163 26.0873 3.02743 26.0873C3.53985 26.0873 3.93205 26.0952 4.35872 26.1037C5.69285 26.1303 7.3639 26.1637 14.1006 25.9853L19.7062 25.8297C24.171 25.8377 26.1338 25.7666 27.3829 25.7213C27.9511 25.7007 28.3716 25.6855 28.8128 25.6855C29.8733 25.6855 30.4493 25.3417 30.5156 24.451C30.5815 23.5664 30.642 5.71151 30.6142 5.01005C30.5855 4.28177 30.5227 3.29973 30.47 2.5375C30.4207 1.82454 30.1985 1.24955 29.0787 1.17277C28.8672 1.15828 28.6377 1.13934 28.3733 1.11752C26.686 0.978262 23.5737 0.721395 14.6246 0.752852L8.72786 0.908474C4.86448 0.96425 4.15921 0.987888 3.8634 0.997802C3.68156 1.0039 3.65445 1.00481 3.14362 1.00481C2.0538 1.00481 1.77513 1.86368 1.77717 2.66888C1.77848 3.23347 1.77644 3.92466 1.76567 4.65514Z"
        fill="white"
      />
      <path
        d="M1.76567 4.65514C1.75525 5.35574 1.79074 14.0358 1.81389 19.6985C1.82439 22.2654 1.83235 24.2122 1.83235 24.5148C1.83235 25.4824 2.2163 26.0873 3.02743 26.0873C3.53985 26.0873 3.93205 26.0952 4.35872 26.1037C5.69285 26.1303 7.3639 26.1637 14.1006 25.9853L19.7062 25.8297C24.171 25.8377 26.1338 25.7666 27.3829 25.7213C27.9511 25.7007 28.3716 25.6855 28.8128 25.6855C29.8733 25.6855 30.4493 25.3417 30.5156 24.451C30.5815 23.5664 30.642 5.71151 30.6142 5.01005C30.5855 4.28177 30.5227 3.29973 30.47 2.5375C30.4207 1.82454 30.1985 1.24955 29.0787 1.17277C28.8672 1.15828 28.6377 1.13934 28.3733 1.11752C26.686 0.978262 23.5737 0.721395 14.6246 0.752852L8.72786 0.908474C4.86448 0.96425 4.15921 0.987888 3.8634 0.997802C3.68156 1.0039 3.65445 1.00481 3.14362 1.00481C2.0538 1.00481 1.77513 1.86368 1.77717 2.66888C1.77848 3.23347 1.77644 3.92466 1.76567 4.65514Z"
        stroke="#222222"
      />
    </mask>
    <g mask="url(#mask0_203_3041)">
      <rect
        x="-1.02539"
        y="0.75"
        width="28.8573"
        height="10.2205"
        fill={props.stroke || '#B77548'}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_203_3041"
        x="0.000244141"
        y="0"
        width="28.8572"
        height="26.5943"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.21932" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_203_3041"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_203_3041"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconMute: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1568 30.5691C25.7317 30.5691 25.3138 30.3669 25.0534 29.991C24.6301 29.3825 24.7822 28.5458 25.3916 28.1243C28.0951 26.2495 30.7914 23.5147 30.2822 17.4483C30.2768 17.3105 29.9833 10.3787 25.116 7.49718C24.478 7.11953 24.2668 6.29624 24.6444 5.65819C25.022 5.02013 25.8462 4.81073 26.4825 5.18658C32.6348 8.82787 32.9516 16.9517 32.9615 17.2953C33.5843 24.6969 30.0603 28.1538 26.921 30.3293C26.6874 30.4913 26.4208 30.5691 26.1568 30.5691Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3572 26.8262C20.9679 26.8262 20.5831 26.6588 20.3182 26.334C19.8475 25.7613 19.9316 24.9147 20.5062 24.4458C22.1939 23.0623 23.6928 21.2466 23.2373 17.3565C23.2284 17.2473 22.8785 12.8024 19.9146 11.1916C19.2632 10.8373 19.0224 10.0229 19.3759 9.37054C19.7321 8.71906 20.5446 8.48102 21.197 8.83271C25.4701 11.1549 25.8934 16.8697 25.9104 17.1122C26.5216 22.3195 24.0902 24.98 22.2064 26.5219C21.9577 26.7269 21.6561 26.8262 21.3572 26.8262Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0715 23.1156C16.7601 23.1156 16.4478 23.0082 16.1936 22.788C15.6334 22.303 15.5726 21.4546 16.0576 20.8944C16.3816 20.5213 16.8066 19.8653 16.7673 18.4872C16.7717 17.9798 16.6321 16.65 15.9323 15.8804C15.4339 15.3318 15.4742 14.4835 16.0227 13.9841C16.5704 13.4866 17.4214 13.5251 17.919 14.0745C19.4645 15.7748 19.4564 18.2071 19.451 18.4783C19.5011 20.1481 19.0546 21.5352 18.0872 22.652C17.8214 22.9581 17.4474 23.1156 17.0715 23.1156Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97288 12.6067C6.63331 12.5825 7.27315 12.3651 7.76713 11.9257L8.95822 10.8679C9.19358 10.6585 9.47189 10.4366 9.76004 10.2209C10.6209 9.57483 11.8586 10.0948 11.9668 11.1659C12.2255 13.7217 12.5512 17.7711 12.2621 19.7891C12.042 21.3318 12.0706 23.1064 12.1539 24.9239C12.2111 26.1678 10.7453 26.8792 9.79584 26.073C9.75557 26.0389 9.7153 26.0049 9.67592 25.9709C8.51794 24.9785 7.45124 23.7597 6.56977 23.7597C6.05432 23.7597 4.99925 23.9002 3.08061 23.595C1.60942 23.3606 1 22.4317 1 21.0884V15.1679C1 13.9347 2.09534 12.9298 3.54685 12.8546C3.54685 12.8546 3.88064 12.6837 5.97288 12.6067Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.28645 33.4377C0.76251 32.9138 0.19195 32.6519 2.32998 30.5138C1.5472 31.3376 11.2043 21.6241 15.9812 17.1104C20.3705 12.9527 30.5137 2.62802 30.5925 2.54671C32.6963 0.379624 32.8618 0.954948 33.5136 1.34852C34.2006 1.73598 34.2308 2.79819 32.127 4.96527C31.7686 5.33505 22.084 15.0485 16.9973 19.8669C12.6507 23.9743 4.46132 32.0624 4.39676 32.127C2.25874 34.265 1.81039 33.9617 1.28645 33.4377Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

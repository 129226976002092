import React, { useState, useEffect, useMemo, useCallback } from 'react'
import 'shared/styles/app.scss'
import { ScreenTheme } from 'components/Screen/Screen.model'
import { useLocation, Route, Routes, matchPath } from 'react-router-dom'
import { ScreenComponent, ScreenType } from 'screens/survey/type.d'
import { connect } from 'react-redux'
import { HeaderPropsModel } from 'components/Header/Header.model'
import {
  GrogShopDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel,
  SpecialEventDataModel,
  SurveyDataScreenModel
} from 'api/client.model'
import { FooterPropsModel } from 'components/Footer/Footer.model'
import { SurveyState } from 'store/type.d'
import { AudioContext, AudioContextProps } from 'context/AudioContext'
import {
  loadGrogAudio,
  loadScreenAudio
} from 'shared/utils/loadScreenAudio/loadScreenAudio'
import {
  calculateSpecialEvent,
  parseSpecialEvent
} from 'shared/utils/calculateSpecialEvent/calculateSpecialEvent'

const InitialSurveySetup = (
  props: SurveyState & {
    demoMode?: boolean
    specialEvents?: SpecialEventDataModel[]
    typesCategories?: Array<GrogTypeCategoryModel | GrogSubTypeModel>
  }
) => {
  const { survey, demoMode, specialEvents, typesCategories = [] } = props

  // Set current dynamic event from list of events
  const specialEvent = useMemo(() => {
    return specialEvents ? calculateSpecialEvent(specialEvents) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialEvents])

  const location = useLocation()

  const startScreenData = survey?.screens[0]

  const [screenData, setScreenData] = useState<
    SurveyDataScreenModel | undefined
  >(startScreenData)
  const [progress, setProgress] = useState<number>(0)
  const [voice, setVoice] = useState<string>(
    survey ? survey.voiceOver[0].value : 'enMale'
  )
  const [audio, setAudio] = useState<AudioContextProps>({ files: {} })
  const [activeAudio, setActiveAudio] = useState<HTMLAudioElement | undefined>()
  const [voiceMute, setVoiceMute] = useState<boolean>(false)

  const findScreen = (id: string): SurveyDataScreenModel | undefined => {
    return survey?.screens?.find((item) => item.id === id)
  }

  const filterVoiceOver = () => {
    if (!screenData || !screenData.voices || !survey) {
      return []
    }

    const validVoices = Object.keys(screenData.voices)
    return survey.voiceOver.filter((voiceItem) =>
      validVoices.includes(voiceItem.value)
    )
  }

  const handleVoiceChange = (value: string) => {
    setVoice(value)
  }

  const handleMuteChange = (mute: boolean) => {
    setVoiceMute(mute)
  }

  const Component: React.FC<any> = screenData
    ? ScreenComponent[Object.keys(screenData.data)[0] as ScreenType]
    : ('div' as any)

  const generateHeaderProps = (): HeaderPropsModel => {
    return {
      theme: screenData?.theme || ScreenTheme.demog,
      nextScreenId: screenData?.nextScreen?.nextScreenId,
      progress,
      voice,
      mute: voiceMute,
      helpText: screenData?.helpText,
      voiceOver: filterVoiceOver(),
      playAudio,
      setVoice: handleVoiceChange,
      setMute: handleMuteChange
    }
  }

  const stopAudio = useCallback(() => {
    // Stop existing audio from playing
    if (activeAudio) {
      activeAudio.pause()
    }
  }, [activeAudio])

  const playAudio = useCallback(
    (key?: string, newVoice: string | undefined = undefined) => {
      newVoice = newVoice || voice
      stopAudio()

      if (key) {
        const file = audio.files[key]?.[newVoice]
        if (file) {
          const audioElement = new Audio(file)
          audioElement.currentTime = 0
          audioElement
            .play()
            .catch((e) => console.warn(`Failed to play audio`, e))
          setActiveAudio(audioElement)
        }
      }
    },
    [audio.files, stopAudio, voice]
  )

  const hasAudio = () => {
    return Boolean(screenData?.id && audio.files[screenData.id]?.[voice])
  }

  const generateFooterProps = (
    data?: SurveyDataScreenModel,
    firstscreen?: boolean
  ): FooterPropsModel => {
    return {
      id: data?.id,
      theme: screenData?.theme || ScreenTheme.demog,
      surveyEnd: screenData?.surveyEnd,
      demoMode,
      firstscreen,
      screenName: screenData?.surveyField,
      followUpQuestionName: screenData?.data.followUpQuestion.surveyField,
      nextScreenId: screenData?.nextScreen?.nextScreenId,
      conditions: screenData?.nextScreen?.isConditional
        ? screenData?.nextScreen?.conditions
        : undefined,
      activeAudio,
      playAudio,
      stopAudio,
      hasAudio
    }
  }

  const calculateProgress = (sectionId: string) => {
    let totalSections = survey?.screens.map((screen) => screen.sectionId)
    totalSections = Array.from(new Set(totalSections))
    const sectionIndex = totalSections.findIndex((each) => each === sectionId)
    return ((sectionIndex + 1) / totalSections.length) * 100
  }

  /** Update Screen Data based on Route */
  useEffect(() => {
    const path = matchPath(
      `survey/${demoMode ? 'demo/' : ''}:id`,
      location.pathname
    )

    const screensData = survey?.screens

    if (screensData) {
      if (path?.params.id) {
        const screen = findScreen(path.params.id)

        if (screen) {
          const parsedScreen = parseSpecialEvent(screen, specialEvent)

          setProgress(calculateProgress(parsedScreen.sectionId))
          setScreenData(parsedScreen)
        } else {
          alert(`Unknown screen: ${path.params.id}`)
        }
      } else {
        const parsedScreen = startScreenData
          ? parseSpecialEvent(startScreenData, specialEvent)
          : startScreenData
        setScreenData(parsedScreen)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Load all audio screens
  useEffect(() => {
    // Load audio for all screens
    if (survey?.screens) {
      Promise.all(
        survey.screens.map((screen) => {
          loadScreenAudio(audio, screen, specialEvent?.id)
          if (screen?.data?.grogDiary && screen?.id) {
            loadGrogAudio(audio, screen?.id, typesCategories)
          }
        })
      )
    }

    setAudio(audio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey])

  useEffect(() => {
    if (activeAudio) activeAudio.muted = voiceMute
  }, [activeAudio, voiceMute])

  useEffect(() => {
    if (activeAudio) {
      activeAudio.onended = () => {
        setActiveAudio(undefined)
      }

      activeAudio.onpause = () => {
        setActiveAudio(undefined)
      }
    }
  }, [activeAudio])

  return (
    <AudioContext.Provider value={audio}>
      <Routes>
        <Route
          index
          element={
            <Component
              key={startScreenData?.id}
              currentScreenId={startScreenData?.id}
              theme={startScreenData?.theme || ScreenTheme.demog}
              name={screenData?.surveyField}
              screenData={
                startScreenData?.data[Object.keys(startScreenData.data)[0]]
              }
              followUpQuestion={startScreenData?.data.followUpQuestion}
              headerProps={generateHeaderProps()}
              footerProps={generateFooterProps(startScreenData, true)}
              shortcodes={screenData?.shortcodes}
              specialEvent={specialEvent}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <Component
              key={screenData?.id}
              currentScreenId={screenData?.id}
              theme={screenData?.theme || ScreenTheme.demog}
              name={screenData?.surveyField}
              screenData={screenData?.data[Object.keys(screenData.data)[0]]}
              followUpQuestion={screenData?.data.followUpQuestion}
              headerProps={generateHeaderProps()}
              footerProps={generateFooterProps(screenData)}
              shortcodes={screenData?.shortcodes}
              specialEvent={specialEvent}
            />
          }
        />
      </Routes>
    </AudioContext.Provider>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  survey: state.survey,
  specialEvents: state.service?.projects.find(
    (project) => project.id === state.surveyMetaData?.projectId
  )?.specialEvents,
  typesCategories: (
    (state.user?.id === 'demo'
      ? state?.demoGrogShops
      : state?.grogShops || []
    )?.find(
      (shop) => shop.Id === state.surveyMetaData?.shopId
    ) as GrogShopDataModel
  )?.typesCategories
})

export const SurveyScreen = connect(mapStateToProps)(InitialSurveySetup)

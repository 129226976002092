import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { Button } from 'components/Button/Button.component'
import { IconType } from 'components/Icon/Icon.model'
import { Select } from 'components/Select/Select.component'
import { TimeClock } from 'components/TimeClock/TimeClock.component'
import React, { useState } from 'react'
import styles from './Header.module.scss'
import { HeaderPropsModel, HeaderPropsStateModel } from './Header.model'
import { SecondaryColor } from 'components/Screen/Screen.model'
import { colors } from 'shared/theme/theme'
import { calculateTimeDiff } from 'shared/utils/calculateTimeDiff/calculateTimeDiff'
import { TimeDiffFormat } from 'shared/utils/calculateTimeDiff/calculateTimeDiff.model'
import { connect } from 'react-redux'
import { SurveyState } from 'store/type'
import classnames from 'classnames'
import { Modal } from 'components/Modal/Modal.component'

export const HeaderComponent: React.FC<
  HeaderPropsModel & HeaderPropsStateModel
> = (props) => {
  const {
    nextScreenId,
    startTime,
    duration,
    progress = 0,
    voice,
    mute,
    theme,
    helpText,
    voiceOver,
    playAudio,
    setVoice,
    setMute
  } = props

  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false)

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.white
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: SecondaryColor[theme]
    }
  }))

  const handleMute = () => {
    setMute && setMute(!mute)
  }

  const handleSetVoice = (voicefile: string) => {
    playAudio?.('current', voicefile)
    setVoice(voicefile)
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerRowOne}>
        <div className={styles['header-left']}>
          <TimeClock
            start={
              calculateTimeDiff(
                startTime,
                new Date(),
                duration,
                true
              ) as TimeDiffFormat
            }
            finish={!nextScreenId}
          />
        </div>
        <div className={styles['header-right']}>
          {voiceOver?.length > 0 && (
            <>
              <Select
                className={styles.select}
                selectProps={{
                  defaultValue: voice || voiceOver[0].value
                }}
                icon={IconType.Voice}
                options={voiceOver}
                setValue={handleSetVoice}
              />
              <Button
                noBorder
                className={classnames(
                  'ml',
                  styles['header-right-icons--volume']
                )}
                icon={mute ? IconType.Mute : IconType.Volume}
                iconPosition="top"
                onClick={handleMute}
                style={{
                  padding: '0 !important'
                }}
              >
                Volume
              </Button>
            </>
          )}
          <Button
            noBorder
            className="ml"
            icon={IconType.Information}
            iconPosition="top"
            onClick={() => setHelpModalOpen(true)}
            style={{ padding: '0 !important' }}
          >
            Help
          </Button>
        </div>
      </div>
      <div className={styles.headerRowTwo}>
        <Box sx={{ flexGrow: 1 }}>
          <BorderLinearProgress
            className={styles.progress}
            variant="determinate"
            value={progress}
            aria-label={`Progress ${progress}%`}
          />
        </Box>
      </div>
      <Modal
        className={styles.helpModal}
        open={helpModalOpen}
        buttons={
          <Button
            variation="primary"
            width="l"
            onClick={() => setHelpModalOpen(false)}
          >
            OK
          </Button>
        }
        style={{ backgroundColor: colors.white }}
      >
        <div
          className={styles['helpModal-content']}
          dangerouslySetInnerHTML={{ __html: helpText || 'No Help Available' }}
        />
      </Modal>
    </header>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  startTime:
    state.surveyMetaData?.resumeTime ||
    state.surveyMetaData?.startTime ||
    new Date(),
  duration:
    state.surveyMetaData?.duration != null ? state.surveyMetaData?.duration : 0
})

export const Header = connect(mapStateToProps)(HeaderComponent)

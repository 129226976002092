import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './DeclinedScreen.module.scss'
import {
  aboriginalOrIslanderOptions,
  ageOptions,
  DeclinedScreenDataModel,
  genderOptions
} from './DeclinedScreen.model'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { Button } from 'components/Button/Button.component'
import { Slider } from 'components/Slider/Slider.component'
import { connect, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { ISurveyData, SurveyState } from 'store/type'
import { addCompletedSurvey } from 'store/reducer'
import { DELETE_REFUSE_TAKE_PART } from 'shared/constants/messages/Messages.d'

export const DeclinedScreenComponent: React.FC<SurveyState> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const [index, setIndex] = useState<number>(0)
  const [data, setData] = useState<DeclinedScreenDataModel>({})
  const [invalid, setInvalid] = useState<boolean>(true)

  const handleValueSave = (
    key: keyof DeclinedScreenDataModel,
    value: string
  ) => {
    const updatedData = { ...data }
    updatedData[key] = value
    setData(updatedData)

    if (value) {
      setInvalid(false)
    }
  }

  const handleNextClick = () => {
    if (index === 3) {
      dispatch(
        addCompletedSurvey({
          save: true,
          responses: {
            'refusal-gender': data.gender,
            'refusal-age': data.age,
            'refusal-indigenous': data.aboriginalOrIslander
          } as ISurveyData,
          metadata: {
            ...props.surveyMetaData,
            slk: undefined,
            flaggedIncomplete: true,
            flaggedDeleted: true,
            deletionReason: DELETE_REFUSE_TAKE_PART,
            flaggedComingBack: false,
            status: 'deletion',
            endTime: new Date()
          }
        })
      )

      navigate('/ra-dashboard/dashboard')
      return
    }

    setIndex(index + 1)
    setInvalid(true)
  }

  useEffect(() => {
    if (index === 3) {
      setInvalid(false)
    }
  }, [index])

  return (
    <main className={styles.declinedscreen}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Declined Screen
      </h1>
      <div className={styles['declinedscreen-wrapper']}>
        <p>{DELETE_REFUSE_TAKE_PART}</p>
        {index === 0 && (
          <div
            className={classnames(
              styles['declinedscreen-qs'],
              styles['declinedscreen-qs--q1']
            )}
          >
            <h2>Please select the person’s gender</h2>
            <MultipleChoice
              options={genderOptions}
              color={colors.butterflyBush}
              setValue={(choices) => handleValueSave('gender', choices[0])}
            />
          </div>
        )}
        {index === 1 && (
          <div
            className={classnames(
              styles['declinedscreen-qs'],
              styles['declinedscreen-qs--q2']
            )}
          >
            <h2>How old do you think the person is?</h2>
            <Slider
              color={colors.butterflyBush}
              min={10}
              max={80}
              step={10}
              customLabels={ageOptions}
              defaultValue={data.age ? Number(data.age) : undefined}
              setValue={(value) => handleValueSave('age', value.toString())}
            />
          </div>
        )}
        {index === 2 && (
          <div
            className={classnames(
              styles['declinedscreen-qs'],
              styles['declinedscreen-qs--q3']
            )}
          >
            <h2>
              Do you think the person is from an Aboriginal <br />
              or Torres Strait Islander background??
            </h2>
            <MultipleChoice
              className={styles['declinedscreen-qs--q3-options']}
              options={aboriginalOrIslanderOptions}
              color={colors.butterflyBush}
              setValue={(choices) =>
                handleValueSave('aboriginalOrIslander', choices[0])
              }
            />
          </div>
        )}
        {index === 3 && (
          <div
            className={classnames(
              styles['declinedscreen-qs'],
              styles['declinedscreen-qs--finish']
            )}
          >
            <h2>Thanks. Your answers have been saved.</h2>
          </div>
        )}
      </div>
      <div className={styles['declinedscreen-footer']}>
        <Button
          variation="primary"
          width="l"
          onClick={handleNextClick}
          disabled={invalid}
        >
          Next
        </Button>
      </div>
    </main>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  surveyMetaData: state.surveyMetaData
})

export const DeclinedScreen = connect(mapStateToProps)(DeclinedScreenComponent)

import { Screen } from 'components/Screen/Screen.component'
import { SecondaryColor } from 'components/Screen/Screen.model'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import React, { useState } from 'react'
import { AudioSelectScreenPropsModel } from './AudioSelectScreen.model'
import styles from './AudioSelectScreen.module.scss'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'

export const AudioSelectScreen: React.FC<AudioSelectScreenPropsModel> = (
  props
) => {
  const {
    currentScreenId,
    theme,
    headerProps,
    screenData: { title, subtitle, options },
    footerProps,
    shortcodes
  } = props

  const [optionValue, setOptionValue] = useState<string[]>([])

  const handleSetValue = (choices: string[]) => {
    setOptionValue(choices)
  }

  const handleHeaderProps = () => {
    const updatedHeaderProps = { ...headerProps }
    if (optionValue.length > 0) {
      updatedHeaderProps.voice = optionValue[0]
      headerProps.setVoice(optionValue[0])
    }

    return updatedHeaderProps
  }

  const { getNextScreenId } = matchCondition()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (optionValue.length === 0) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  return (
    <div className={`drug-app-screen ${styles.audioselectscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={handleHeaderProps()}
        footerProps={handleFooterProps()}
      >
        <div className={styles['audioselectscreen-choices']}>
          <MultipleChoice
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle)}
            isMultiple={false}
            options={options}
            defaultValue={optionValue}
            setValue={handleSetValue}
            color={SecondaryColor[theme]}
            withTick={true}
            isCarousel={false}
          />
        </div>
      </Screen>
    </div>
  )
}

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const OptionBorder1: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="275"
    height="129"
    viewBox="0 0 275 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5172 20.2401L1.51723 20.2397C1.62047 16.5347 1.63994 13.0314 1.6274 10.1719L1.6274 10.1713C1.61935 8.48764 2.1506 6.86586 3.68185 5.62282C5.26672 4.33625 8.13061 3.28428 13.1492 3.28428C18.0187 3.28428 18.282 3.27963 20.0276 3.24875C22.8635 3.1986 29.6114 3.07926 66.3766 2.79834L66.3861 2.7982L122.562 2.01334C122.565 2.01334 122.568 2.01332 122.571 2.01332C207.816 1.85481 237.454 3.14968 253.525 3.85187C256.045 3.96197 258.232 4.05749 260.247 4.13057C265.508 4.32154 268.374 5.13246 269.951 6.13576C271.387 7.04989 271.876 8.20303 272.073 9.71008C272.575 13.5524 273.168 18.4707 273.438 22.0996L273.438 22.1003C273.499 22.9083 273.514 29.1062 273.487 38.1722C273.461 47.1802 273.394 58.9072 273.299 70.6636C273.205 82.4204 273.082 94.2037 272.944 103.325C272.875 107.887 272.802 111.778 272.727 114.667C272.65 117.616 272.573 119.397 272.509 119.847L272.509 119.847C272.296 121.364 271.311 122.568 268.918 123.446C266.46 124.347 262.778 124.783 257.768 124.783C253.55 124.783 249.521 124.86 244.092 124.964C240.848 125.026 237.106 125.098 232.525 125.169C220.258 125.358 201.932 125.54 170.986 125.511L170.975 125.511L170.963 125.511L117.545 126.296C53.3652 127.195 37.4546 127.027 24.7411 126.893C20.6729 126.85 16.9322 126.81 12.042 126.81C8.33939 126.81 5.85529 126.073 4.33331 124.962C2.88358 123.904 2.15326 122.397 2.15326 120.378C2.15326 118.823 2.07648 108.887 1.97556 95.8252C1.75486 67.2612 1.41866 23.7479 1.5172 20.2401Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

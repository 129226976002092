import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import styles from './ProgressScreen.module.scss'
import { SideMenu } from 'components/SideMenu/SideMenu.component'
import { AnalysisModel, RADashboardMenu } from '../index.model'
import { useNavigate } from 'react-router-dom'
import { SurveyState } from 'store/type'
import { ProgressScreenPropsModel } from './ProgressScreen.model'
import { Dispatch } from 'redux'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { colors } from 'shared/theme/theme'
import Box from '@mui/material/Box'
import { Button } from 'components/Button/Button.component'
import { Select } from 'components/Select/Select.component'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'
import { RADashCalc } from 'shared/utils/raDashboardCalculation/raDashboardCalculation'
import { BorderType } from 'components/Border/Border.model'
import { IconType } from 'components/Icon/Icon.model'
import classnames from 'classnames'
import { syncSurveysAPI } from 'store/actionCreators'
import { Card } from 'components/Card/Card.component'
import { TargetGender } from 'shared/utils/raDashboardCalculation/raDashboardCalculation.model'

const ProgressScreenComponent: React.FC<ProgressScreenPropsModel> = (props) => {
  const {
    offline,
    serviceId,
    syncRounds,
    projects,
    projectId: defaultProjectId,
    siteId: defaultSiteId,
    surveyResponses
  } = props

  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const [projectId, setProjectId] = useState<string>(defaultProjectId)
  const [siteId, setSiteId] = useState<string>(defaultSiteId)
  const [isSync, setIsSync] = useState<boolean>(false)

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'rgba(51, 51, 51, .2)'
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: colors.capePalliser
    }
  }))

  const { generateProjectOptions, generateSiteOptions } = generateOptions()
  const { calcTotalResponses, generateTargetData, calcTargetSurveys } =
    RADashCalc()

  const totalSurveys = calcTotalResponses(projectId, siteId)
  const targetSurveys = calcTargetSurveys(projectId, siteId)
  const totalCompletedSurveys = totalSurveys.filter(
    (survey) =>
      !survey.metadata?.flaggedComingBack &&
      !survey.metadata?.flaggedDeleted &&
      !survey.metadata?.flaggedIncomplete
  )

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  const handleSyncAPI = () => {
    if (surveyResponses && surveyResponses.length > 0) {
      setIsSync(true)
      dispatch(syncSurveysAPI({ id: serviceId, surveyResponses }))
    }
  }

  const generateTargets = () => {
    const selectedProject = projects.find((p) => p.id === projectId)
    const site = selectedProject?.sites?.find((s) => s.id === siteId)
    const targets = site?.targets
    const results = generateTargetData(targets, totalCompletedSurveys)
    return (
      results &&
      results.map((item, index) => (
        <Box key={index}>
          <p>{item.value}</p>
          <div className={styles['progressscreen-analysis']}>
            {item.data &&
              item.data.map((currentData, _currentDataIndex) =>
                generateAnalysis(currentData.gender, currentData.data, index)
              )}
          </div>
        </Box>
      ))
    )
  }

  const generateAnalysis = (
    title: string,
    values: AnalysisModel[],
    index: number
  ) => {
    return (
      <div className={styles['progressscreen-analysis-section']}>
        <p>{title}</p>
        <div className={styles['progressscreen-analysis-section-wrapper']}>
          {values.map((item, index) => (
            <Box key={index} sx={{ width: '100%' }}>
              <label
                id={`analysis${index}`}
                className={styles['progressscreen-analysis-section-labels']}
              >
                <span>{item.label}</span>
                <span>{`${item.quantity} / ${item.count}`}</span>
              </label>
              <BorderLinearProgress
                className={styles['progressscreen-analysis-progress']}
                variant="determinate"
                value={item.count > 0 ? (item.quantity / item.count) * 100 : 0}
                aria-labelledby={`analysis${index}`}
              />
            </Box>
          ))}
        </div>
      </div>
    )
  }

  const calcTargetNumber = (
    total: boolean,
    age?: number[],
    gender?: TargetGender
  ) => {
    if (targetSurveys) {
      let totalNum = 0
      let filteredNum = 0

      targetSurveys.forEach((target) => {
        totalNum += target.count

        if (
          age &&
          gender &&
          age[0] <= target.minAge &&
          age[1] >= target.maxAge &&
          gender === target.gender
        ) {
          filteredNum += target.count
        }
      })

      if (total) {
        return totalNum
      } else {
        return filteredNum
      }
    }
    return 0
  }

  useEffect(() => {
    setIsSync(false)
  }, [syncRounds, surveyResponses, projectId, siteId])

  return (
    <div className={styles.progressscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[2].value}
        setValue={handleMenuChange}
      />
      <main className={styles['progressscreen-content']}>
        <h1
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none'
          }}
        >
          Overall Progress
        </h1>
        <div className={styles['progressscreen-row1']}>
          <Box component="form" className={styles['progressscreen-row1-form']}>
            <Select
              className={styles['progressscreen-select']}
              options={generateProjectOptions(projects)}
              placeholder="Select Project"
              selectProps={{
                defaultValue: defaultProjectId,
                inputProps: {
                  'aria-label': 'Select Project'
                }
              }}
              setValue={(value) => setProjectId(value)}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
            <Select
              className={styles['progressscreen-select']}
              options={generateSiteOptions(projects, projectId)}
              placeholder="Select Site"
              selectProps={{
                defaultValue: defaultSiteId,
                inputProps: {
                  'aria-label': 'Select Site'
                }
              }}
              setValue={(value) => setSiteId(value)}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
          </Box>
          {offline ? (
            <Button
              className={styles['progressscreen-row1-syncButton']}
              variation="primary"
              disabled
              icon={IconType.NotSync}
              style={{ backgroundColor: colors.white }}
            >
              Sync
            </Button>
          ) : (
            <Button
              className={classnames(styles['progressscreen-row1-syncButton'], {
                [styles['progressscreen-row1-syncButton--loading']]: isSync
              })}
              onClick={handleSyncAPI}
              icon={IconType.Sync}
              borderType={BorderType.Option1}
              style={{
                backgroundColor: isSync ? colors.greenSmoke : colors.white
              }}
            >
              {!isSync && 'Sync'}
            </Button>
          )}
        </div>
        <div className={styles['progressscreen-row2']}>
          <Card
            className={styles['progressscreen-card']}
            title={`${generateProjectOptions(projects, projectId)[0].label} / ${
              generateSiteOptions(projects, projectId)[0].label
            }`}
            titleColor={colors.breakerBay}
            titleHeight={57}
            centerAlign={false}
          >
            <div
              className={classnames(
                styles['progressscreen-analysisWrapper'],
                styles['progressscreen-analysisWrapper--total']
              )}
            >
              <div className={styles['progressscreen-analysis-section']}>
                <div
                  className={styles['progressscreen-analysis-section-wrapper']}
                >
                  <Box sx={{ width: '100%' }}>
                    <label
                      id="site-progress-label"
                      className={
                        styles['progressscreen-analysis-section-labels']
                      }
                    >
                      <span>People surveyed at this site overall</span>
                      <span
                        className={
                          styles['progressscreen-analysis-section-labels-big']
                        }
                      >
                        {`${totalCompletedSurveys.length} / ${calcTargetNumber(
                          true
                        )}`}
                        <span>
                          {(
                            (totalCompletedSurveys.length /
                              calcTargetNumber(true)) *
                            100
                          ).toFixed(1)}
                          %
                        </span>
                      </span>
                    </label>
                    <BorderLinearProgress
                      className={styles['progressscreen-analysis-progress']}
                      variant="determinate"
                      value={
                        (totalCompletedSurveys.length /
                          calcTargetNumber(true)) *
                        100
                      }
                      style={{ height: 16 }}
                      aria-labelledby="site-progress-label"
                    />
                  </Box>
                </div>
              </div>
            </div>
            <div className={styles['progressscreen-analysisWrapper']}>
              {generateTargets()}
            </div>
          </Card>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  serviceId: state.service?.id,
  syncRounds: state.syncRounds,
  projects: state.service?.projects || [],
  projectId: state.surveyMetaData?.projectId || '',
  siteId: state.surveyMetaData?.siteId || '',
  surveyResponses: state.surveyResponses
})

export const ProgressScreen = connect(mapStateToProps)(ProgressScreenComponent)

import React from 'react'
import { SelectPropsModel } from './Select.model'
import MuiSelect from '@mui/material/Select'
import styles from './Select.module.scss'
import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'
import MenuItem from '@mui/material/MenuItem'
import classnames from 'classnames'
import FormControl from '@mui/material/FormControl'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'

export const Select: React.FC<SelectPropsModel> = (props) => {
  const {
    className,
    selectProps,
    required,
    icon,
    dropIcon,
    options,
    placeholder,
    fullWidth,
    style,
    borderType,
    setValue
  } = props

  return (
    <FormControl
      required={required}
      className={classnames(className, styles.select)}
      style={style}
      fullWidth={fullWidth}
    >
      <Border
        type={borderType || BorderType.Select1}
        fill={style?.backgroundColor}
      />
      {icon && (
        <Icon
          className={styles['select-icon--before']}
          icon={IconType.Voice}
          size={24}
        />
      )}
      <MuiSelect
        className={classnames('u-border-style-sketch', {
          [styles.hasIcon]: !!icon
        })}
        autoWidth
        defaultValue=""
        displayEmpty
        variant="outlined"
        MenuProps={{
          disablePortal: true,
          disableScrollLock: true
        }}
        IconComponent={(iconProps) =>
          dropIcon ? (
            <Icon
              className={classnames(
                iconProps.className,
                styles['select-icon--drop']
              )}
              icon={dropIcon}
              size={18}
            />
          ) : (
            <Icon
              className={classnames(
                iconProps.className,
                styles['select-icon--drop']
              )}
              icon={IconType.ArrowDown}
              size={18}
            />
          )
        }
        renderValue={(selected) => {
          const value = selected as string
          if (value === '' || !value) {
            return <em>{placeholder}</em>
          }

          return options.find((item) => item.value === value)?.label
        }}
        onChange={(e) => setValue(e.target.value as string)}
        {...selectProps}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

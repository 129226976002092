import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconTechnical: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2254 27.9911C24.328 27.0034 27.2303 20.2169 27.2303 20.2169C29.9239 14.7385 28.7111 10.9204 26.5626 7.67009C24.4135 4.41823 19.8184 2.16817 15.0172 1.88942C10.6511 1.65527 7.37483 3.60277 7.37483 3.60277C7.37483 3.60277 1.55747 6.76293 2.50729 15.2044C2.49968 15.2775 2.92142 30.9012 18.2254 27.9911Z"
      fill={props.fill || '#6688AF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8968 2.97894C13.6784 3.00876 7.32823 3.9435 4.36598 8.47112C2.61799 11.1434 2.36231 14.5999 3.60715 18.7436C3.61013 18.7541 3.61311 18.7637 3.61609 18.7734C5.07561 24.1687 11.589 26.2432 11.6546 26.2633C17.3607 28.3654 20.6905 26.5384 23.2002 24.4758C25.927 22.2351 27.619 17.6792 27.4111 13.1374C27.2091 9.13156 25.1197 6.25204 25.0981 6.22297C24.9378 5.99413 21.5507 1.30997 14.022 2.95882C13.9803 2.96776 13.9393 2.97447 13.8968 2.97894ZM16.3969 29.447C14.7958 29.447 12.9882 29.1369 10.9375 28.3803C10.6781 28.302 3.2464 25.9309 1.46115 19.3727C-0.0647082 14.2779 0.416827 10.0067 2.89308 6.67692C6.29737 2.10011 12.28 0.967085 13.5435 0.779987L13.5427 0.774769C22.589 -1.20877 26.7469 4.67326 26.9184 4.92447C26.9959 5.02733 29.4073 8.31608 29.6443 13.0301C29.8874 18.3269 27.9157 23.4956 24.6203 26.2037C22.6539 27.819 20.0651 29.4462 16.3969 29.447Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1821 15.5473C13.7213 15.345 13.3305 15.0277 13.0384 14.6388C12.65 14.1214 12.4356 13.4765 12.4612 12.8053C12.4998 12.0982 12.8552 11.1216 13.1689 10.2595L13.1696 10.2576C13.2704 9.9803 13.3642 9.72032 13.4501 9.46148C14.0443 7.90089 14.9741 6.04956 15.0134 5.97139C15.2369 5.52748 15.7862 5.38712 16.2406 5.65742C16.6941 5.92844 16.881 6.50644 16.6585 6.95052C16.6493 6.96874 15.7287 8.80272 15.1807 10.2394C15.1038 10.4738 15.0029 10.7528 14.8944 11.0513C14.6503 11.723 14.3165 12.6425 14.2933 13.0633C14.2839 13.315 14.4036 13.664 14.7634 13.8231C14.9804 13.916 15.2951 13.9719 15.6109 14.012C15.6885 14.0125 15.7654 14.0212 15.84 14.0388C15.922 14.0477 16.0024 14.0558 16.0795 14.0636L16.0844 14.0641C16.3815 14.0944 16.6509 14.1219 16.8946 14.1717C17.4779 14.2244 18.1084 14.2991 18.6337 14.5195C19.0836 14.7134 19.423 15.0194 19.6328 15.3957C19.9118 15.8964 19.9618 16.522 19.7397 17.1744C19.4927 17.862 18.8184 18.8134 18.2232 19.6532L18.2218 19.655C18.0306 19.9251 17.8519 20.1784 17.6824 20.4305C16.5758 21.9509 15.0151 23.7559 14.9491 23.8321C14.5743 24.2649 13.9277 24.4046 13.5046 24.1445C13.0827 23.8838 13.0435 23.3231 13.4174 22.8901C13.4328 22.8723 14.9784 21.0843 15.9981 19.6846C16.1507 19.4563 16.3427 19.1845 16.5486 18.8937C17.012 18.2394 17.6459 17.3437 17.7932 16.9345C17.8763 16.6899 17.8451 16.3513 17.4941 16.1988C17.384 16.1527 17.242 16.1163 17.0839 16.0868C16.9882 16.0878 16.8895 16.0732 16.7907 16.0413C16.5645 16.0118 16.3262 15.9912 16.1119 15.9728L16.1066 15.9724C15.645 15.9322 15.2457 15.8975 14.924 15.7942C14.8874 15.7825 14.8526 15.7688 14.8196 15.7533C14.6028 15.7019 14.3883 15.6356 14.1821 15.5473Z"
      fill="#222222"
    />
  </svg>
)

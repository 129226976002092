import {
  ETHANOL_CONST,
  STANDARD_DRINK_CONST
} from 'shared/constants/Constants.d'
import {
  AGE,
  GENDER,
  GROG_12_MONTHS,
  GROG_12_MONTHS_FREQUENCY,
  GROG_DIARY,
  GROG_HARMS,
  GROG_IS_BOSS,
  GROG_LIFE,
  GROG_SHAKES,
  GROG_SPEND_TIME,
  HOW_OFTEN_SINGLE_DAY,
  PREGNANT_WEEKS,
  RISK_TYPE,
  TYPICAL_NUMBER_DRINKS
} from 'shared/constants/SurveyFields.d'
import store from 'store/store'
import { GrogDiaryData, GrogDiaryDataDetail } from 'store/type.d'
import {
  AlcoholCalcModel,
  RiskLevel,
  ScoreCalc
} from './alcoholCalculation.model'

export const AlcoholCalc = (): AlcoholCalcModel => {
  const storeData = store.getState().surveyData
  const demoMode = store.getState().user?.id === 'demo'
  const grogDiary: { [key: string]: GrogDiaryData } | undefined = storeData
    ? typeof storeData[GROG_DIARY] === 'string'
      ? JSON.parse(storeData[GROG_DIARY])
      : storeData[GROG_DIARY]
    : undefined

  const latestSession = grogDiary
    ? grogDiary[Object.keys(grogDiary)[0]]
    : undefined

  const calcAlcoholRiskLevel = () => {
    /** Demo Mode */
    if (demoMode && storeData && storeData[RISK_TYPE]) {
      return storeData[RISK_TYPE] as keyof typeof RiskLevel
    }
    /** Ends Demo Mode */

    // Not Drinking conditions
    if (
      !storeData ||
      !grogDiary ||
      !latestSession ||
      latestSession.consumptions.length === 0 ||
      storeData[GROG_LIFE]
    ) {
      return 'not drinking' as keyof typeof RiskLevel
    }

    /** Drinking Patterns */
    let shortTerm = false
    let longTerm = false

    // short term
    const standardDrinkArray: number[] = []
    Object.keys(grogDiary).forEach((key) => {
      standardDrinkArray.push(calcTotalStandardDrinks(grogDiary[key]))
    })

    if (Math.max(...standardDrinkArray) > 4) {
      shortTerm = true
    }

    /** LongTerm */
    const surveyData = store.getState().survey
    const screen = surveyData?.screens.find(
      (item) => item.surveyField === GROG_DIARY
    )
    const requiredDiaryOccasions =
      (screen?.data.grogDiary.requireDiary as number) || 2
    const requestedOccasions =
      requiredDiaryOccasions +
      ((screen?.data.grogDiary.otherDayDiary as number) || 2)

    let duration = 0
    if (Object.keys(grogDiary).length < requestedOccasions) {
      // less than requestedOccasions(4) occasions
      const cutoffDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      )
      const difference = new Date().getTime() - cutoffDate.getTime()
      duration = Math.ceil(difference / (1000 * 3600 * 24))
    } else {
      // more than requestedOccasions(4) occasions
      const difference =
        new Date().getTime() -
        new Date(
          Object.keys(grogDiary)[Object.keys(grogDiary).length - 1]
        ).getTime()
      duration = Math.ceil(difference / (1000 * 3600 * 24))

      let totalDurationBetweenOccasions = 0
      Object.keys(grogDiary).forEach((key, index) => {
        if (index === 0) {
          return
        }

        const diff =
          new Date(Object.keys(grogDiary)[index - 1]).getTime() -
          new Date(key).getTime()

        totalDurationBetweenOccasions =
          totalDurationBetweenOccasions + Math.ceil(diff / (1000 * 3600 * 24))
      })
      const meanGap =
        totalDurationBetweenOccasions / (Object.keys(grogDiary).length - 1)

      duration = duration + meanGap * 0.5 > 365 ? 365 : duration + meanGap * 0.5
    }

    const SDSum = standardDrinkArray.reduce((a, b) => a + b, 0)
    const SDperOccasion = SDSum / requiredDiaryOccasions

    const occasionsperDay = Object.keys(grogDiary).length / duration

    const SDperDay = SDperOccasion * occasionsperDay

    longTerm = SDperDay > 10 / 7

    /** AUDIT-C Score */
    let auditCRisk = false
    if (
      storeData[GENDER] &&
      storeData[GROG_12_MONTHS] &&
      storeData[GROG_12_MONTHS_FREQUENCY] &&
      storeData[TYPICAL_NUMBER_DRINKS] &&
      storeData[HOW_OFTEN_SINGLE_DAY]
    ) {
      const male = storeData[GENDER] === 'male'
      const grog12Months =
        storeData[GROG_12_MONTHS] == 'yes'
          ? Number(ScoreCalc[storeData[GROG_12_MONTHS_FREQUENCY]])
          : ScoreCalc['never']

      const grogNumberDrinks = Number(
        JSON.parse(storeData[TYPICAL_NUMBER_DRINKS]).value
      )
      const grogSingleDay = Number(
        ScoreCalc[JSON.parse(storeData[HOW_OFTEN_SINGLE_DAY]).value]
      )

      const auditCScore = grog12Months + grogNumberDrinks + grogSingleDay

      auditCRisk = male ? auditCScore > 4 : auditCScore > 3
    }

    /** Harms */
    const harmsKey = Object.keys(storeData).filter((key) =>
      key.includes(GROG_HARMS)
    )

    const harmsField: string[] | undefined =
      storeData && harmsKey.length > 0
        ? JSON.parse(storeData[harmsKey[0]])
        : undefined

    let harmsRisk = true

    if (harmsField) {
      if (
        harmsField.length === 1 &&
        harmsField[0] === 'no-worries-or-problems'
      ) {
        harmsRisk = false
      }
    } else {
      harmsRisk = false
    }

    /** Drinking while pregnant */
    const pregnantWeeks: number | undefined = storeData
      ? storeData[PREGNANT_WEEKS]
      : undefined

    const weeksDiff =
      new Date().getTime() - new Date(Object.keys(grogDiary)[0]).getTime()

    const weeksSince = Math.ceil(weeksDiff / (1000 * 3600 * 24)) / 7

    const pregnantRisk =
      pregnantWeeks != null && pregnantWeeks >= weeksSince ? true : false

    /** Drinking while under-age */
    const underageRisk = Number(storeData[AGE]) < 18

    /** Frequency of dependence symptoms */
    const grogBoss = Number(ScoreCalc[storeData[GROG_IS_BOSS]]) > 2 ? 1 : 0
    const grogShakes = Number(ScoreCalc[storeData[GROG_SHAKES]]) > 2 ? 1 : 0
    const grogSpendTime =
      Number(ScoreCalc[storeData[GROG_SPEND_TIME]]) > 2 ? 1 : 0

    const dependentRisk = grogBoss + grogShakes + grogSpendTime >= 2

    // Low Risk Conditions
    if (
      !shortTerm &&
      !longTerm &&
      !auditCRisk &&
      !harmsRisk &&
      !pregnantRisk &&
      !underageRisk &&
      !dependentRisk
    ) {
      return 'low risk' as keyof typeof RiskLevel
    }

    // Risky Conditions
    if (
      (shortTerm ||
        longTerm ||
        auditCRisk ||
        harmsRisk ||
        pregnantRisk ||
        underageRisk) &&
      !dependentRisk
    ) {
      return 'risky' as keyof typeof RiskLevel
    }

    // High Risk Conditions
    if (
      ((shortTerm ||
        longTerm ||
        auditCRisk ||
        harmsRisk ||
        pregnantRisk ||
        underageRisk) &&
        dependentRisk) ||
      dependentRisk
    ) {
      return 'high risk' as keyof typeof RiskLevel
    }
    return 'not drinking' as keyof typeof RiskLevel
  }

  const calcStandardDrinks = (consumption: GrogDiaryDataDetail): number => {
    let capacity = 0

    // Calc capacity for containers or products
    if (
      consumption.containerCapacity != null &&
      consumption.containerCapacity !== 0
    ) {
      capacity =
        consumption.containerCapacity *
        ((consumption.containerGrog != null ? consumption.containerGrog : 0) /
          100) *
        (consumption.drinkAmount != null && consumption.drinkAmount !== 0
          ? consumption.drinkAmount
          : 1)
    } else if (
      consumption.productCapacity != null &&
      consumption.productCapacity !== 0
    ) {
      const stepsPerSubContainer =
        consumption.stepsPerSubContainer != null
          ? consumption.stepsPerSubContainer
          : 0
      const subContainer =
        consumption.subContainer != null ? consumption.subContainer : 0
      const drinkAmount =
        consumption.drinkAmount != null ? consumption.drinkAmount : 0
      capacity =
        consumption.productCapacity *
        ((stepsPerSubContainer < subContainer
          ? stepsPerSubContainer / subContainer
          : subContainer / stepsPerSubContainer) *
          drinkAmount)
    }

    // Grams alcohol is capacity (ml) * ratio of alcohol * ethanol conversion constant (0.789)
    const gramsAlcohol =
      capacity *
      ((consumption.alcoholPecentage != null
        ? consumption.alcoholPecentage
        : 0) /
        100) *
      ETHANOL_CONST

    return gramsAlcohol / STANDARD_DRINK_CONST
  }

  const calcTotalStandardDrinks = (session?: GrogDiaryData): number => {
    let standardDrinks = 0
    const setSession = session || latestSession

    if (
      !grogDiary ||
      !setSession ||
      !setSession.consumptions ||
      setSession.consumptions.length === 0
    )
      return standardDrinks

    setSession.consumptions.forEach((consumption) => {
      standardDrinks += calcStandardDrinks(consumption)
    })

    return standardDrinks
  }

  return {
    calcAlcoholRiskLevel,
    calcTotalStandardDrinks,
    calcStandardDrinks
  }
}

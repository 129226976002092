import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconClock: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8477 2.68642C13.6529 2.68642 8.5771 4.67486 6.00699 7.77295C3.50757 10.7869 1.29272 14.7835 3.8163 21.5721C3.86373 21.7162 6.41058 29.5527 12.9289 31.3022C12.9379 31.304 12.9477 31.3076 12.9576 31.3103C17.8517 32.7439 21.9539 32.4808 25.1477 30.5272C29.9747 27.5786 31.5981 21.5014 32.0714 19.027C32.0876 18.9456 32.1037 18.8642 32.1242 18.7818C34.1154 9.74976 28.44 5.68518 28.1966 5.51605C28.1277 5.46594 24.6323 2.95131 19.7614 2.707C19.4571 2.69358 19.152 2.68642 18.8477 2.68642ZM18.7457 34.9068C16.7143 34.9068 14.5361 34.5685 12.2184 33.8911C4.26912 31.7487 1.39564 22.8187 1.27662 22.4392C-1.71678 14.3897 1.12628 9.45355 3.94159 6.05925C7.22224 2.10295 13.49 -0.277444 19.8903 0.0259225C25.6041 0.312286 29.5872 3.20635 29.7546 3.32984C30.0159 3.50703 37.1481 8.4996 34.7427 19.3761C34.7382 19.3966 34.7328 19.4163 34.7275 19.4369L34.7096 19.5309C34.1673 22.3569 32.2898 29.3102 26.5473 32.819C24.2707 34.2097 21.6603 34.9068 18.7457 34.9068Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0444 21.2335C11.3034 21.2335 10.702 20.6321 10.702 19.8911C10.702 19.1502 11.3034 18.5488 12.0444 18.5488C12.5965 18.5488 15.0091 18.3439 16.1671 18.2463L16.7649 18.1953C17.1747 18.1577 17.3841 17.8481 17.4396 17.5689C17.6097 16.6311 17.6696 15.4883 17.5962 14.537C17.3215 12.3553 17.4978 9.20797 17.5058 9.07553C17.547 8.33635 18.1824 7.76184 18.9216 7.81284C19.6616 7.8549 20.2281 8.48938 20.186 9.22945C20.1842 9.25898 20.016 12.2667 20.2666 14.2668C20.3641 15.5142 20.2934 16.8789 20.0769 18.0727C19.7681 19.6155 18.5332 20.7305 16.9984 20.871L16.3944 20.9211C14.5304 21.0795 12.6591 21.2335 12.0444 21.2335Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconFourPieSecond: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="202"
    height="194"
    preserveAspectRatio="none"
    viewBox="0 0 202 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="img2"
        patternUnits="userSpaceOnUse"
        width="100%"
        height="100%"
      >
        <image xlinkHref={props.image} x="0" y="0" width="100%" height="100%" />
      </pattern>
    </defs>
    <path
      d="M2.39648 20.7963V2.00177C6.29282 1.35636 13.4935 2.63214 15.7566 3.24932C17.9056 3.83539 32.2332 6.7017 47.8253 11.2876C63.4174 15.8735 64.4656 19.5423 75.8649 23.9971C87.2641 28.452 121.2 56.3606 128.013 59.8983C134.827 63.436 170.073 105.102 176.362 119.253C182.651 133.404 186.32 140.61 189.333 147.031C192.347 153.451 198.767 179.656 199.684 183.194C200.418 186.024 199.99 189.614 199.684 191.055C199.51 191.448 198.269 191.999 194.705 191.055C190.251 189.876 183.699 191.055 180.555 191.055H158.804C147.711 191.186 119.208 191.37 93.9465 191.055C62.3692 190.662 83.0344 191.055 67.8723 190.4C62.2425 190.157 52.4549 190.793 48.2184 190.4C44.5933 190.619 33.7531 190.924 19.3927 190.4C16.8158 190.619 11.1118 191.055 8.91057 191.055H2.39648V170.091C2.39648 156.464 3.66953 164.981 2.39648 152.272V103.227C2.39648 97.9136 3.24527 85.0796 3.24527 82.7907C3.24527 80.5019 2.39648 75.6763 2.39648 65.1514V20.7963Z"
      fill={props.image ? 'url(#img2)' : props.fill || '#8C625E'}
      fillOpacity={props.image ? '1' : '0.3'}
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const ModalBorder: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="793"
    height="427"
    viewBox="0 0 793 427"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M791.422 360.199C791.837 343.226 789.599 45.3178 789.599 29.146C789.599 13.0176 779.099 2.93288 756.916 2.93288C699.084 2.93288 697.243 0.708597 454.09 4.63289L300.791 7.22703C123.143 7.0331 90.3308 9.6318 51.7471 9.6318C22.7436 9.6318 6.99162 15.3631 5.1799 30.2093C3.37614 44.955 1.72369 342.59 2.48422 354.283C3.26863 366.423 4.98479 382.793 6.4262 395.499C7.77603 407.384 13.8523 416.969 44.4763 418.249C87.1493 420.031 156.662 425.855 439.76 425.249L601.023 422.654C771.623 421.153 717.042 421.049 753.739 421.049C783.542 421.049 791.164 406.732 791.108 393.309C791.072 383.898 791.128 372.376 791.422 360.199Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import { createContext } from 'react'

export interface AudioContextProps {
  files: { [page: string]: { [voice: string]: string } }
}

const initial: AudioContextProps = {
  files: {}
}

export const AudioContext = createContext<AudioContextProps>(initial)

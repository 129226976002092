import { Screen } from 'components/Screen/Screen.component'
import { SecondaryColor } from 'components/Screen/Screen.model'
import { CalendarDate } from 'components/CalendarDate/CalendarDate.component'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import { CalendarDateScreenPropsModel } from './CalendarDateScreen.model'
import styles from './CalendarDateScreen.module.scss'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { ModalScreen } from '../ModalScreen/ModalScreen'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'

export const CalendarDateScreen: React.FC<CalendarDateScreenPropsModel> = (
  props
) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId, showFollowUpQuestion } = matchCondition()
  const {
    currentScreenId,
    name,
    theme,
    headerProps,
    screenData: { title, subtitle },
    followUpQuestion,
    footerProps,
    shortcodes
  } = props

  const [dateValue, setDateValue] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleSetValue = (month?: string, date?: string) => {
    if (month && date) {
      const value = month + ' ' + date
      setDateValue(value)
      dispatch(
        updateSurveyAnswer({
          [name]: value
        } as ISurveyData)
      )

      // Remove follow up data if main screen data changed and doesn't match any conditions
      if (
        value &&
        followUpQuestion &&
        followUpQuestion.isActive &&
        !showFollowUpQuestion(value, followUpQuestion) &&
        followUpQuestion.surveyField
      ) {
        dispatch(
          updateSurveyAnswer({
            [followUpQuestion.surveyField]: undefined
          } as ISurveyData)
        )

        followUpQuestion?.additionalQuestions?.forEach((question) => {
          const { surveyField } = question
          if (surveyField) {
            dispatch(
              updateSurveyAnswer({
                [surveyField]: undefined
              } as ISurveyData)
            )
          }
        })

        setIsFollowUpValueSet(false)
      }
    } else {
      setDateValue(undefined)
      dispatch(
        updateSurveyAnswer({
          [name]: undefined
        } as ISurveyData)
      )
    }
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (!dateValue) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    // Next Screen Condition Logic
    if (
      dateValue &&
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(dateValue, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        dateValue,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    }
  }

  // Clear Redux
  useEffect(() => {
    const reset: ISurveyData = {
      [name]: undefined
    }

    if (followUpQuestion && followUpQuestion.surveyField) {
      reset[followUpQuestion.surveyField] = undefined
    }

    dispatch(updateSurveyAnswer(reset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div className={`drug-app-screen ${styles.calendardatescreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['calendardatescreen-calendar']}>
          <CalendarDate
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle)}
            defaultValue={dateValue}
            setValue={handleSetValue}
            color={SecondaryColor[theme]}
          />
        </div>
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              theme={theme}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              playAudio={footerProps?.playAudio}
              value={dateValue}
            />
          )}
      </Screen>
    </div>
  )
}

import { FooterPropsModel } from 'components/Footer/Footer.model'
import { HeaderPropsModel } from 'components/Header/Header.model'
import { HTMLAttributes } from 'react'

export enum ScreenTheme {
  default = 'default',
  demog = 'demog',
  initial = 'initial',
  alcohol = 'alcohol',
  tobacco = 'tobacco',
  cannabis = 'cannabis',
  meth = 'meth',
  heroin = 'heroin',
  opioids = 'opioids',
  benzos = 'benzos',
  other = 'other',
  harms = 'harms',
  treatment = 'treatment'
}

export enum MainColor {
  default = '#F7F3F0',
  demog = '#F2E6DE',
  initial = '#E2D8D7',
  alcohol = '#E2C8B6',
  tobacco = '#D9C2B7',
  cannabis = '#DEE3E0',
  meth = '#C2CFDF',
  heroin = '#E5CFAB',
  opioids = '#DFE2E8',
  benzos = '#BEB9CD',
  other = '#D9CCE0',
  harms = '#EEF1E9',
  treatment = '#CCE0DD'
}

export enum SecondaryColor {
  default = '#222222',
  demog = '#C57861',
  initial = '#8C625E',
  alcohol = '#B77548',
  tobacco = '#A1664C',
  cannabis = '#597464',
  meth = '#6688AF',
  heroin = '#BD862C',
  opioids = '#5E6E8C',
  benzos = '#5C5182',
  other = '#815596',
  harms = '#8FA26F',
  treatment = '#55968C'
}

export interface ScreenPropsModel extends HTMLAttributes<HTMLElement> {
  currentScreenId: string
  theme: ScreenTheme
  headerProps: HeaderPropsModel
  footerProps: FooterPropsModel
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconDecrease: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8888 3.25342C16.5765 3.25342 16.2704 3.26774 15.968 3.29548C12.0197 3.66865 7.58376 4.94028 4.62795 11.5463C4.55547 11.6904 0.870325 18.9426 4.21273 24.8318C4.21631 24.8381 4.21988 24.8452 4.22346 24.8515C6.77658 29.5318 10.0402 32.2782 13.9231 33.0129C20.1802 34.1959 25.9844 29.9184 26.3755 29.6186C26.4372 29.5604 26.5052 29.5076 26.5786 29.4611C34.4187 24.5007 33.2777 17.619 33.2258 17.3282C33.2097 17.2271 32.5788 13.0417 29.257 9.39592C25.8117 5.6365 20.9578 3.25342 16.8888 3.25342ZM16.0888 35.9087C11.3852 35.9096 6.03203 33.7682 1.8726 26.1473C-2.19019 18.9739 2.03099 10.7248 2.21355 10.3758C5.7573 2.44892 11.6179 1.00905 15.7156 0.623351C20.8308 0.140112 26.9205 2.8731 31.2383 7.58467C35.1463 11.8739 35.8479 16.6973 35.8756 16.9004C35.9329 17.1922 37.4354 25.6927 28.1277 31.6571C27.7456 31.9999 27.0789 32.4464 25.9155 33.1033C24.6824 33.7995 22.727 34.7525 20.4137 35.3423C19.0839 35.6814 17.6199 35.9087 16.0888 35.9087Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.26162 18.0778C9.26162 17.153 10.0122 16.4023 10.9371 16.4023C11.0912 16.4023 14.7281 16.4046 17.2324 16.6324C19.654 16.8447 24.2158 16.6302 24.2616 16.628C25.1853 16.5833 25.9705 17.2982 26.0141 18.2219C26.0744 19.1646 25.3439 19.9309 24.4202 19.9744C24.2247 19.9845 19.5859 20.2012 16.9353 19.97C14.574 19.7555 10.9728 19.7533 10.9371 19.7533C10.0122 19.7533 9.26162 19.0027 9.26162 18.0778Z"
      fill="#222222"
    />
  </svg>
)

import React from 'react'
import styles from './Postcode.module.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import classnames from 'classnames'
import { Button } from 'components/Button/Button.component'
import { numbers, PostCodePropsModel } from './Postcode.model'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'

export const PostCode: React.FC<PostCodePropsModel> = (props) => {
  const { onPinClick, handleModalOpen, handleDelete } = props

  const responsiveSize = window.outerHeight > 800 ? 66 : 55

  return (
    <div
      className={styles['postcode__numbers']}
      style={{ gridTemplateColumns: `repeat(3, ${responsiveSize}px)` }}
    >
      {numbers.map((item: string, index: number) => (
        <div key={index} style={{ position: 'relative', zIndex: 0 }}>
          <Border type={BorderType.Option2} fill={colors.white} dropShadow />
          <Card
            key={`number-${index}`}
            sx={{ maxWidth: responsiveSize, maxHeight: responsiveSize }}
            className={classnames(
              styles['postcode__number'],
              styles[`postcode__number--${index + 1}`],
              styles['u-border-style-sketch']
            )}
            onClick={() => {
              onPinClick(item)
            }}
          >
            <CardContent>
              <div>{item}</div>
            </CardContent>
          </Card>
        </div>
      ))}
      <Button
        className={styles['postcode__del']}
        variation="secondary"
        width="full"
        onClick={handleDelete}
      >
        Delete
      </Button>
      <Button
        variation="secondary"
        width="full"
        className={styles['postcode__idk']}
        onClick={handleModalOpen}
      >
        {`I don't know`}
      </Button>
    </div>
  )
}

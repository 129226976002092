import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const SelectBorder2: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="425"
    height="59"
    viewBox="0 0 425 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M422.985 48.536C423.106 46.3291 422.451 7.59284 422.451 5.49007C422.451 3.39294 419.378 2.08164 412.885 2.08164C395.957 2.08164 375.418 1.79242 234.245 2.30269L149.373 2.64C97.3734 2.61478 27.769 2.95269 16.4752 2.95269C7.98567 2.95269 3.37491 3.69791 2.8446 5.62832C2.31663 7.54567 1.83294 46.2464 2.05555 47.7668C2.28516 49.3453 2.78749 51.4739 3.20941 53.126C3.60452 54.6714 5.38308 55.9177 14.347 56.0841C26.8378 56.3158 107.185 57.0731 190.05 56.9943L367.253 56.657C417.19 56.4617 401.213 56.4482 411.955 56.4482C420.678 56.4482 422.909 54.5865 422.893 52.8413C422.882 51.6175 422.899 50.1193 422.985 48.536Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

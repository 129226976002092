import React from 'react'
import { BorderPropsModel } from '../Border.model'
import { v4 as uuidv4 } from 'uuid'

export const CircleBorder2: React.FC<Partial<BorderPropsModel>> = (props) => {
  const id = uuidv4()
  return (
    <svg
      className={props.className || ''}
      style={props.style}
      preserveAspectRatio="none"
      width="223"
      height="223"
      viewBox="0 0 223 223"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id={id}
          patternUnits="userSpaceOnUse"
          width="100%"
          height="100%"
        >
          <image
            xlinkHref={props.image}
            x="0"
            y="0"
            width="100%"
            height="100%"
          />
        </pattern>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32332 145.494C21.689 194.883 80.0445 212.954 80.0445 212.954C127.453 230.429 157.844 217.171 182.758 196.696C207.684 176.215 222.265 136.458 220.442 96.6953C218.624 60.5527 199.782 35.2529 199.782 35.2529C199.782 35.2529 168.778 -9.92533 100.102 5.13408C99.4943 5.13408 -28.7589 22.0066 8.32332 145.494Z"
        fill={props.image ? `url(#${id})` : props.fill || 'none'}
        stroke={props.stroke || '#222222'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconLogout: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7924 22.0702C8.34104 21.8025 7.96383 21.3931 7.7467 20.8843C7.58387 20.5601 7.23541 19.763 7.09989 18.8775C6.95419 17.8775 6.51537 16.4654 6.51032 16.4516C6.27975 15.7116 6.69454 14.9398 7.4381 14.7259C8.1799 14.5132 8.97114 14.9362 9.2024 15.6789C9.22324 15.7438 9.71242 17.3157 9.88963 18.5276C9.9362 18.8365 10.0591 19.2261 10.2233 19.5962C10.3655 19.5807 10.4927 19.5702 10.6203 19.5589C10.9005 19.5351 11.2828 19.502 11.4336 19.4647C11.7779 19.3993 12.4078 19.2637 12.9471 19.1454C13.4842 19.0276 14.0247 19.3611 14.1577 19.8947L14.3474 20.6559C14.4833 21.201 14.1438 21.751 13.595 21.871C13.0433 21.9916 12.4072 22.1282 12.0885 22.1881C11.8028 22.2602 11.3755 22.2967 10.9232 22.3365C10.7434 22.3523 10.5625 22.3663 10.3982 22.3859C9.85127 22.4813 9.27874 22.3586 8.7924 22.0702Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M10.1953 19.3719C10.1191 19.1565 10.5781 18.3396 13.0234 16.7947C15.4687 15.2498 16.177 14.9537 18.3153 14.3861C21.7675 13.813 23.2708 14.1536 25.1101 15.4126C27.3374 16.9372 27.6041 18.4048 28.1334 19.9045"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.131 3.62268C16.8187 3.62268 16.5126 3.637 16.2101 3.66474C12.2619 4.03791 7.82595 5.30955 4.87014 11.9156C4.79765 12.0597 1.11251 19.3118 4.45491 25.2011C4.45849 25.2073 4.46207 25.2145 4.46565 25.2208C7.01876 29.901 10.2824 32.6474 14.1653 33.3821C20.4224 34.5652 26.2266 30.2876 26.6177 29.9878C26.6794 29.9297 26.7474 29.8769 26.8208 29.8303C34.6609 24.87 33.5199 17.9883 33.468 17.6975C33.4519 17.5963 32.821 13.411 29.4992 9.76518C26.0539 6.00577 21.2 3.62268 17.131 3.62268ZM16.3309 36.278C11.6274 36.2789 6.27422 34.1374 2.11479 26.5166C-1.948 19.3432 2.27318 11.0941 2.45574 10.7451C5.99949 2.81818 11.8601 1.37831 15.9578 0.992614C21.073 0.509375 27.1627 3.24236 31.4805 7.95393C35.3885 12.2431 36.09 17.0666 36.1178 17.2697C36.1751 17.5614 37.6776 26.062 28.3699 32.0264C27.9877 32.3691 27.3211 32.8157 26.1577 33.4725C24.9245 34.1687 22.9692 35.1218 20.6559 35.7115C19.3261 36.0507 17.8621 36.278 16.3309 36.278Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

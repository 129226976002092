import { SiteTarget, Stratification } from 'api/client.model'
import groupBy from 'lodash/groupBy'
import {
  AnalysisModel,
  TargetAnalysisModel,
  TargetAnalysisData
} from 'screens/ra-dashboard/index.model'
import { AGE, GENDER, INDIGENOUS } from 'shared/constants/SurveyFields.d'
import store from 'store/store'
import { SurveyResponse } from 'store/type'
import { Gender, RADashCalcModel } from './raDashboardCalculation.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'

export const RADashCalc = (): RADashCalcModel => {
  const storeData = store.getState()
  const { isMatchCondition } = matchCondition()

  const calcTotalResponses = (
    projectId: string,
    siteId: string,
    today?: boolean,
    ra?: string
  ) => {
    if (!storeData.surveyResponses) return []
    return storeData.surveyResponses.filter(
      (data) =>
        data.local &&
        data.metadata?.projectId?.toString() === projectId &&
        data.metadata?.siteId?.toString() === siteId &&
        data.metadata.startTime &&
        (today
          ? new Date(data.metadata.startTime).toDateString() ===
            new Date().toDateString()
          : true) &&
        (ra ? data.metadata.raId === ra : true)
    )
  }

  const calcCategoryNumber = (
    aboriginal: boolean,
    gender: Gender,
    age: number[],
    responses: SurveyResponse[]
  ) => {
    const indigenous = ['aboriginal', 'both']
    const num = responses.filter(
      (res) =>
        res.responses &&
        (aboriginal
          ? indigenous.includes(res.responses[INDIGENOUS])
          : !indigenous.includes(res.responses[INDIGENOUS])) &&
        res.responses[GENDER] === gender &&
        age.includes(Number(res.responses[AGE]))
    )
    return num.length
  }

  const calcTargetNumber = (
    stratification: Stratification,
    gender: string,
    minAge: number,
    maxAge: number,
    responses: SurveyResponse[]
  ) => {
    const num = responses.filter(
      (res) =>
        res.responses &&
        res.responses[GENDER] === gender.toLowerCase() &&
        minAge <= Number(res.responses[AGE]) &&
        maxAge >= Number(res.responses[AGE]) &&
        (!stratification.value ||
          (stratification.value &&
            isMatchCondition(
              stratification,
              res.responses[stratification.surveyField]
            )))
    )

    return num.length
  }

  const calcTargetSurveys = (projectId: string, siteId: string) => {
    const targets = storeData.service?.projects
      .find((project) => project.id === projectId)
      ?.sites?.find((site) => site.id === siteId)?.targets
    return targets || undefined
  }

  const generateTargetData = (
    siteTargets: SiteTarget[] | null | undefined,
    responses: SurveyResponse[]
  ) => {
    if (!siteTargets) return []
    siteTargets = siteTargets.filter((item) => item.stratification)
    const groupedTarget = groupBy(siteTargets, (d) => {
      return d.stratification.label
    })
    const results: TargetAnalysisModel[] = []
    for (const targetValue in groupedTarget) {
      const targetvalue = groupedTarget[targetValue]
      const groupedGender = groupBy(targetvalue, 'gender')
      const data: TargetAnalysisData[] = []
      for (const gender in groupedGender) {
        const itemsInGroupedGender = groupedGender[gender]
        const analysisArr: AnalysisModel[] = []
        for (const item of itemsInGroupedGender) {
          const quantity = calcTargetNumber(
            item.stratification,
            gender,
            item.minAge,
            item.maxAge,
            responses
          )
          const analysisData: AnalysisModel = {
            label:
              `aged ${item.minAge}` +
              (item.maxAge <= 100 ? `-${item.maxAge}` : '+'),
            quantity: quantity,
            count: item.count
          }
          analysisArr.push(analysisData)
        }
        const oneData: TargetAnalysisData = {
          gender: gender,
          data: analysisArr
        }
        data.push(oneData)
      }
      const result: TargetAnalysisModel = {
        value: targetValue,
        data: data
      }
      results.push(result)
    }
    return results
  }

  return {
    calcTotalResponses,
    calcCategoryNumber,
    calcTargetSurveys,
    calcTargetNumber,
    generateTargetData
  }
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconVoice: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="26"
    height="19"
    viewBox="0 0 26 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3572 18.1558C19.9679 18.1558 19.5831 17.9885 19.3182 17.6636C18.8475 17.0909 18.9316 16.2443 19.5062 15.7754C21.1939 14.3919 22.6928 12.5762 22.2373 8.68611C22.2284 8.57693 21.8785 4.13203 18.9146 2.52124C18.2632 2.16686 18.0224 1.35252 18.3759 0.700144C18.7321 0.0486668 19.5446 -0.189373 20.197 0.162317C24.4701 2.48455 24.8934 8.19929 24.9104 8.44181C25.5216 13.6491 23.0902 16.3096 21.2064 17.8515C20.9577 18.0565 20.6561 18.1558 20.3572 18.1558Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0715 14.4452C15.7601 14.4452 15.4478 14.3378 15.1936 14.1176C14.6334 13.6326 14.5726 12.7843 15.0576 12.2241C15.3816 11.8509 15.8066 11.1949 15.7673 9.81681C15.7717 9.30941 15.6321 7.97961 14.9323 7.21C14.4339 6.66144 14.4742 5.81309 15.0227 5.31374C15.5704 4.81618 16.4214 4.85466 16.919 5.40412C18.4645 7.10441 18.4564 9.53671 18.451 9.80786C18.5011 11.4777 18.0546 12.8648 17.0872 13.9816C16.8214 14.2877 16.4474 14.4452 16.0715 14.4452Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97288 3.93631C5.63331 3.91215 6.27315 3.69469 6.76713 3.2553L7.95822 2.19754C8.19358 1.98814 8.47189 1.76621 8.76004 1.55054C9.62092 0.904434 10.8586 1.42436 10.9668 2.49554C11.2255 5.05134 11.5512 9.1007 11.2621 11.1187C11.042 12.6615 11.0706 14.436 11.1539 16.2535C11.2111 17.4974 9.74531 18.2089 8.79584 17.4026C8.75557 17.3686 8.7153 17.3345 8.67592 17.3005C7.51794 16.3081 6.45124 15.0893 5.56977 15.0893C5.05432 15.0893 3.99925 15.2298 2.08061 14.9246C0.609418 14.6902 0 13.7613 0 12.418V6.49747C0 5.26432 1.09534 4.25936 2.54685 4.18419C2.54685 4.18419 2.88064 4.01327 4.97288 3.93631Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconSmallSmile: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.3651 76.2961C82.6751 66.8253 83.5421 44.3234 83.5421 44.3234C85.2411 25.7761 77.6211 16.2203 67.954 9.303C58.2837 2.38187 42.7369 0.983131 28.7531 5.4259C16.0577 9.5212 8.84719 18.6437 8.84719 18.6437C8.84719 18.6437 -4.28077 33.9931 7.63319 57.0088C7.69119 57.2252 25.9347 101.281 66.3651 76.2961Z"
      fill={props.fill || '#C57861'}
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5854 54.3366C29.3596 55.0122 33.1269 56.7944 36.4394 57.1694C39.1111 57.783 41.3819 57.783 46.0573 57.783C50.0648 57.2243 54.433 56.1224 56.3431 55.0122C59.4155 53.2263 61.6864 50.5621 61.6864 50.5621"
      stroke="#222222"
      strokeWidth="5.04542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1048 34.0235C26.5667 35.7303 28.5834 36.3548 28.5834 36.3548C30.2217 36.9587 31.2719 36.5005 32.1329 35.793C32.9943 35.0852 33.4982 33.7113 33.4352 32.3372C33.3724 31.0882 32.7212 30.2139 32.7212 30.2139C32.7212 30.2139 31.6498 28.6526 29.2765 29.173C29.2555 29.173 24.8234 29.7561 26.1048 34.0235Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.5644 33.6105C58.0648 35.175 60.2495 35.7475 60.2495 35.7475C62.0243 36.3011 63.1621 35.8811 64.0948 35.2325C65.028 34.5837 65.5738 33.3243 65.5056 32.0647C65.4375 30.9197 64.7321 30.1183 64.7321 30.1183C64.7321 30.1183 63.5714 28.6871 61.0004 29.1642C60.9776 29.1642 56.1761 29.6987 57.5644 33.6105Z"
      fill="#222222"
    />
  </svg>
)

import React, { useEffect, useState } from 'react'
import styles from './Container.module.scss'
import classnames from 'classnames'
import { ContainerPropsModel } from './Container.model'
import { colors } from 'shared/theme/theme'

export const Container: React.FC<ContainerPropsModel> = (props) => {
  const {
    image,
    fillMaskImage,
    max = 100,
    topBorder = 0,
    bottomBorder = 0,
    drinkValue = 0,
    fizzyDrinkValue = 0,
    drinkColor,
    maxHeight,
    height
  } = props

  const [maskHeight, setMaskHeight] = useState<number>(0)
  const [maskTopBorder, setMaskTopBorder] = useState<number>(0)
  const [maskBottomBorder, setMaskBottomBorder] = useState<number>(0)
  const [maskLoaded, setMaskLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (maskLoaded) {
      const naturalHeight = (
        document.querySelector(
          `.${classnames(styles['container__image'])}`
        ) as HTMLImageElement
      ).naturalHeight
      const currentHeight = (
        document.querySelector(
          `.${classnames(styles['container__image'])}`
        ) as HTMLImageElement
      ).height
      const currentMaskTopBorder = (currentHeight * topBorder) / naturalHeight
      const currentMaskBottomBorder =
        (currentHeight * bottomBorder) / naturalHeight

      setMaskHeight(
        currentHeight - currentMaskTopBorder - currentMaskBottomBorder
      )
      setMaskTopBorder(currentMaskTopBorder)
      setMaskBottomBorder(currentMaskBottomBorder)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maskLoaded])

  return (
    <div className={classnames(styles['container__wrapper'])}>
      <img
        className={classnames(styles['container__image'])}
        style={{
          height,
          maxHeight
        }}
        src={image}
        onLoad={() => setMaskLoaded(true)}
        role="presentation"
        alt=""
      />
      <div
        className={classnames(styles['container__mask'])}
        style={{
          WebkitMaskImage: `url(${fillMaskImage})`,
          backgroundColor:
            fizzyDrinkValue !== 0 ? colors.fizzyDrink : undefined,
          clipPath: `inset(calc(${maskTopBorder}px + ${
            maskHeight * ((max - fizzyDrinkValue) / max)
          }px) 0% ${maskBottomBorder}px 0%)`
        }}
      />
      <div
        className={classnames(styles['container__mask'])}
        style={{
          WebkitMaskImage: `url(${fillMaskImage})`,
          backgroundColor:
            drinkValue !== 0
              ? !drinkColor || drinkColor === ''
                ? colors.black
                : drinkColor
              : undefined,
          clipPath: `inset(calc(${maskTopBorder}px + ${
            maskHeight * ((max - drinkValue) / max)
          }px) 0% ${maskBottomBorder}px 0%)`
        }}
      />
    </div>
  )
}

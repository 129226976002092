import React, { useEffect, useState } from 'react'
import { SectionProgressPropsModel } from './SectionProgress.model'
import styles from './SectionProgress.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import Paper from '@mui/material/Paper'
import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'

export const SectionProgress: React.FC<SectionProgressPropsModel> = (props) => {
  const {
    className,
    color,
    title,
    subtitle,
    options,
    click,
    setValue,
    playAudio
  } = props

  const [clicked, setClicked] = useState<number>()

  const calcGridColumnEnd = (index: number) => {
    if (options.length % 4 !== 0) {
      const ophanIndex = options.length % 4
      for (let i = 0; i < ophanIndex; i++) {
        if (options.length - i === index + 1) {
          return -(4 * 2 - (4 - ophanIndex + 1) - (index % 4) * 2)
        }
      }
    }
    return 'auto'
  }

  const handleItemClick = (index: number) => {
    if (click) {
      if (options?.[index]?.id && options?.[index]?.voices) {
        playAudio?.(`${options[index].id}-option`)
      }
      setClicked(index)
      setValue(options[index].screenId)
    }
  }

  useEffect(() => {
    if (click !== true) {
      const value = options.find(
        (option) => !option.disabled && !option.completed
      )
      setValue(value?.screenId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classnames(className, styles.sectionprogress)}>
      <div className={styles['sectionprogress-titles']}>
        {title && (
          <h1
            className="screen-titles--title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {subtitle && (
          <p
            className="screen-titles--subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </div>
      <div className={styles['sectionprogress-options']}>
        {options.map((option, index) => (
          <div
            key={`${index}-option`}
            className={classnames(styles['sectionprogress-option'], {
              [styles['sectionprogress-option--completed']]:
                option.completed || option.disabled,
              [styles['sectionprogress-option--dimmed']]:
                click && clicked !== undefined && index !== clicked,
              [styles['sectionprogress-option--nobg']]: click && !option.icon
            })}
            style={{ gridColumnEnd: calcGridColumnEnd(index) }}
            onClick={() => handleItemClick(index)}
          >
            <Border
              type={BorderType.Option1}
              fill={option.color || colors.white}
              dropShadow
            />
            <Paper className={styles['sectionprogress-option-card']}>
              {option.completed ? (
                <Icon
                  className={styles['sectionprogress-option-tickIcon']}
                  icon={IconType.Tick}
                  size={32}
                  stroke={colors.white}
                  fill={color}
                  border={false}
                />
              ) : (
                <></>
              )}
              <div className={styles['sectionprogress-option-content']}>
                {option.icon && (
                  <div className={styles['sectionprogress-option-icon']}>
                    <img src={option.icon} alt={'Image of ' + option.label} />
                  </div>
                )}
                <div className={styles['sectionprogress-option-label']}>
                  {option.label}
                </div>
              </div>
            </Paper>
          </div>
        ))}
      </div>
    </div>
  )
}

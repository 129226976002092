import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const SelectBorder1: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="205"
    height="59"
    viewBox="0 0 205 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M202.987 48.536C203.092 46.3291 202.522 7.59284 202.522 5.49007C202.522 3.39294 199.848 2.08164 194.198 2.08164C179.469 2.08164 179 1.79242 117.07 2.30269L78.0256 2.64C32.7795 2.61478 24.4224 2.95269 14.5953 2.95269C7.20831 2.95269 3.19635 3.69791 2.73491 5.62832C2.27551 7.54567 1.85464 46.2464 2.04834 47.7668C2.24812 49.3453 2.68522 51.4739 3.05234 53.126C3.39614 54.6714 4.94372 55.9177 12.7435 56.0841C23.6121 56.3158 41.3166 57.0731 113.42 56.9943L154.493 56.657C197.944 56.4618 184.043 56.4482 193.389 56.4482C200.98 56.4482 202.921 54.5865 202.907 52.8413C202.898 51.6175 202.912 50.1193 202.987 48.536Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import { Screen } from 'components/Screen/Screen.component'
import React, { useState } from 'react'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { TipsScreenOption, TipsScreenPropsModel } from './TipsScreen.model'
import styles from './TipsScreen.module.scss'
import { SurveyState } from 'store/type'
import { connect } from 'react-redux'
import { SectionProgress } from 'components/SectionProgress/SectionProgress.component'
import { MainColor, SecondaryColor } from 'components/Screen/Screen.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { SectionOption } from 'components/SectionProgress/SectionProgress.model'

export const TipsScreenComponent: React.FC<TipsScreenPropsModel> = (props) => {
  const {
    currentScreenId,
    theme,
    headerProps,
    screenData,
    footerProps,
    shortcodes,
    states
  } = props

  const { getNextScreenId } = matchCondition()

  const { title, subtitle, onlyShowUser, options } = screenData

  const [nextScreenId, setNextScreenId] = useState<string>()

  const defineUsing = (option: TipsScreenOption, defaultScreenId?: string) => {
    if (states && option.dependingScreen) {
      if (states[option.dependingScreen]) {
        return {
          using: true,
          screenId: option.user
        }
      } else {
        return {
          using: false,
          screenId: option.nonUser
        }
      }
    }
    return {
      using: false,
      screenId: option.nonUser || defaultScreenId
    }
  }

  const formatOptions = () => {
    const defaultScreenId = getNextScreenId(
      undefined,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    const sectionOptions = options.map((option) => {
      const usingStatus = defineUsing(option, defaultScreenId)
      return {
        using: usingStatus.using,
        label: option.label,
        icon: option.image,
        color: option.dependingScreen ? undefined : MainColor[theme],
        screenId: usingStatus.screenId,
        id: option.id,
        voices: option.voices,
        disabled: false,
        completed: false
      } as SectionOption & { using: boolean }
    })

    if (onlyShowUser) {
      return sectionOptions.filter((sectionOption) => sectionOption.using)
    }

    return sectionOptions
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (!nextScreenId) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        undefined,
        nextScreenId
      )
    }

    return updatedFooterProps
  }

  return (
    <div className={`drug-app-screen ${styles.tipsscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['tipsscreen-container']}>
          <SectionProgress
            color={SecondaryColor[theme]}
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle || undefined)}
            options={formatOptions()}
            click={true}
            setValue={(value) => setNextScreenId(value ? value : undefined)}
            playAudio={footerProps?.playAudio}
          />
        </div>
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData
})

export const TipsScreen = connect(mapStateToProps)(TipsScreenComponent)

export const DEMO_MODE_SERVICE_ID =
  process.env.REACT_APP_DEMO_MODE_SERVICE_ID ||
  '0fd66239-f646-4afe-a2d4-ca6cd23853ff'

// API Endpoints
export const DEFAULT_EMPTY_IMAGE_PATH = '/path/to/image.png'

export const SURVEY_DATA_API_BASE =
  process.env.REACT_APP_SURVEY_DATA_API_BASE ||
  'https://dev-cms-drug-app-tool.sydney.edu.au'

export const SURVEY_DATA_API_ENDPOINT = '/api/data/service'

export const LOGIN_DATA_API_ENDPOINT = '/api/data/ra/authenticate'

export const LOCATION_DATA_API_ENDPOINT = '/api/data/location'

export const SURVEY_SUBMISSION_API_BASE =
  process.env.REACT_APP_SURVEY_SUBMISSION_API_BASE

export const SURVEY_SUBMISSION_API_ENDPOINT = '/submissions-v2'

export const FEEDBACK_SUBMISSION_API_ENDPOINT = '/api/data/feedback/create'

export const FORGOT_PIN_API_ENDPOINT = '/api/data/ra/reset-pin'

export const VALIDATE_SLK_API_ENDPOINT = '/api/slk/validate'

// Survey Fields
export const LEAVE_SURVEY = 'leave'
export const CONTINUE_LATER = 'continue'

// Outro Fields
export enum UsingKeys {
  'grog' = 'GROG',
  'tobacco' = 'TOBACCO',
  'cannabis' = 'CANNABIS',
  'meth' = 'METH',
  'heroin' = 'HEROIN',
  'opioid' = 'OPIOIDS',
  'benzos' = 'BENZOS',
  'other' = 'OTHER'
}

export enum UsingTypes {
  'grog' = 'grog',
  'tobacco' = 'tobacco',
  'cannabis' = 'cannabis',
  'meth' = 'meth',
  'heroin' = 'heroin',
  'opioid' = 'opioid',
  'benzos' = 'benzos',
  'other' = 'other'
}

// Alcohol Constant for Standard Drinks Calculation
export const ETHANOL_CONST = 0.789
export const STANDARD_DRINK_CONST = 10

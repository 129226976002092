import { FollowUpQuestionModel, ShortCodeModel } from 'api/client.model'
import { CircleChoice } from 'components/CircleChoice/CircleChoice.component'
import { FollowUpBody } from 'components/FollowUpBody/FollowUpBody.component'
import { Form } from 'components/Form/Form.component'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { ScreenTheme } from 'components/Screen/Screen.model'
import { Slider } from 'components/Slider/Slider.component'
import { genericObject } from '../type'

export enum ComponentType {
  'multipleChoice' = 'multipleChoice',
  slider = 'slider',
  input = 'input',

  // Outro
  symptomsCircle = 'symptomsCircle'
}

export const ModalComponent = {
  'multipleChoice': MultipleChoice,
  slider: Slider,
  input: Form,

  // Outro
  symptomsCircle: CircleChoice,
  followUpBody: FollowUpBody
}

export interface ModalScreenPropsModel {
  screenId?: string
  theme: ScreenTheme
  modalData: FollowUpQuestionModel
  open: boolean
  onModalScreenClose: (hasValue: boolean) => void
  shortcodes?: ShortCodeModel[]
  playAudio?: (key?: string, newVoice?: string) => void
  optionFollowUp?: string
  value?: FollowUpOptionValue
}

export type FollowUpOptionValue =
  | number
  | string
  | string[]
  | number[]
  | genericObject
  | undefined

export interface FollowUpOptionValues {
  [key: string]: FollowUpOptionValue
}

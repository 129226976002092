import { ProjectDataModel } from 'api/client.model'
import { FeedbackOption } from 'components/Feedback/Feedback.model'
import { genericObject } from 'screens/survey/type'

export const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const feedbackOptions: FeedbackOption[] = [
  {
    'icon': 'Big Frown',
    'value': '1'
  },
  {
    'icon': 'Small Frown',
    'value': '2'
  },
  {
    'icon': 'Straight Face',
    'value': '3'
  },
  {
    'icon': 'Small Smile',
    'value': '4'
  },
  {
    'icon': 'Big Smile',
    'value': '5'
  }
]

export interface DailyFeedbackPropsModel {
  title?: string
  projects?: ProjectDataModel[]
  feedbackState?: genericObject
  feedbackProject?: string
  feedbackDate?: string
  feedbackSite?: string
  feedbackError?: string
  feedbackSubmitting?: boolean
  setFeedbackState: (value: genericObject) => void
  setFeedbackDate: (value: string) => void
  setFeedbackSite: (value: string) => void
  setFeedbackProject: (value: string) => void
  btnCallBack?: () => void
}

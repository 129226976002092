import React, { useState } from 'react'
import { ISurveyMetaData, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './EnterParticipantsSLKScreen.module.scss'
import { EnterParticipantsSLKScreenComponentProps } from './EnterParticipantsSLKScreen.model'
import { colors } from 'shared/theme/theme'
import { Input } from 'components/Input/Input.component'
import { Button } from 'components/Button/Button.component'
import { Dispatch } from '@reduxjs/toolkit'
import {
  updateSurveyAnswer,
  updateSurveyMetadata,
  updateContinueLocalIdentifier,
  updateAnalytics,
  updateUserJourney
} from 'store/reducer'
import { InputFormat } from 'components/Input/Input.model'
import { formatDate } from 'shared/utils/formatTime/formatTime'

const EnterParticipantsSLKScreenComponent: React.FC<
  EnterParticipantsSLKScreenComponentProps
> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const {
    option,
    surveyMetaData,
    surveyResponses,
    surveyScreens,
    isContinueSurvey
  } = props

  const [SLKValue, setSLKValue] = useState<string>()
  const [errorText, setErrorText] = useState<string>()
  const [isValid, setValidStatus] = useState<boolean>(false)
  const [isSurveyFoundState, setSurveyFoundState] = useState<boolean>(false)
  const [nextScreenId, setNextScreenId] = useState<string>()
  const [lastCompleteDate, setlastCompleteDate] = useState<string>()

  const handleInputValue = (value: string, error?: string) => {
    if (error) {
      setErrorText(error)
      setValidStatus(false)
    } else {
      setErrorText(undefined)
      setSLKValue(value)
      setValidStatus(true)
    }
  }

  const handleSubmitSLK = () => {
    if (!isValid) return
    const latestScreenMatchSLK = surveyResponses?.find(
      (x) =>
        x.metadata?.slk?.trim() === SLKValue?.trim() &&
        x.metadata?.flaggedIncomplete &&
        !x.metadata?.flaggedDeleted &&
        x.metadata?.siteId == surveyMetaData?.siteId &&
        x.metadata?.projectId == surveyMetaData?.projectId
    )
    const latestCompleteScreenMatchSLK = surveyResponses?.find(
      (x) => x.metadata?.slk?.trim() === SLKValue?.trim()
    )
    if (!isContinueSurvey) {
      if (latestScreenMatchSLK) {
        setErrorText('SLK already exited, please continue your survey!')
        return
      } else if (latestCompleteScreenMatchSLK) {
        setErrorText('SLK already used!')
        return
      }
      const metadata: ISurveyMetaData = {
        ...surveyMetaData,
        slk: SLKValue
      }
      dispatch(updateSurveyMetadata(metadata))
      dispatch(updateContinueLocalIdentifier(undefined))
      navigate('/survey')
    } else {
      if (!latestScreenMatchSLK) {
        setErrorText('Not found')
        return
      }
      setSurveyFoundState(true)
      const enDate = formatDate(latestScreenMatchSLK.metadata!.endTime)
      setlastCompleteDate(enDate)
      dispatch(updateSurveyAnswer(latestScreenMatchSLK.responses))
      dispatch(
        updateUserJourney({
          userJourney: latestScreenMatchSLK.userJourney,
          continue: true
        })
      )
      dispatch(updateAnalytics(latestScreenMatchSLK.analytics))
      dispatch(
        updateContinueLocalIdentifier(latestScreenMatchSLK.localIdentifier)
      )
      dispatch(
        updateSurveyMetadata({
          ...latestScreenMatchSLK.metadata,
          resumeTime: new Date()
        })
      )
      if (latestScreenMatchSLK.metadata!.breakPoint) {
        setNextScreenId(latestScreenMatchSLK.metadata!.breakPoint)
      } else {
        const lastedField = Object.keys(latestScreenMatchSLK.responses!).at(-1)
        const lastScreen = surveyScreens?.find(
          (item) => item.surveyField === lastedField
        )
        setNextScreenId(lastScreen ? lastScreen.id : '')
      }
    }
  }

  return isSurveyFoundState && isContinueSurvey ? (
    <main className={styles.enterparticipantsslkscreen}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Enter Participant SLK
      </h1>
      <div className={styles['enterparticipantsslkscreen-content']}>
        <div
          className={
            styles['enterparticipantsslkscreen-content-foundedtextcontainer']
          }
        >
          <h2
            className={
              styles[
                'enterparticipantsslkscreen-content-foundedtextcontainer-title'
              ]
            }
          >
            You’ve successfully found an existing participant.
          </h2>
          <p>Participant last completed a survey on {lastCompleteDate}</p>
        </div>
        <Button
          className={styles['enterparticipantsslkscreen-content-button']}
          variation="primary"
          width="l"
          onClick={() => navigate(`/survey/${nextScreenId}`)}
        >
          Next
        </Button>
      </div>
    </main>
  ) : (
    <div className={styles.enterparticipantsslkscreen}>
      <div className={styles['enterparticipantsslkscreen-content']}>
        <h2>Please enter the participants SLK</h2>
        <div
          className={
            styles['enterparticipantsslkscreen-content-inputcontainer']
          }
        >
          <Input
            className={
              styles['enterparticipantsslkscreen-content-inputcontainer-input']
            }
            inputOption={option}
            setValue={(value, error) => handleInputValue(value, error)}
            style={{ backgroundColor: colors.white }}
          />
          <p
            className={
              styles[
                'enterparticipantsslkscreen-content-inputcontainer-errortext'
              ]
            }
          >
            {errorText}
          </p>
        </div>
        <Button
          className={styles['enterparticipantsslkscreen-content-button']}
          variation="primary"
          width="l"
          onClick={() => handleSubmitSLK()}
          disabled={!isValid}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  surveyData: state.surveyData,
  surveyMetaData: state.surveyMetaData,
  surveyResponses: state.surveyResponses,
  surveyScreens: state.survey?.screens,
  option: {
    label: 'Participants SLK',
    value: null,
    required: false,
    allowMultipleLines: false,
    inputFormat: InputFormat.slk
  }
})

export const EnterParticipantsSLKScreen = connect(mapStateToProps)(
  EnterParticipantsSLKScreenComponent
)

import { IconType } from 'components/Icon/Icon.model'
import { TargetGender } from 'shared/utils/raDashboardCalculation/raDashboardCalculation.model'

export const RADashboardMenu = [
  {
    icon: IconType.Dashboard,
    label: 'Dashboard',
    value: 'dashboard'
  },
  {
    icon: IconType.Surveys,
    label: 'Surveys',
    value: 'surveys'
  },
  {
    icon: IconType.Support,
    label: 'Support',
    value: 'support'
  },
  {
    icon: IconType.Profile,
    label: 'My profile',
    value: 'profile'
  },
  {
    icon: IconType.Logout,
    label: 'Logout',
    value: 'logout'
  }
]

export interface AnalysisModel {
  label: string
  quantity: number
  count: number
  age?: number[]
  gender?: TargetGender
}

export interface TargetAnalysisModel {
  value: string
  data: TargetAnalysisData[]
}

export interface TargetAnalysisData {
  gender: string
  data: AnalysisModel[]
}

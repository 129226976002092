import React, { useEffect, useState } from 'react'
import { GrogSliderPropsModel } from './GrogSlider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './GrogSlider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icon/Icon.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { IconType } from 'components/Icon/Icon.model'
import { Button } from 'components/Button/Button.component'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Product } from '../Product/Product.component'

export const GrogSlider: React.FC<GrogSliderPropsModel> = (props) => {
  const {
    className,
    color = colors.black,
    bgColor,
    vertical,
    title,
    subtitle,
    image,
    fillMaskImage,
    icon = IconType.Hand,
    min = 0,
    max = 100,
    defaultValue,
    setValue,
    subContainer = 1,
    stepsPerSubContainer = 1,
    topBorder = 0,
    bottomBorder = 0,
    drinkColor,
    addFizzyDrink = false,
    customComponent,
    isShare = false,
    shareMax,
    ...componentProps
  } = props

  const smallScreen = window.outerHeight < 800

  const IconSlider = styled(MuiSlider)({
    color: color,
    width: vertical ? 10 : undefined,
    height: vertical ? undefined : 10,
    '& .MuiSlider-valueLabel': {
      backgroundColor: color
    }
  })

  const [rangeValue, setRangeValue] = useState<number | number[]>(min)
  const [fizzy, setFizzy] = useState<boolean | undefined>(undefined)

  const drinkValue = fizzy
    ? (rangeValue as number[])[0]
    : Number(rangeValue) || 0
  const fizzyDrinkValue = fizzy ? (rangeValue as number[])[1] : 0

  useEffect(() => {
    let newValue

    if (fizzy) {
      newValue = [
        rangeValue as number,
        (rangeValue as number) + 20 > max ? max : (rangeValue as number) + 20
      ]
    } else {
      newValue = rangeValue ? (rangeValue as number[])[0] : min
    }

    if (fizzy !== undefined) {
      setValue(newValue)
    }

    setRangeValue(newValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fizzy])

  /**
   * Generate slider thumb component
   * @param thumbProps Html attributes for slider thumb
   * @returns {JSX.Element} Slider thumb component
   */
  const SliderThumbComponent = (
    thumbProps: React.HTMLAttributes<HTMLElement>
  ) => {
    const { children, ...other } = thumbProps
    return (
      <SliderThumb {...other}>
        {children}
        {icon && (
          <Icon icon={icon} size={47} stroke={colors.white} fill={color} />
        )}
      </SliderThumb>
    )
  }

  const calcPercent = (val: number, max: number): number =>
    Math.floor((val / max) * 100)

  const getVerticalDescription = (
    grogValue = 0,
    fizzyValue = 0,
    max = 0
  ): string => {
    let desc = `${calcPercent(grogValue, max)}% container filled with grog`

    if (fizzyValue) {
      desc += `, ${calcPercent(
        fizzyValue - grogValue,
        max
      )}% filled with fizzy drink or juice`
    }
    return desc
  }

  /**
   * Generate vertical or horizontal dynamic slider image
   * @returns {JSX.Element} dynamic image
   */
  const generateImage = () => {
    if (vertical && fillMaskImage) {
      return (
        <Container
          image={image || ''}
          fillMaskImage={fillMaskImage}
          min={min}
          max={max}
          height={smallScreen ? 400 : 450}
          topBorder={topBorder}
          bottomBorder={bottomBorder}
          drinkValue={drinkValue}
          fizzyDrinkValue={fizzyDrinkValue}
          drinkColor={drinkColor}
        />
      )
    } else {
      const tempValue = Number(rangeValue) || min
      const step = subContainer === 1 ? stepsPerSubContainer : subContainer
      const isPack = subContainer === 1 ? false : true

      if (customComponent) {
        return (
          <TransitionGroup
            className={classnames(
              styles['grog-slider__dynamic--horizontal-wrapper']
            )}
          >
            {new Array(max).fill(0).map((_value, index) => (
              <div
                className={classnames(
                  styles['grog-slider__dynamic--horizontal-item']
                )}
                style={{
                  opacity: tempValue <= index ? (isShare ? 0.2 : 0) : 1
                }}
                key={index}
              >
                {customComponent}
              </div>
            ))}
          </TransitionGroup>
        )
      }

      return (
        <TransitionGroup
          className={classnames(
            styles['grog-slider__dynamic--horizontal-wrapper']
          )}
        >
          {new Array(max).fill(0).map((_value, index) => {
            const clipPercentage =
              100 -
              (tempValue - (index + 1) * step > 0
                ? 1
                : (tempValue - index * step) / step) *
                100

            return (
              <CSSTransition key={index} timeout={300} classNames="fade">
                <div
                  className={classnames(
                    styles['grog-slider__dynamic--horizontal-item']
                  )}
                  style={{
                    opacity:
                      Math.ceil(tempValue / step) > index || isShare ? 1 : 0
                  }}
                >
                  <Product
                    isPack={isPack}
                    clipPercentage={clipPercentage}
                    image={image || ''}
                  />
                </div>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      )
    }
  }

  /**
   * Generate fizzy button string
   * @returns {string} fizzy button string
   */
  const generateFizzyString = () =>
    fizzy ? 'Remove fizzy drink or juice' : 'Add fizzy drink or juice'

  return (
    <div
      className={classnames(styles['grog-slider__wrapper'], className)}
      {...componentProps}
    >
      {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      {subtitle && <p>{subtitle}</p>}
      <div
        className={classnames(styles.sliderAlign, {
          [styles['sliderAlign--inline']]: vertical,
          [styles['sliderAlign--middle']]: !(title || subtitle || image),
          [styles['grog-slider__dynamic--vertical']]: vertical,
          [styles['grog-slider__dynamic--horizontal']]: !vertical
        })}
      >
        <>
          {generateImage()}
          <div
            className={classnames(styles['grog-slider__inner-wrapper'])}
            style={{
              // offset border top by 15px which is equal to 15px less height set on slider to center
              paddingTop: topBorder / 2 + 15,
              paddingBottom: bottomBorder / 2
            }}
          >
            <IconSlider
              className={classnames(styles.slider)}
              components={{ Thumb: SliderThumbComponent }}
              defaultValue={rangeValue}
              orientation={vertical ? 'vertical' : undefined}
              min={min}
              max={
                vertical
                  ? max
                  : shareMax != null && shareMax !== 0
                  ? shareMax
                  : max * subContainer * stepsPerSubContainer
              }
              aria-label={
                vertical
                  ? getVerticalDescription(drinkValue, fizzyDrinkValue, max)
                  : calcPercent(
                      drinkValue,
                      vertical
                        ? max
                        : shareMax != null && shareMax !== 0
                        ? shareMax
                        : max * subContainer * stepsPerSubContainer
                    ).toString()
              }
              onChangeCommitted={(_e, value) => {
                setRangeValue(value)
                setValue(value)
              }}
            />
            {vertical && addFizzyDrink ? (
              <Button
                variation="secondary"
                width="m"
                onClick={() => setFizzy(!fizzy)}
                className={classnames(styles['grog-slider__dynamic-button'])}
              >
                <Icon
                  className={classnames(
                    styles['grog-slider__dynamic-icon'],
                    fizzy && styles['grog-slider__dynamic-icon--fizzy']
                  )}
                  icon={IconType.Plus}
                  size={36}
                  bgColor={bgColor}
                />
                {generateFizzyString()}
              </Button>
            ) : null}
          </div>
        </>
      </div>
    </div>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconInformation: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.776 2.68506C16.4636 2.68506 16.1576 2.69938 15.8551 2.72712C11.9069 3.10029 7.47094 4.37192 4.51602 10.978C4.44354 11.1221 0.7575 18.3742 4.0999 24.2635C4.10348 24.2697 4.10706 24.2769 4.11064 24.2831C6.66375 28.9634 9.9274 31.7098 13.8103 32.4445C20.0691 33.6275 25.8716 29.35 26.2627 29.0502C26.3244 28.992 26.3924 28.9392 26.4658 28.8927C34.3059 23.9323 33.1649 17.0507 33.113 16.7607C33.0969 16.6587 32.466 12.4733 29.1451 8.82667C25.6989 5.06814 20.845 2.68506 16.776 2.68506ZM15.9759 35.3404C11.2724 35.3413 5.9192 33.1998 1.75888 25.578C-2.30391 18.4046 1.91906 10.1556 2.10162 9.80746C5.64447 1.88056 11.5051 0.440688 15.6028 0.0549916C20.726 -0.428247 26.8085 2.30474 31.1264 7.01631C35.0334 11.3055 35.735 16.1289 35.7628 16.3321C35.82 16.6229 37.3235 25.1243 28.0148 31.0897C27.6327 31.4315 26.966 31.878 25.8027 32.5349C24.5695 33.2311 22.6142 34.1842 20.3009 34.7739C18.9711 35.1131 17.5071 35.3404 15.9759 35.3404Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0853 19.4293C16.3443 19.4293 15.743 18.8279 15.743 18.0869C15.743 17.9894 15.7448 15.6636 15.9309 14.0537C16.0947 12.5726 15.9264 9.73317 15.9246 9.70543C15.8799 8.96536 16.4437 8.32909 17.1829 8.28435C17.9426 8.23245 18.5592 8.80249 18.6039 9.54256C18.612 9.66874 18.7883 12.6389 18.5986 14.3553C18.4294 15.8202 18.4276 18.0646 18.4276 18.0869C18.4276 18.8279 17.8263 19.4293 17.0853 19.4293Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.741 24.4788C18.562 25.2842 17.5776 25.5526 17.5776 25.5526C16.8617 25.8211 16.3248 25.6421 15.9668 25.2842C15.6089 24.9262 15.3404 24.2998 15.3404 23.6734C15.3404 23.0469 15.6983 22.689 15.6983 22.689C15.6983 22.689 16.1458 21.9731 17.3091 22.2415C17.2197 22.2415 19.2779 22.51 18.741 24.4788Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

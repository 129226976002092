import React from 'react'
import { IconPropsModel, iconComponents } from './Icon.model'
import classnames from 'classnames'
import styles from './Icon.module.scss'

export const Icon: React.FC<IconPropsModel> = (props) => {
  const {
    icon,
    className,
    stroke,
    border,
    fill,
    size,
    title,
    ...componentProps
  } = props

  const Component = iconComponents[icon]

  const classes = classnames(className, styles.icon)

  return (
    <Component
      className={classes}
      stroke={stroke}
      fill={fill}
      border={border}
      style={{ width: size }}
      {...componentProps}
    />
  )
}

// Fixed Survey Field
export const GENDER = 'participant-gender'
export const AGE = 'age'
export const INDIGENOUS = 'indigenous'
export const USING_STATUS = 'grog-drugs-using-status'
export const GROG_DIARY = 'grog-diary'
export const TOBACCO_TYPE = 'tobacco-smoke-mostly'
export const TOBACCO_AMOUNT = [
  'how-many-rollies-per-day',
  'how-many-cigarettes-per-day',
  'how-many-bumpers-dumpers-per-day'
]
export const CANNABIS_TYPE = 'cannabis-which-mostly-use'
export const CANNABIS_AMOUNT = [
  'how-many-joints-a-day',
  'how-many-cones-per-day'
]
export const METH_TYPE = 'ice-meth-mostly-use'
export const HEROIN_TYPE = 'mostly-use-heroin'
export const OPIOIDS_TYPE = ['mostly-use-which-opioids', 'mostly-use-opioids']
export const OPIOIDS_AMOUNT = 'how-many-opioids'
export const BENZOS_TYPE = 'mostly-use-benzos'
export const BENZOS_AMOUNT = 'how-many-benzos'
export const OTHER_TYPE = 'drugs-types-other'

export const REFUSAL_GENDER = 'refusal-gender'
export const REFUSAL_AGE = 'refusal-age'

// Alcohol Calculation
export const GROG_HARMS = 'current-user-problems'
export const PREGNANT_WEEKS = 'pregnant-how-many-weeks'
export const GROG_IS_BOSS = 'grog-is-boss'
export const GROG_SHAKES = 'grog-shakes'
export const GROG_SPEND_TIME = 'spend-time-drinking'
export const GROG_12_MONTHS = 'grog-12-months'
export const GROG_12_MONTHS_FREQUENCY = 'grog-12-months-frequency'
export const GROG_LIFE = 'grog-life'
export const TYPICAL_NUMBER_DRINKS = 'typical-number-drinks'
export const HOW_OFTEN_SINGLE_DAY = 'how-often-single-day'

// Demo Mode
export const RISK_TYPE = 'risk-type'

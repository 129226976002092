import { createSlice } from '@reduxjs/toolkit'
import { fetchSurveyAPI, loginAPI, syncSurveysAPI } from './actionCreators'
import { SurveyState } from './type'
import { v4 as uuidv4 } from 'uuid'

const initialState: SurveyState = {
  offline: undefined,
  syncRounds: undefined,
  loggedIn: undefined,
  loginAttempt: undefined,
  user: undefined,
  service: undefined,
  demoService: undefined,
  geoLocations: undefined,
  demoGeoLocations: undefined,
  grogShops: undefined,
  demoGrogShops: undefined,
  survey: undefined,
  surveyData: undefined,
  surveyMetaData: undefined,
  surveyResponses: [],
  continueLocalIdentifier: undefined,
  userJourney: [],
  drinkingSessions: undefined,
  analytics: undefined,
  lastQTime: undefined
}

const slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    updateUserJourney(state, action) {
      if (action.payload && action.payload.continue) {
        state.userJourney = action.payload.userJourney
      } else {
        if (
          state.userJourney &&
          state.userJourney?.length > 0 &&
          action.payload === -1
        ) {
          state.userJourney.pop()
        } else if (action.payload === undefined) {
          state.userJourney = []
        } else {
          if (!state.userJourney) {
            state.userJourney = []
          }
          state.userJourney?.push(action.payload)
        }
      }
    },
    updateService(state, action) {
      state.service = action.payload
    },
    updateUser(state, action) {
      state.user = action.payload
    },
    updateSurvey(state, action) {
      state.survey = action.payload
    },
    updateDrinkingSession(state, action) {
      state.drinkingSessions = action.payload
    },
    logout(state) {
      state.offline = undefined
      state.loggedIn = undefined
      state.loginAttempt = undefined
      state.user = undefined
      state.survey = undefined
      state.surveyData = undefined
      state.surveyMetaData = undefined
      state.userJourney = []
      state.analytics = undefined
      state.lastQTime = undefined
      state.continueLocalIdentifier = undefined
    },
    updateSurveyMetadata(state, action) {
      state.surveyMetaData = {
        ...state.surveyMetaData,
        ...action.payload
      }
    },
    updateSurveyAnswer(state, action) {
      state.surveyData = {
        ...state.surveyData,
        ...action.payload
      }
    },
    updateContinueLocalIdentifier(state, action) {
      state.continueLocalIdentifier = action.payload
    },
    updateMetadataByLocalIdentifier(state, action) {
      const survey = state.surveyResponses?.find(
        (x) => x.localIdentifier == action.payload.localIdentifer
      )
      if (survey && action.payload.metadata) {
        survey.metadata = action.payload.metadata
      }
    },
    updateContinueSurveyResponse(state, action) {
      const lastedSurvey = state.surveyResponses?.filter(
        (x) =>
          state.continueLocalIdentifier &&
          x.localIdentifier == state.continueLocalIdentifier
      )

      if (lastedSurvey && lastedSurvey.length > 0) {
        const updateResponse = { ...lastedSurvey[0] }
        if (updateResponse && updateResponse.metadata) {
          updateResponse.metadata = {
            ...action.payload.metadata,
            slk: updateResponse.metadata.slk
          }
          updateResponse.responses = { ...action.payload.responses }
          updateResponse.analytics = action.payload.analytics
          updateResponse.userJourney = action.payload.userJourney
          updateResponse.drinkingSessions = action.payload.drinkingSessions
          updateResponse.local = true

          state.surveyResponses = [
            ...(state.surveyResponses || []).filter(
              (each) => each.localIdentifier != state.continueLocalIdentifier
            )
          ]

          state.surveyResponses.push(updateResponse)
        }
      }
      state.surveyData = undefined
      state.userJourney = []
      state.analytics = undefined
      state.lastQTime = undefined
    },
    addCompletedSurvey(state, action) {
      if (action.payload.save) {
        state.surveyResponses = [
          ...(state.surveyResponses || []),
          {
            id: action.payload.id,
            localIdentifier: uuidv4(),
            local: true,
            metadata: action.payload.metadata,
            responses: action.payload.responses,
            userJourney: action.payload.userJourney,
            analytics: action.payload.analytics,
            drinkingSessions: action.payload.drinkingSessions
          }
        ]
      }
      state.surveyMetaData = {
        ...state.surveyMetaData,
        startTime: undefined
      }
      state.surveyData = undefined
      state.userJourney = []
      state.analytics = undefined
      state.lastQTime = undefined
    },
    updateValidatedSLK(state, action) {
      const responses = state.surveyResponses?.filter(
        (x) => x.localIdentifier == action.payload.localIdentifier
      )
      if (responses && responses.length > 0) {
        const updateResponse = { ...responses[0] }
        updateResponse.metadata!.slk = action.payload.slk
        updateResponse.metadata!.status = updateResponse.metadata!
          .flaggedDeleted
          ? 'deletion'
          : updateResponse.metadata!.flaggedIncomplete
          ? 'incomplete'
          : 'complete'
        state.surveyResponses = [
          ...(state.surveyResponses || []).filter(
            (each) => each.localIdentifier != action.payload.localIdentifier
          )
        ]
        state.surveyResponses?.push(updateResponse)
      }
    },
    updateLastQTime(state, action) {
      state.lastQTime = action.payload
    },
    updateAnalytics(state, action) {
      if (state.analytics) {
        const existingStateIndex = state.analytics.findIndex(
          (item) => item.question === action.payload.question
        )

        // Check whether question already exists in analytics state
        if (existingStateIndex !== undefined && existingStateIndex > -1) {
          state.analytics[existingStateIndex] = action.payload
        } else {
          Array.isArray(action.payload)
            ? (state.analytics = [...action.payload])
            : state.analytics.push(action.payload)
        }
      } else {
        if (Array.isArray(action.payload)) {
          state.analytics = [...action.payload]
        } else {
          state.analytics = [action.payload]
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAPI.fulfilled, (state, action) => {
      state.offline = false
      if (action.payload.error) {
        if (action.payload.error.login) {
          state.loggedIn = action.payload.error.login
          state.loginAttempt =
            (state.loginAttempt != null ? state.loginAttempt : 0) + 1
        } else if (action.payload.error.data) {
          state.offline = true
          state.loggedIn = 'true'
          state.loginAttempt = undefined
          state.user = action.payload.userInfo
          if (action.payload.demoMode) {
            state.demoGeoLocations = action.payload.locations
            state.demoGrogShops = action.payload.grogshops
          } else {
            state.geoLocations = action.payload.locations
            state.grogShops = action.payload.grogshops
          }
        }
      } else {
        state.loggedIn = 'true'
        state.loginAttempt = undefined
        state.user = action.payload.userInfo
        if (action.payload.demoMode) {
          state.demoService = action.payload.data
          state.demoGeoLocations = action.payload.locations
          state.demoGrogShops = action.payload.grogshops
        } else {
          state.service = action.payload.data
          state.geoLocations = action.payload.locations
          state.grogShops = action.payload.grogshops
        }
      }
    })
    builder.addCase(loginAPI.rejected, (state) => {
      state.offline = true
    })
    builder.addCase(fetchSurveyAPI.fulfilled, (state, action) => {
      state.service = action.payload.data
    })
    builder.addCase(syncSurveysAPI.fulfilled, (state, action) => {
      if (!action.payload?.data) return
      const completedIds = new Set(
        action.payload.data.completedSubmissions.map(
          (item) => item.localIdentifier
        )
      )
      const incompletedIds = new Set(
        action.payload.data.toBeContinueSubmissions.map(
          (item) => item.localIdentifier
        )
      )

      const updatedLocalResponses = (state.surveyResponses || []).map(
        (each) => {
          if (completedIds.has(each.localIdentifier) && each.local) {
            const item = action.payload.data.completedSubmissions.find(
              (cItem) => cItem.localIdentifier === each.localIdentifier
            )
            return item ? { ...item, local: true } : each
          }

          if (incompletedIds.has(each.localIdentifier) && each.local) {
            const item = action.payload.data.toBeContinueSubmissions.find(
              (tbcItem) => tbcItem.localIdentifier === each.localIdentifier
            )
            return item ? { ...item, local: true } : each
          }
          return each
        }
      )

      const localIds = new Set(
        updatedLocalResponses.map((item) => item.localIdentifier)
      )

      state.surveyResponses = [
        ...updatedLocalResponses,
        ...action.payload.data.toBeContinueSubmissions.filter(
          (item) => !localIds.has(item.localIdentifier)
        )
      ]
      state.syncRounds = (state.syncRounds != null ? state.syncRounds : 0) + 1
    })
    builder.addCase(syncSurveysAPI.rejected, (state) => {
      state.syncRounds = (state.syncRounds != null ? state.syncRounds : 0) + 1
    })
  }
})

export const {
  updateUserJourney,
  updateService,
  updateUser,
  updateSurvey,
  updateDrinkingSession,
  logout,
  updateSurveyAnswer,
  updateSurveyMetadata,
  updateContinueLocalIdentifier,
  addCompletedSurvey,
  updateContinueSurveyResponse,
  updateMetadataByLocalIdentifier,
  updateValidatedSLK,
  updateLastQTime,
  updateAnalytics
} = slice.actions
export default slice.reducer

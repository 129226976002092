import { createTheme } from '@mui/material/styles'
import { breakpoints } from './theme'

/**
 * Material UI Theme
 *
 * @remarks
 * This gets injected into the Theme provider applied on the <App /> component.
 * The values entered here affect all Material UI components, namely the grid
 */
export const theme = createTheme({
  spacing: [0, 16, 24, 32, 34, 34, 3, 4, 5, 6, 7],
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl
    }
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.25, 0.1, 0.25, .1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
  }
})

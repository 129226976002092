import { Button } from 'components/Button/Button.component'
import { Modal } from 'components/Modal/Modal.component'
import { MainColor, SecondaryColor } from 'components/Screen/Screen.model'
import React, {
  Dispatch,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import {
  ModalComponent,
  ComponentType,
  ModalScreenPropsModel,
  FollowUpOptionValue,
  FollowUpOptionValues
} from './ModalScreen.model'
import styles from './ModalScreen.module.scss'
import { FollowUpQuestionModel } from 'api/client.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'

export const ModalScreen: React.FC<ModalScreenPropsModel> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const {
    screenId,
    theme,
    modalData,
    open,
    onModalScreenClose,
    shortcodes,
    playAudio,
    optionFollowUp,
    value
  } = props

  const { showAdditionalFollowUpQuestion } = matchCondition()

  const { additionalQuestions = [] } = modalData

  const questions = useMemo<
    FollowUpQuestionModel[]
  >((): FollowUpQuestionModel[] => {
    if (value) {
      const checkQuestions =
        additionalQuestions?.filter((q) =>
          showAdditionalFollowUpQuestion(value, q?.conditions)
        ) || []

      if (showAdditionalFollowUpQuestion(value, modalData.conditions)) {
        checkQuestions.unshift({
          ...modalData,
          id: 'main'
        })
      }

      return checkQuestions
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, value])

  // optionValues for main follow up and additional questions
  const [optionValues, setOptionValues] = useState<FollowUpOptionValues>()

  const [curQuestionId, setCurQuestionId] = useState<string>()

  // Track progress of additional questions
  const [progress, setProgress] = useState<string[]>([])

  const curQuestion =
    curQuestionId === 'main'
      ? modalData
      : additionalQuestions?.find((q) => q?.id === curQuestionId)

  const field = curQuestion?.surveyField

  useEffect(() => {
    const current = questions?.[0]?.id || 'main'
    setCurQuestionId(current)
    setProgress([current])
    setOptionValues(undefined)
  }, [questions])

  // Get next question id or return null
  const nextQuestion = useMemo<string | null>((): string | null => {
    // Check for next question in additional questions and has not been displayed yet
    return questions?.find((q) => !progress.includes(q?.id || ''))?.id || null
  }, [questions, progress])

  const Component: React.FC<any> = useMemo(
    () =>
      curQuestion?.data
        ? ModalComponent[Object.keys(curQuestion?.data)[0] as ComponentType]
        : ('div' as any),
    [curQuestion]
  )

  const getSurveyFieldValue = (key: string) => {
    if (!optionValues?.[key]) return undefined

    return typeof optionValues[key] === 'string'
      ? optionValues[key]
      : JSON.stringify(optionValues[key])
  }

  const handleModalClose = (cancel?: boolean) => {
    const mainField = modalData?.surveyField

    if (mainField) {
      const surveyAnswer = cancel ? undefined : getSurveyFieldValue('main')
      dispatch(updateSurveyAnswer({ [mainField]: surveyAnswer } as ISurveyData))
    }

    additionalQuestions?.forEach((question) => {
      const { id, surveyField } = question
      if (surveyField && id) {
        const surveyAnswer = cancel ? undefined : getSurveyFieldValue(id)
        dispatch(
          updateSurveyAnswer({ [surveyField]: surveyAnswer } as ISurveyData)
        )
      }
    })

    if (onModalScreenClose) {
      let valueCheck = true
      questions.forEach((question) => {
        if (!optionValues?.[question?.id || '']) {
          valueCheck = false
        }
      })

      setOptionValues(undefined)
      setProgress([])

      onModalScreenClose(!cancel && valueCheck)
    } else {
      setOptionValues(undefined)
      setProgress([])
    }
  }

  const handleNextQuestion = (next: string) => {
    if (next) {
      setProgress([...progress, next])
      setCurQuestionId(next)
    }
  }

  const handleSetValue = useCallback(
    (choices: FollowUpOptionValue) => {
      if (curQuestionId) {
        setOptionValues({
          ...optionValues,
          [curQuestionId]: choices
        })

        let value: FollowUpOptionValue = choices

        if (Array.isArray(choices) && typeof choices[0] === 'string') {
          value = JSON.stringify(choices)
        } else if (typeof choices === 'object') {
          value = (choices as any)[Object.keys(choices)[0]]
          setOptionValues({
            ...optionValues,
            [curQuestionId]: value
          })
        }

        if (field) {
          dispatch(
            updateSurveyAnswer({
              [field]: typeof value === 'string' ? value : JSON.stringify(value)
            } as ISurveyData)
          )
        }
      }
    },
    [dispatch, field, optionValues, curQuestionId]
  )

  useEffect(() => {
    // reset value when name changes
    if (curQuestionId) {
      setOptionValues({
        ...optionValues,
        [curQuestionId]: undefined
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curQuestionId])

  useEffect(() => {
    if (open && (screenId || optionFollowUp) && curQuestion?.voices) {
      let audioReference = ''

      // Use followUp option audio if availble on main screen, else use followUpQustion voices or additional question voices
      if (optionFollowUp) {
        audioReference = `${optionFollowUp}-option-followup`
      } else if (curQuestionId === 'main') {
        audioReference = `${screenId}-modal`
      } else if (curQuestionId) {
        audioReference = `${curQuestionId}-modal`
      }

      if (audioReference) playAudio?.(audioReference)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, curQuestionId])

  const renderModalButton = (): ReactNode | undefined => {
    return (
      <>
        <Button
          variation="primary"
          width="l"
          onClick={() => handleModalClose(true)}
        >
          Close
        </Button>
        <Button
          variation="primary"
          width="l"
          onClick={() =>
            nextQuestion
              ? handleNextQuestion(nextQuestion)
              : handleModalClose(false)
          }
        >
          Confirm
        </Button>
      </>
    )
  }

  return (
    <div className={styles.modalscreen}>
      <Modal
        open={open}
        customClose={handleModalClose}
        style={{
          backgroundColor: MainColor[theme]
        }}
        invalid={!!nextQuestion}
        fullWidth
        buttons={renderModalButton()}
      >
        {curQuestionId && curQuestion?.data && (
          <Component
            {...curQuestion.data[Object.keys(curQuestion.data)[0]]}
            title={shortcodesRender(
              shortcodes,
              curQuestion.data[Object.keys(curQuestion.data)[0]].title
            )}
            subtitle={shortcodesRender(
              shortcodes,
              curQuestion.data[Object.keys(curQuestion.data)[0]].subtitle
            )}
            key={`${Object.keys(curQuestion.data)[0]}${open}`} // clear value on modal open/close
            setValue={handleSetValue}
            color={SecondaryColor[theme]}
            defaultValue={optionValues?.[curQuestionId]}
            playAudio={playAudio}
          />
        )}
      </Modal>
    </div>
  )
}

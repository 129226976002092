const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0')
}

export const formatTime = (time: Date) => {
  const date = new Date(time)
  return (
    [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear()
    ].join('/') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  )
}

export const formatDate = (time: Date | undefined) => {
  if (time == undefined) return ''
  const date = new Date(time)
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear().toString().slice(2, 4)
  ].join('/')
}

import React, { useState } from 'react'
import { FormPropsModel } from './Form.model'
import styles from './Form.module.scss'
import classnames from 'classnames'
import { Alert } from 'components/Alert/Alert.component'
import { Input } from 'components/Input/Input.component'
import { genericObject } from 'screens/survey/type'
import { colors } from 'shared/theme/theme'

export const Form: React.FC<FormPropsModel> = (props) => {
  const { className, title, subtitle, options, setValue } = props

  const [values, setValues] = useState<genericObject | string>()
  const [errorText, setErrorText] = useState<string>()

  const handleInputValue = (
    value: string,
    error?: string,
    label?: string | null
  ) => {
    if (error) {
      setErrorText(error)
    } else {
      setErrorText(undefined)
    }

    let dispatchValue: any
    if (typeof values !== 'string' && label) {
      dispatchValue = Object.assign({}, values, {
        [label]: error ? undefined : value
      })
    } else {
      dispatchValue = error ? undefined : value
    }

    setValues(dispatchValue)

    const requiredOptions = options.filter((option) => option.required)

    const valid =
      dispatchValue &&
      typeof dispatchValue === 'object' &&
      requiredOptions.every(
        (option) => option.value && dispatchValue[option.value]
      )

    setValue(dispatchValue, !valid)
  }

  return (
    <div className={classnames(className, styles.form)}>
      {title && (
        <h1
          className={classnames('screen-titles--title')}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className={classnames('screen-titles--subtitle')}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={styles['form-options']}>
        <Alert
          className={classnames(styles['form-alert'], {
            [styles['form-alert--hidden']]: !errorText
          })}
          fullWidth={true}
          text={errorText || ''}
          style={{ backgroundColor: colors.crimson }}
        />
        {options.map((option, index) => (
          <Input
            className={classnames(styles['form-option'], {
              [styles['form-option--first']]: index === 0
            })}
            key={index}
            inputOption={option}
            setValue={(value, error) =>
              handleInputValue(value, error, option.value)
            }
            style={{ backgroundColor: colors.white }}
          />
        ))}
      </div>
    </div>
  )
}

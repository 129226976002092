import React, { Dispatch, useState } from 'react'
import { Select } from 'components/Select/Select.component'
import { ISurveyMetaData, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSurvey, updateSurveyMetadata } from 'store/reducer'
import styles from './ProjectSelectorScreen.module.scss'
import Box from '@mui/material/Box'
import { ProjectSelectorScreenComponentProps } from './ProjectSelectorScreen.model'
import { Card } from 'components/Card/Card.component'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'

const ProjectSelectorScreenComponent: React.FC<
  ProjectSelectorScreenComponentProps
> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const { generateProjectOptions, generateSiteOptions } = generateOptions()

  const { service, user, projects } = props

  const [projectId, setProjectId] = useState<string>()
  const [siteId, setSiteId] = useState<string>()

  const handleSurveyData = () => {
    const project = service?.projects.find((item) => item.id === projectId)

    if (!service || !projects || !project || projects.length === 0) {
      return
    }

    dispatch(updateSurvey(project.survey))

    // Update Metadata
    const metadata: ISurveyMetaData = {
      surveyId: project.survey?.id,
      serviceId: service.id,
      projectId,
      siteId,
      shopId:
        project.shopId != null && project.shopId !== 0
          ? project.shopId
          : undefined,
      raId: user?.id,
      flaggedIncomplete: false,
      status: 'incomplete'
    }
    dispatch(updateSurveyMetadata(metadata))
    navigate('/ra-dashboard/dashboard')
  }

  if (!service || !projects || projects.length === 0) {
    return <></>
  }

  return (
    <main className={styles.projectselectorscreen}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Project Selection
      </h1>
      <Card
        title="Hi!"
        buttonText="Continue"
        buttonDisabled={!(projectId && siteId)}
        onButtonClick={handleSurveyData}
      >
        <Box
          className={styles['projectselectorscreen-select']}
          component="form"
        >
          <Select
            fullWidth
            options={generateProjectOptions(projects)}
            placeholder="please select"
            setValue={(value) => setProjectId(value)}
            style={{
              marginRight: 20
            }}
            selectProps={{
              inputProps: {
                'aria-label': 'Select Project'
              }
            }}
          />
          <Select
            fullWidth
            options={projectId ? generateSiteOptions(projects, projectId) : []}
            placeholder="please select"
            setValue={(value) => setSiteId(value)}
            style={{
              marginRight: 20
            }}
            selectProps={{
              inputProps: {
                'aria-label': 'Select Site'
              }
            }}
          />
        </Box>
      </Card>
    </main>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  service: state.service,
  user: state.user,
  projects: state.service?.projects
})

export const ProjectSelectorScreen = connect(mapStateToProps)(
  ProjectSelectorScreenComponent
)

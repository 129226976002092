import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconEmail: React.FC<Partial<IconPropsModel>> = () => (
  <svg
    width="29"
    height="21"
    viewBox="0 0 29 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9375 17.2212L26.9375 17.221C26.9471 16.6813 26.9142 9.96608 26.8922 5.49378C26.8821 3.42606 26.8743 1.83776 26.8742 1.56089C26.4367 1.55999 26.0893 1.5542 25.7155 1.54798C24.4527 1.52695 22.8886 1.50091 16.5348 1.64126C16.5346 1.64126 16.5344 1.64127 16.5342 1.64127L11.2272 1.76419L11.2118 1.76455L11.1964 1.76452C8.12962 1.75994 6.31862 1.78836 5.11109 1.8177C4.67841 1.82821 4.31459 1.83917 3.99351 1.84885C3.43766 1.8656 3.00991 1.8785 2.57469 1.8785C2.41462 1.8785 2.29212 1.88743 2.20065 1.9003C2.20048 1.90642 2.20031 1.91271 2.20013 1.91917C2.19646 2.05461 2.19274 2.23724 2.18903 2.46226C2.18161 2.91167 2.17438 3.51884 2.16752 4.23252C2.15381 5.65924 2.14164 7.50325 2.13223 9.34395C2.12283 11.1845 2.1162 13.0201 2.11357 14.4298C2.11225 15.1348 2.11193 15.7323 2.11276 16.1701C2.11318 16.3892 2.11388 16.5668 2.11486 16.6973C2.11536 16.7627 2.11591 16.8144 2.1165 16.8528C2.11695 16.882 2.11734 16.8971 2.11748 16.9024C2.11754 16.9048 2.11755 16.9052 2.11749 16.9039C2.14237 17.4311 2.19628 18.146 2.24448 18.7328C2.28528 18.7382 2.33482 18.7431 2.39425 18.7465L26.9375 17.2212ZM26.9375 17.2212C26.9271 17.8089 26.9252 18.3639 26.9264 18.816V18.8163C26.9265 18.8381 26.9259 18.8581 26.9247 18.8763C26.912 18.8768 26.8979 18.8772 26.8824 18.8772C26.3969 18.8772 26.3645 18.878 26.1753 18.8831C25.8731 18.8912 25.1705 18.9101 21.5793 18.9534V18.9533M26.9375 17.2212L21.5793 18.9533M21.5793 18.9533L21.5669 18.9536M21.5793 18.9533L21.5669 18.9536M21.5669 18.9536L15.9948 19.0763M21.5669 18.9536L15.9948 19.0763M15.9948 19.0763C7.56774 19.1008 4.64934 18.8996 3.06138 18.7901C2.81151 18.7729 2.59458 18.758 2.39432 18.7465L15.9948 19.0763Z"
      stroke="white"
      strokeWidth="2.5"
    />
    <path
      d="M2.01172 2.25183C3.26486 2.78675 4.46725 3.44469 5.55645 4.23378C6.84044 5.16399 8.02641 6.31812 9.43731 7.06514C10.7038 7.7357 11.9373 8.66039 13.1195 9.47179C13.9001 10.0075 14.6803 10.7127 15.5795 10.9976C16.9804 11.4413 17.8737 10.388 18.9256 9.78638C19.413 9.50759 19.9688 8.9641 20.3694 8.5516C20.7602 8.14927 21.2798 7.89747 21.6758 7.48984C22.6321 6.50528 23.8165 5.78929 24.7774 4.80005C25.4042 4.15483 26.2528 3.08109 27.0387 2.67653"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconThreePieThird: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="313"
    height="193"
    viewBox="0 0 313 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4443 96.5997C19.8639 97.5478 7.88088 106.476 2.08691 110.821C7.6177 119.644 20.8915 139.344 29.7406 147.561C38.5897 155.778 52.1267 164.68 57.7891 168.104C68.3237 173.371 91.7633 184.617 101.244 187.461C113.096 191.016 138.774 191.016 148.65 190.621C158.526 190.226 163.267 190.226 185.39 187.461C207.167 184.739 230.476 172.446 251.924 161.135L252.943 160.598C274.671 149.141 287.707 140.055 297.189 130.574C304.773 122.989 309.04 118.459 310.225 117.142L293.633 105.686L267.56 85.9334L224.895 54.3295L198.821 33.787L166.032 12.8494L152.996 2.57812L143.515 8.50384L105.985 33.787L85.0474 49.5889L51.0733 76.8473C41.8555 83.0364 23.0247 95.6516 21.4443 96.5997Z"
      fill={props.fill || '#8C625E'}
      fillOpacity="0.3"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
)

import { FollowUpQuestionModel } from 'api/client.model'
import { PieChart } from 'components/PieChart/PieChart.component'
import { Screen } from 'components/Screen/Screen.component'
import { SecondaryColor } from 'components/Screen/Screen.model'
import React, { useState } from 'react'
import { ModalScreen } from 'screens/survey/ModalScreen/ModalScreen'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { EffectsPieChartScreenPropsModel } from './EffectsPieChartScreen.model'
import styles from './EffectsPieChartScreen.module.scss'

export const EffectsPieChartScreen: React.FC<
  EffectsPieChartScreenPropsModel
> = (props) => {
  const {
    currentScreenId,
    theme,
    headerProps,
    screenData: { title, subtitle, options },
    followUpQuestion,
    footerProps,
    shortcodes
  } = props

  const { showFollowUpQuestion, getNextScreenId } = matchCondition()

  const [selectedValue, setSelectedValue] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showSectionModal, setShowSectionModal] = useState<boolean>(false)
  const [followUpData, setFollowUpData] = useState<FollowUpQuestionModel>()
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (!selectedValue) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(selectedValue, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        selectedValue,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    }
  }

  const handleSetValue = (value: string) => {
    setSelectedValue(value)
    const selectedOption = options.find((option) => option.value === value)

    const followUpInfo = selectedOption?.followUp

    if (followUpInfo) {
      setShowSectionModal(true)
      setFollowUpData({
        id: selectedOption?.id,
        isActive: true,
        data: followUpInfo
      } as FollowUpQuestionModel)
    }
  }

  return (
    <div className={`drug-app-screen ${styles.effectspiechartscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['effectspiechartscreen-titles']}>
          {title && (
            <h1
              className="screen-titles--title"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, title) || title
              }}
            />
          )}
          {subtitle && (
            <p
              className="screen-titles--subtitle"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, subtitle) || subtitle
              }}
            />
          )}
        </div>
        <div className={styles['effectspiechartscreen-chart']}>
          <PieChart
            options={options}
            color={SecondaryColor[theme]}
            setValue={handleSetValue}
            playAudio={footerProps?.playAudio}
          />
          {followUpData && (
            <ModalScreen
              screenId={followUpData?.id}
              theme={theme}
              modalData={followUpData}
              open={showSectionModal}
              onModalScreenClose={() => setShowSectionModal(false)}
              shortcodes={shortcodes}
              playAudio={footerProps?.playAudio}
              optionFollowUp={followUpData?.id}
            />
          )}
        </div>
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              theme={theme}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              playAudio={footerProps?.playAudio}
            />
          )}
      </Screen>
    </div>
  )
}

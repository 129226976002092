import { Screen } from 'components/Screen/Screen.component'
import React, { Dispatch, useEffect, useState } from 'react'
import { FeedbackScreenPropsModel } from './FeedbackScreen.model'
import styles from './FeedbackScreen.module.scss'
import classnames from 'classnames'
import { SecondaryColor } from 'components/Screen/Screen.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { Feedback } from 'components/Feedback/Feedback.component'
import { ModalScreen } from '../ModalScreen/ModalScreen'
import { useDispatch } from 'react-redux'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'

export const FeedbackScreen: React.FC<FeedbackScreenPropsModel> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId, showFollowUpQuestion } = matchCondition()
  const {
    currentScreenId,
    name,
    theme,
    headerProps,
    footerProps,
    followUpQuestion,
    shortcodes,
    screenData: { title, subtitle, options }
  } = props

  const [feedbackValue, setFeedbackValue] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleSetValue = (value: string) => {
    dispatch(
      updateSurveyAnswer({
        [name]: value
      } as ISurveyData)
    )

    setFeedbackValue(value)

    // Remove follow up data if main screen data changed and doesn't match any conditions
    if (
      followUpQuestion &&
      followUpQuestion.isActive &&
      !showFollowUpQuestion(value, followUpQuestion) &&
      followUpQuestion.surveyField
    ) {
      dispatch(
        updateSurveyAnswer({
          [followUpQuestion.surveyField]: undefined
        } as ISurveyData)
      )

      followUpQuestion?.additionalQuestions?.forEach((question) => {
        const { surveyField } = question
        if (surveyField) {
          dispatch(
            updateSurveyAnswer({
              [surveyField]: undefined
            } as ISurveyData)
          )
        }
      })

      setIsFollowUpValueSet(false)
    }
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (!feedbackValue) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(feedbackValue, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        feedbackValue,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    }
  }

  // Clear Redux
  useEffect(() => {
    // Reset value when name changes
    setFeedbackValue(undefined)

    const reset: ISurveyData = {
      [name]: undefined
    }

    if (followUpQuestion && followUpQuestion.surveyField) {
      reset[followUpQuestion.surveyField] = undefined
    }

    dispatch(updateSurveyAnswer(reset))

    followUpQuestion?.additionalQuestions?.forEach((question) => {
      const { surveyField } = question
      if (surveyField) {
        dispatch(
          updateSurveyAnswer({
            [surveyField]: undefined
          } as ISurveyData)
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div className={classnames('drug-app-screen', styles.feedbackscreen)}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['feedbackscreen-wrapper']}>
          {title && (
            <h1
              className="screen-titles--title"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, title) || title
              }}
            />
          )}
          {subtitle && (
            <p
              className="screen-titles--subtitle"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, subtitle) || subtitle
              }}
            />
          )}
          <Feedback
            name="feedback"
            className={styles['feedbackscreen-feedbackIcons']}
            options={options}
            color={SecondaryColor[theme]}
            setValue={handleSetValue}
          />
        </div>
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              theme={theme}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              playAudio={footerProps?.playAudio}
              value={feedbackValue}
            />
          )}
      </Screen>
    </div>
  )
}

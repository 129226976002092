import classnames from 'classnames'
import { Form } from 'components/Form/Form.component'
import { Screen } from 'components/Screen/Screen.component'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import { genericObject } from '../type'
import { InputScreenPropsModel } from './InputScreen.model'
import styles from './InputScreen.module.scss'

export const InputScreen: React.FC<InputScreenPropsModel> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const {
    currentScreenId,
    name,
    theme,
    headerProps,
    screenData: { title, subtitle, options },
    footerProps,
    shortcodes
  } = props

  const { getNextScreenId } = matchCondition()

  const [checkValue, setCheckValue] = useState<genericObject | string>()
  const [invalid, setInvalid] = useState<boolean>(true)

  const handleInputValue = (
    value?: genericObject | string,
    isInvalid?: boolean | string
  ) => {
    dispatch(
      updateSurveyAnswer({
        [name]: value
      } as ISurveyData)
    )
    setCheckValue(value)
    isInvalid ? setInvalid(true) : setInvalid(false)
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (invalid) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    updatedFooterProps.nextScreenId = getNextScreenId(
      checkValue,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    return updatedFooterProps
  }

  return (
    <div className={classnames('drug-app-screen', styles.inputscreen)}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <Form
          title={shortcodesRender(shortcodes, title)}
          subtitle={shortcodesRender(shortcodes, subtitle)}
          options={options}
          setValue={handleInputValue}
        />
      </Screen>
    </div>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconPause: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7755 2.68506C16.4632 2.68506 16.1571 2.69938 15.8547 2.72712C11.9064 3.10029 7.47048 4.37192 4.51467 10.978C4.44219 11.1221 0.757044 18.3742 4.09944 24.2635C4.10302 24.2697 4.1066 24.2769 4.11018 24.2831C6.66329 28.9634 9.92694 31.7098 13.8099 32.4445C20.0669 33.6275 25.8711 29.35 26.2622 29.0502C26.3239 28.992 26.392 28.9392 26.4653 28.8927C34.3054 23.9323 33.1645 17.0507 33.1126 16.7598C33.0964 16.6587 32.4656 12.4733 29.1437 8.82756C25.6984 5.06815 20.8446 2.68506 16.7755 2.68506ZM15.9755 35.3404C11.272 35.3413 5.91875 33.1998 1.75932 25.5789C-2.30347 18.4055 1.91771 10.1565 2.10027 9.80746C5.64402 1.88056 11.5046 0.440688 15.6023 0.0549916C20.7175 -0.428247 26.8072 2.30474 31.125 7.01631C35.033 11.3055 35.7346 16.1289 35.7623 16.3321C35.8196 16.6238 37.3221 25.1243 28.0144 31.0888C27.6323 31.4315 26.9656 31.878 25.8022 32.5349C24.5691 33.2311 22.6137 34.1842 20.3005 34.7739C18.9707 35.1131 17.5066 35.3404 15.9755 35.3404Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4772 25.016C14.4396 25.016 14.402 25.0142 14.3635 25.0115C13.6253 24.9498 13.0767 24.301 13.1375 23.5618C13.1402 23.5305 13.3989 20.3921 13.3129 18.1764C13.2235 16.1039 13.3085 12.4312 13.312 12.2773C13.3299 11.5462 13.9277 10.9663 14.6535 10.9663H14.6857C15.4276 10.9842 16.0146 11.599 15.9967 12.34C15.9958 12.3775 15.9099 16.0788 15.9958 18.0672C16.088 20.4539 15.8249 23.6513 15.8133 23.7856C15.7551 24.4863 15.1671 25.016 14.4772 25.016Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9187 24.3896C20.1778 24.3896 19.5764 23.7883 19.5764 23.0473C19.5764 22.9238 19.5782 20.0101 19.7608 18.0037C19.9308 16.0636 19.759 12.4089 19.7572 12.3722C19.7214 11.6321 20.2941 11.003 21.0342 10.9681C21.7895 10.9198 22.4034 11.5051 22.4383 12.2451C22.4463 12.4017 22.6199 16.1182 22.4347 18.2418C22.2629 20.1335 22.2611 23.0187 22.2611 23.0473C22.2611 23.7883 21.6597 24.3896 20.9187 24.3896Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconThreePieFirst: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="185"
    height="299"
    viewBox="0 0 185 299"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.432 283.047L26.5882 296.17L14.9225 272.84C12.0062 261.499 5.59044 234.151 3.25775 215.487C0.341893 192.157 1.79962 180.492 3.25775 165.424C4.71589 150.357 19.7833 111.473 26.5882 99.3224C33.3932 87.1713 54.7785 64.3272 73.2481 45.3715C91.7178 26.4157 130.601 11.3484 148.585 6.00186C162.972 1.72467 176.614 1.62746 181.636 2.11351V19.1251V68.7016C180.826 77.4504 179.692 98.2531 181.636 111.473C183.58 124.694 182.446 129.943 181.636 130.915V165.424V188.755L153.931 204.794L118.45 231.04L83.4551 259.717L49.432 283.047Z"
      fill={props.fill || '#8C625E'}
      fillOpacity="0.3"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
)

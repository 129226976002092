import React from 'react'
import styles from './GrogDiary.module.scss'
import classnames from 'classnames'
import { GrogDiaryPropsModel } from './GrogDiary.model'
import { Box, Grid } from '@mui/material'
import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'
import { GrogDiaryDataDetail } from 'store/type'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Product } from 'components/GrogConsumptions/Product/Product.component'
import { Button } from 'components/Button/Button.component'
import { colors } from 'shared/theme/theme'
import { MainColor, SecondaryColor } from 'components/Screen/Screen.model'
import { Carousel } from 'components/Carousel/Carousel.component'
import { v4 as uuidv4 } from 'uuid'

export const GrogDiary: React.FC<GrogDiaryPropsModel> = (props) => {
  const {
    title,
    date,
    consumptions,
    theme,
    outro = false,
    onDiaryIncrease,
    onDiaryDecrease,
    onYesClick,
    onNoClick
  } = props

  // filter the item whose drinkAmount is 0
  const updatedConsumptionList = consumptions
    ?.filter((item) => Boolean(item?.drinkAmount))
    ?.reduce((acc, cv) => {
      const key = cv.typecategory || cv.subtype
      if (!key) {
        return acc
      }
      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(cv)

      return acc
    }, {} as { [key: string]: GrogDiaryDataDetail[] })

  const renderDrink = (item: GrogDiaryDataDetail) => {
    const isContainer = item.container ? true : false

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      initialSlide: 0,
      slidesToShow: 10,
      swipeToSlide: true,
      slidesToScroll: 1,
      arrows: false,
      className: classnames(
        styles['carousel__slides'],
        styles['grogdiary__list-wrapper']
      )
    }

    if (isContainer) {
      return (
        <Carousel {...settings}>
          {new Array(item.drinkAmount).fill(0).map((_v, index) => (
            <div
              key={index}
              className={classnames(styles['grogdiary__list-item-container'])}
            >
              <Container
                image={item.containerImage}
                fillMaskImage={item.containerFillMaskImage}
                topBorder={item.containerTopBorder}
                bottomBorder={item.containerBottomBorder}
                min={0}
                max={100}
                maxHeight={100}
                drinkValue={item.containerGrog}
                fizzyDrinkValue={
                  (item.containerGrog != null ? item.containerGrog : 0) +
                  (item.containerFizzy != null ? item.containerFizzy : 0)
                }
                drinkColor={item.containerColor}
              />
            </div>
          ))}
        </Carousel>
      )
    } else {
      const isPack = item.subContainer === 1 ? false : true
      const drinkAmount = Number(item.drinkAmount)
      const step = Number(
        isPack ? item.subContainer : item.stepsPerSubContainer
      )
      const max = Math.ceil(drinkAmount / Number(step))

      return (
        <Carousel {...settings}>
          {new Array(max).fill(0).map((_v, index) => {
            const clipPercentage =
              100 -
              (drinkAmount - (index + 1) * step > 0
                ? 1
                : (drinkAmount - index * step) / step) *
                100
            return (
              <div
                key={index}
                className={classnames(styles['grogdiary__list-item-container'])}
              >
                <Product
                  isPack={isPack}
                  clipPercentage={clipPercentage}
                  image={item.productImage || ''}
                />
              </div>
            )
          })}
        </Carousel>
      )
    }
  }

  return (
    <Box className={classnames(styles.grogdiary)}>
      <h2 className={classnames(styles['grogdiary__title'])}>{title}</h2>

      <h3 className={classnames(styles['grogdiary__date'])}>
        <Icon
          className={classnames(styles['grogdiary__icon'])}
          icon={IconType.Calendar}
          size={36}
          style={{ marginRight: '5px' }}
          stroke={SecondaryColor[theme]}
          fill={MainColor[theme]}
        />
        {new Date(date).toLocaleString('en-AU', {
          month: 'long',
          day: 'numeric'
        })}
      </h3>

      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <div
          className={classnames(styles['grogdiary__container'])}
          style={{
            maxHeight: window.outerHeight < 800 && outro ? 285 : undefined
          }}
        >
          {updatedConsumptionList &&
            Object.keys(updatedConsumptionList).map((key) => (
              <div
                key={`${key}_${uuidv4()}`}
                className={classnames(styles['grogdiary__list'])}
              >
                <h4 className={classnames(styles['grogdiary__type-title'])}>
                  {key}
                </h4>
                <div style={{ height: '100%', overflowY: 'auto' }}>
                  {updatedConsumptionList[key].map(
                    (item: GrogDiaryDataDetail, index: number) => (
                      <div
                        key={`${key}_${item.uuid}`}
                        className={classnames(styles['grogdiary__list-item'])}
                      >
                        <div
                          className={classnames(
                            styles['grogdiary__list-item-info']
                          )}
                        >
                          <h4>{item.brand || item.subtype}</h4>
                          <h5>{item.container || item.product}</h5>
                        </div>
                        <div
                          className={classnames(
                            styles['grogdiary__list-item-wrapper']
                          )}
                        >
                          {outro !== true ? (
                            <Button
                              aria-label="Decrease"
                              onClick={() => {
                                onDiaryDecrease(item.uuid)
                              }}
                              noBorder={true}
                            >
                              <Icon
                                className={classnames(
                                  styles['grogdiary__decrease-icon']
                                )}
                                icon={IconType.Decrease}
                                size={36}
                              />
                            </Button>
                          ) : null}

                          <div
                            className={classnames(
                              styles['grogdiary__list-item-drink']
                            )}
                          >
                            {renderDrink(item)}
                          </div>
                          {outro !== true ? (
                            <Button
                              aria-label="Increase"
                              onClick={() => {
                                onDiaryIncrease(item.uuid)
                              }}
                              noBorder={true}
                            >
                              <Icon icon={IconType.Increase} size={36} />
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
        </div>
      </Box>

      {outro !== true ? (
        <Grid
          container={true}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          rowSpacing={0}
          className={classnames(
            styles['grogdiary__action'],
            styles[`grogdiary__action-background-${theme}`]
          )}
        >
          <Grid item={true} xs={4}>
            <Button
              variation="secondary"
              width="full"
              dropShadow={true}
              style={{
                backgroundColor: colors.white
              }}
              onClick={onNoClick}
            >
              No, I had other grog too
            </Button>
          </Grid>
          <Grid item={true} xs={4}>
            <Button
              variation="secondary"
              width="full"
              dropShadow={true}
              disabled={Object.keys(updatedConsumptionList).length === 0}
              style={{
                backgroundColor: colors.white
              }}
              onClick={onYesClick}
            >
              Yes, this is all I had to drink
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  )
}

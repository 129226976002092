import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconTick: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1785 95.0726C18.4786 125.743 54.7176 136.966 54.7176 136.966C84.1588 147.818 103.031 139.584 118.503 126.869C133.982 114.151 143.037 89.4614 141.905 64.7685C140.776 42.3238 129.075 26.6125 129.075 26.6125C129.075 26.6125 109.822 -1.4434 67.1735 7.90856C66.7961 7.90856 -12.8498 18.3865 10.1785 95.0726Z"
      fill={props.fill || '#222222'}
      stroke={(props.border ? props.stroke : props.fill) || '#222222'}
      strokeWidth="12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1797 74.526C51.7385 76.4542 55.4174 82.4267 59.4916 84.9959C63.2463 87.3245 67.731 86.697 70.7739 83.9815C74.0608 80.949 78.3794 72.769 81.1862 68.373C86.0052 59.9839 91.122 48.6611 91.122 48.6611"
      fill={props.fill || '#222222'}
    />
    <path
      d="M54.0034 80.9339C56.7222 82.9826 60.631 89.3283 64.9598 92.0582C68.9492 94.5322 73.7142 93.8656 76.9473 90.9804C80.4396 87.7583 85.0281 79.0671 88.0103 74.3963C93.1306 65.4829 98.5671 53.4525 98.5671 53.4525"
      stroke={props.stroke || 'white'}
      strokeWidth="12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconProgress: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0783 18.4666C10.8193 18.4666 11.4207 19.068 11.4207 19.8089C11.4207 19.9065 11.4189 22.2323 11.2327 23.8422C11.069 25.3232 11.2372 28.1627 11.239 28.1904C11.2837 28.9305 10.72 29.5668 9.98078 29.6115C9.22102 29.6634 8.60445 29.0934 8.5597 28.3533C8.55165 28.2271 8.37536 25.257 8.56507 23.5406C8.73421 22.0757 8.736 19.8313 8.736 19.8089C8.736 19.068 9.33736 18.4666 10.0783 18.4666Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7658 18.4666C26.5068 18.4666 27.1082 19.068 27.1082 19.8089C27.1082 19.9065 27.1064 22.2323 26.9202 23.8422C26.7565 25.3232 26.9247 28.1627 26.9265 28.1904C26.9712 28.9305 26.4075 29.5668 25.6683 29.6115C24.9085 29.6634 24.2919 29.0934 24.2472 28.3533C24.2391 28.2271 24.0629 25.257 24.2526 23.5406C24.4217 22.0757 24.4235 19.8313 24.4235 19.8089C24.4235 19.068 25.0249 18.4666 25.7658 18.4666Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9309 9.05793C15.6719 9.05793 16.2732 9.65929 16.2732 10.4003C16.2732 10.4978 16.3688 22.2323 16.1827 23.8422C16.0189 25.3232 16.1872 28.1627 16.189 28.1904C16.2337 28.9305 15.6699 29.5668 14.9308 29.6115C14.171 29.6634 13.5544 29.0934 13.5097 28.3533C13.5016 28.2271 13.3253 25.257 13.515 23.5406C13.6842 22.0757 13.5886 10.4226 13.5886 10.4003C13.5886 9.65929 14.1899 9.05793 14.9309 9.05793Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.791 13.2699C21.532 13.2699 22.1333 13.8713 22.1333 14.6123C22.1333 14.7098 22.2291 22.2322 22.043 23.8421C21.8792 25.3231 22.0474 28.1626 22.0492 28.1903C22.094 28.9304 21.5302 29.5667 20.791 29.6114C20.0312 29.6633 19.4147 29.0933 19.3699 28.3532C19.3619 28.227 19.1856 25.2569 19.3753 23.5405C19.5444 22.0756 19.4487 14.6346 19.4487 14.6123C19.4487 13.8713 20.05 13.2699 20.791 13.2699Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86887 0.149792C3.79136 0.149792 4.54005 0.898483 4.54005 1.82098C4.54005 1.94242 4.65606 26.0058 4.42433 28.0101C4.22044 29.854 4.4299 33.3891 4.43212 33.4236C4.48783 34.345 3.78593 35.1372 2.86567 35.1929C1.91978 35.2575 1.15215 34.5478 1.09644 33.6264C1.08642 33.4693 0.866935 29.7716 1.10313 27.6347C1.3137 25.8109 1.19769 1.84883 1.19769 1.82098C1.19769 0.898483 1.94638 0.149791 2.86887 0.149792Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9302 33.7899C36.9302 34.7124 36.1815 35.4611 35.259 35.4611C35.1376 35.4611 11.0742 35.5771 9.06989 35.3454C7.22601 35.1415 3.69091 35.351 3.65637 35.3532C2.73499 35.4089 1.94285 34.707 1.88714 33.7867C1.82252 32.8409 2.53222 32.0732 3.4536 32.0175C3.61069 32.0075 7.30846 31.788 9.44534 32.0242C11.2692 32.2348 35.2312 32.1188 35.259 32.1188C36.1815 32.1188 36.9302 32.8675 36.9302 33.7899Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

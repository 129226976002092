import { TimeDiffFormat } from './calculateTimeDiff.model'

export const calculateTimeDiff = (
  startTime: Date,
  endTime: Date,
  duration?: number,
  format?: boolean,
  float?: boolean
): TimeDiffFormat | number => {
  // Convert into Date format
  let startTimeFormatted = startTime
  let endTimeFormatted = endTime
  if (typeof startTime === 'string') {
    startTimeFormatted = new Date(startTime)
  }
  if (typeof endTime === 'string') {
    endTimeFormatted = new Date(endTime)
  }

  let startTimestamp = Math.floor(startTimeFormatted.getTime() / 1000)
  let endTimestamp = Math.floor(endTimeFormatted.getTime() / 1000)
  let totalSeconds =
    endTimestamp - startTimestamp + (duration != null ? duration : 0)

  if (float) {
    startTimestamp = startTimeFormatted.getTime()
    endTimestamp = endTimeFormatted.getTime()

    totalSeconds =
      (endTimestamp - startTimestamp) / 1000 + (duration != null ? duration : 0)
  }

  if (format) {
    const hour = Math.floor(totalSeconds / 3600)
    totalSeconds %= 3600
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    return { hour, minutes, seconds }
  }

  return totalSeconds
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { surveyAPI } from 'api/client'
import {
  LoginAPIResponseDataModel,
  UseAPIHookLoginDataModel
} from 'api/client.model'
import { DEMO_MODE_SERVICE_ID } from 'shared/constants/Constants.d'
import { parseJwt } from 'shared/utils/decodeJWT/decodeJWT'
import { SubmissionDataModel } from './type'

const {
  login,
  fetchSurveyData,
  fetchLocationData,
  fetchGrogShopData,
  handleSurveySubmit,
  handleSLKValidation
} = surveyAPI()

const extractUrls = (
  prefetchedURLs: { imageURLs: string[]; audioURLs: string[] },
  data: any
) => {
  const { imageURLs, audioURLs } = prefetchedURLs
  JSON.stringify(data, (_key, val) => {
    if (typeof val === 'string') {
      if (isImage(val) && imageURLs.indexOf(val) < 0) {
        imageURLs.push(val)
      } else if (val.endsWith('.mp3') && audioURLs.indexOf(val) < 0) {
        audioURLs.push(val)
      }
    }
    return val
  })
  return { imageURLs, audioURLs }
}

const isImage = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

export const loginAPI = createAsyncThunk(
  'ra/login',
  async (loginDetails: UseAPIHookLoginDataModel, { rejectWithValue }) => {
    try {
      let decodeData: LoginAPIResponseDataModel | undefined = undefined
      if (!loginDetails.demoMode) {
        const res = await login(loginDetails)

        if (res.success === false) {
          return { error: { login: res.message } }
        }

        if (res.data) {
          decodeData = parseJwt(res.data)
        }
      } else {
        decodeData = {
          id: 'demo',
          serviceId: DEMO_MODE_SERVICE_ID,
          username: loginDetails.Username,
          email: 'demo@demo.com'
        }
      }

      if (decodeData) {
        const [response, locations, grogshop] = await Promise.all([
          fetchSurveyData(decodeData.serviceId),
          fetchLocationData(),
          fetchGrogShopData(decodeData.serviceId)
        ])

        if (response.success === false) {
          return {
            userInfo: decodeData,
            demoMode: loginDetails.demoMode,
            error: { data: response.message }
          }
        }

        if (response.data) {
          // Preload images & audios
          let prefetchedURLs = {
            imageURLs: [] as string[],
            audioURLs: [] as string[]
          }
          prefetchedURLs = extractUrls(prefetchedURLs, response.data)
          prefetchedURLs = extractUrls(prefetchedURLs, grogshop.data)

          if (
            navigator &&
            navigator.serviceWorker &&
            navigator.serviceWorker.controller
          ) {
            if (prefetchedURLs.imageURLs.length > 0) {
              navigator.serviceWorker.controller.postMessage({
                type: 'PRELOAD_IMAGES',
                prefetchedURLs: prefetchedURLs.imageURLs
              })
            }

            if (prefetchedURLs.audioURLs.length > 0) {
              navigator.serviceWorker.controller.postMessage({
                type: 'PRELOAD_AUDIO',
                prefetchedURLs: prefetchedURLs.audioURLs
              })
            }
          }

          return {
            userInfo: decodeData,
            demoMode: loginDetails.demoMode,
            data: response.data,
            locations: locations.data || undefined,
            grogshops: grogshop.success ? grogshop.data : undefined
          }
        }

        return {
          userInfo: decodeData,
          demoMode: loginDetails.demoMode,
          error: { data: 'Service API Failed' }
        }
      }

      return { error: { login: 'Authenticate API Failed' } }
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Authenticate API Failed: ${err}`)
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const fetchSurveyAPI = createAsyncThunk(
  'ra/fetchSurveyData',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetchSurveyData(id)
      if (response.data) {
        return { data: response.data }
      }
      return { error: 'Service API Failed' }
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Service API Failed: ${err}`)
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const syncSurveysAPI = createAsyncThunk(
  'ra/syncSurveys',
  async (data: SubmissionDataModel, { rejectWithValue }) => {
    const { id, surveyResponses } = data

    if (!id) {
      return rejectWithValue('Survey Submit API Failed')
    }

    const validResponses = surveyResponses
      ? surveyResponses.filter((res) => res.metadata!.status !== 'synced')
      : []

    try {
      const response = await handleSurveySubmit(id, validResponses)
      if (response.success && response.result) {
        const completed = response.result.completedSubmissions
        const incompleted = response.result.toBeContinueSubmissions

        return {
          data: {
            completedSubmissions: completed,
            toBeContinueSubmissions: incompleted
          }
        }
      }
      return rejectWithValue('Survey Submit API Failed')
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Survey Submit API Failed: ${err}`)
      }
      return rejectWithValue(err)
    }
  }
)

export const validateSLK = createAsyncThunk(
  'ra/validateSLK',
  async (data: string | undefined, { rejectWithValue }) => {
    if (!data) {
      return rejectWithValue('Validate SLK API Failed')
    }

    try {
      return await handleSLKValidation(data)
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Survey Submit API Failed: ${err}`)
      }
      return rejectWithValue(err)
    }
  }
)

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const CircleBorder1: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="132"
    height="132"
    viewBox="0 0 132 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84969 85.833C13.6616 114.7 47.7689 125.262 47.7689 125.262C75.4782 135.475 93.2406 127.726 107.802 115.759C122.371 103.789 130.893 80.5519 129.828 57.3115C128.765 36.1871 117.753 21.4 117.753 21.4C117.753 21.4 99.6315 -5.00555 59.4921 3.7963C59.1368 3.7963 -15.824 13.6579 5.84969 85.833Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

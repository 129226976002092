import React, { useEffect, useState } from 'react'
import { FeedbackIcon, FeedbackPropsModel } from './Feedback.model'
import styles from './Feedback.module.scss'
import classnames from 'classnames'
import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'

export const Feedback: React.FC<FeedbackPropsModel> = (props) => {
  const { className, name, options, color, setValue } = props

  const [clickedIndex, setClickedIndex] = useState<number>()

  const handleIconClick = (index: number, value: string) => {
    setClickedIndex(index)
    setValue(value)
  }

  useEffect(() => {
    setClickedIndex(undefined)
  }, [options])

  return (
    <div className={classnames(className, styles.feedback)}>
      {options.map((option, index) => (
        <button
          className={classnames(styles['feedback-icon'], {
            [styles['feedback-icon--circle']]: true,
            [styles['feedback-icon--color']]: clickedIndex === index,
            [styles['feedback-icon--scale']]: name === 'feedback'
          })}
          name={name}
          type="button"
          role="radio"
          aria-label={option.value + ' - ' + option.icon}
          aria-checked={clickedIndex === index ? 'true' : 'false'}
          key={index}
          onClick={() => handleIconClick(index, option.value)}
        >
          <Icon
            icon={FeedbackIcon[option.icon] as any as IconType}
            fill={color}
            role="presentation"
          />
        </button>
      ))}
    </div>
  )
}

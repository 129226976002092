import React, { useState } from 'react'
import { DynamicSliderPropsModel } from './DynamicSlider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './DynamicSlider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icon/Icon.component'
import { colors } from 'shared/theme/theme'
import { DEFAULT_EMPTY_IMAGE_PATH } from 'shared/constants/Constants.d'
import classnames from 'classnames'
import { IconType } from 'components/Icon/Icon.model'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'

export const DynamicSlider: React.FC<DynamicSliderPropsModel> = (props) => {
  const {
    className,
    color = colors.black,
    maskColor,
    title,
    subtitle,
    image,
    labelPrefix,
    labelSuffix,
    icon = IconType.Hand,
    min = 0,
    max = 100,
    step = 1,
    defaultValue,
    dynamicImage,
    setValue,
    minLabel,
    maxLabel,
    customLabels,
    optionalButtons,
    playAudio,
    outro = false,
    ...componentProps
  } = props

  const [resetOptionalButtons, setResetOptionalButtons] =
    useState<boolean>(false)

  const IconSlider = styled(MuiSlider)({
    color: color,
    height: 10,
    '& .MuiSlider-valueLabel': {
      backgroundColor: color
    }
  })

  /**
   * Generate slider thumb component
   * @param thumbProps Html attributes for slider thumb
   * @returns {JSX.Element} Slider thumb component
   */
  const SliderThumbComponent = (
    thumbProps: React.HTMLAttributes<HTMLElement>
  ) => {
    const { children, ...other } = thumbProps
    return (
      <SliderThumb {...other}>
        {children}
        {icon && (
          <Icon icon={icon} size={47} stroke={colors.white} fill={color} />
        )}
      </SliderThumb>
    )
  }

  /**
   * Format prefix or suffix to number
   * @param value Input string for format
   * @returns {string} Formatted string with prefix and suffix
   */
  const handleLabelFormat = (value: number) => {
    let displayValue = value.toString()
    // Check Custom Label
    if (customLabels && customLabels.length > 0) {
      const customLabel = customLabels.find((item) => item.value === value)
      if (customLabel) return customLabel.label
    }

    // Add Prefix & Suffix
    if (labelPrefix) {
      displayValue = labelPrefix + ' ' + displayValue
    }
    if (labelSuffix) {
      displayValue = displayValue + ' ' + labelSuffix
    }
    return displayValue
  }

  const customImage = customLabels?.find(
    (item) => item.value == defaultValue
  )?.image

  /**
   * Generate static image
   * @returns {(JSX.Element | null)} image or placeholder
   */
  const generateImage = () => {
    if (!image) return null

    if (image == DEFAULT_EMPTY_IMAGE_PATH) {
      return <div className="image-space-holder">Picture / illustration</div>
    }

    return (
      <img
        className={styles.image}
        src={customImage || image}
        style={{
          maxHeight: optionalButtons && optionalButtons?.length > 0 ? 200 : 300
        }}
        role="presentation"
        alt=""
      />
    )
  }

  const handleDefaultValue = () => {
    if (!defaultValue) {
      return min
    }

    if (isNaN(defaultValue as number)) {
      return min
    }

    return Number(defaultValue)
  }

  const handleLastRowNum = (num: number, perRow: number) => {
    const rest = num % perRow
    return rest === 0 ? perRow : rest
  }

  const handleTotalRows = () => {
    const totalNum = Math.round(handleDefaultValue())

    switch (true) {
      case totalNum >= 100:
        return [25, 25, 25, 25]
      case totalNum > 75:
        return [25, 25, 25, handleLastRowNum(totalNum, 75)]
      case totalNum > 60:
        return [20, 20, 20, handleLastRowNum(totalNum, 60)]
      case totalNum > 40:
        return [20, 20, handleLastRowNum(totalNum, 40)]
      case totalNum > 30:
        return [15, 15, handleLastRowNum(totalNum, 30)]
      case totalNum > 20:
        return [10, 10, handleLastRowNum(totalNum, 20)]
      case totalNum > 10:
        return [10, handleLastRowNum(totalNum, 10)]
      default:
        return [totalNum]
    }
  }

  const handleValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    setResetOptionalButtons(!resetOptionalButtons)
    setValue(value as number)
  }

  const handleFlexWidth = (numInRow: number) => {
    if (numInRow > 10) {
      return `0 1 calc((100% - ${(rows[0] / 5 - 1) * 30}px) / ${rows[0]})`
    }
    return '0 1 calc((100% - 30px) / 10)'
  }

  const rows = handleTotalRows()

  return (
    <div
      className={classnames(styles.dynamicslider, className)}
      style={
        !image
          ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }
          : {}
      }
      {...componentProps}
    >
      {title && (
        <h1
          className="screen-titles--title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className="screen-titles--subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div
        className={classnames(styles.sliderAlign, {
          [styles['sliderAlign--middle']]: !title && !subtitle && !image
        })}
        style={
          !image
            ? {
                margin: 'auto',
                width: '100%'
              }
            : {}
        }
      >
        {dynamicImage && !customImage ? (
          <>
            {rows.map((num, i) => (
              <div
                className={styles['dynamicslider-horizontal-wrapper']}
                key={i}
                style={{
                  margin: num > 0 ? '50px 0' : undefined
                }}
              >
                {num > 0 ? (
                  new Array(num).fill(0).map((_value, index) => (
                    <div
                      key={index}
                      className={classnames(
                        styles['dynamicslider-horizontal-item'],
                        {
                          [styles['dynamicslider-horizontal-item--half']]:
                            !Number.isInteger(handleDefaultValue()) &&
                            (rows[1] === 0 || i > 0)
                        }
                      )}
                      style={{ flex: handleFlexWidth(rows[0]) }}
                    >
                      <div
                        className={styles.opacityDiv}
                        style={{ backgroundColor: maskColor }}
                      ></div>
                      <img width="100%" src={image} role="presentation" />
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className={styles['dynamicslider-staticImage']}>
            {generateImage()}
          </div>
        )}

        {!outro ? (
          <div className={classnames(styles['dynamicslider-innerWrapper'])}>
            <IconSlider
              className={classnames(styles.slider, {
                [styles['slider--round']]:
                  !labelPrefix &&
                  !labelSuffix &&
                  (!customLabels || customLabels.length === 0)
              })}
              components={{ Thumb: SliderThumbComponent }}
              valueLabelFormat={handleLabelFormat}
              valueLabelDisplay="on"
              defaultValue={handleDefaultValue()}
              min={min || 0}
              max={max || 100}
              step={step || 1}
              marks={
                minLabel && maxLabel
                  ? [
                      {
                        value: min,
                        label: minLabel
                      },
                      {
                        value: max,
                        label: maxLabel
                      }
                    ]
                  : false
              }
              onChangeCommitted={handleValueChange}
            />
          </div>
        ) : null}

        {optionalButtons && (
          <OptionalButtons
            optionalButtons={optionalButtons}
            color={color}
            wide={true}
            reset={resetOptionalButtons}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import styles from './FollowUpBody.module.scss'
import classnames from 'classnames'
import { BodySymptom, FollowUpBodyPropsModel } from './FollowUpBody.model'
import Grid from '@mui/material/Grid'

export const FollowUpBody: React.FC<FollowUpBodyPropsModel> = (props) => {
  const { title, subtitle, image, symptoms, playAudio } = props

  const [selected, setSelected] = useState<BodySymptom>()

  const handleClick = (key: keyof typeof symptoms) => {
    const symptom = symptoms[key]
    setSelected(symptom)

    if (symptom?.voices && symptom?.id) {
      playAudio?.(`${symptom?.id}-symptom`)
    }
  }

  const renderImages = () => {
    return (
      <>
        {Object.keys(symptoms).map((key) => (
          <button
            key={key}
            className={classnames(
              styles['followupbody-images-circle'],
              styles[`followupbody-images-${key}`],
              {
                [styles['followupbody-images-selected']]:
                  selected &&
                  selected.iconImage ===
                    symptoms[key as keyof typeof symptoms].iconImage
              }
            )}
            aria-label={key}
          >
            <img
              src={(symptoms as any)[key].iconImage}
              onClick={() => handleClick(key as keyof typeof symptoms)}
              alt={key}
            />
          </button>
        ))}
      </>
    )
  }

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
  }

  useEffect(() => {
    const wiggleButton = document.querySelectorAll(
      '.followupbody-images  button'
    )
    const timer = setInterval(() => {
      const index = getRandomInt(0, wiggleButton.length - 1)
      wiggleButton.forEach((b) => {
        b.classList.remove(styles['followupbody-images-wiggle'])
      })

      wiggleButton[index].classList.add(styles['followupbody-images-wiggle'])
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className={styles.followupbody}>
      {title && (
        <h1
          className={classnames(
            'screen-titles--title',
            styles['followupbody-title']
          )}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className={classnames(
            'screen-titles--subtitle',
            styles['followupbody-subtitle']
          )}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={styles['followupbody-content']}>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <div className={styles['followupbody-list']}>
              <ul>
                {selected &&
                  selected.symptomItems.map((item, index) => (
                    <li key={index}>
                      <h3>{item}</h3>
                    </li>
                  ))}
              </ul>
            </div>
          </Grid>
          <Grid xs={6}>
            <div
              className={classnames(
                'followupbody-images',
                styles['followupbody-images']
              )}
              style={{ backgroundImage: `url(${image})` }}
            >
              {renderImages()}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconProfile: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0783 17.7936C23.2286 20.9336 19.5186 22.0825 19.5186 22.0825C16.5045 23.1935 14.5724 22.3506 12.9884 21.0489C11.4037 19.7468 10.4767 17.2192 10.5926 14.6912C10.7082 12.3934 11.9061 10.7849 11.9061 10.7849C11.9061 10.7849 13.8772 7.91265 18.2434 8.87007C18.282 8.87007 26.4359 9.94277 24.0783 17.7936Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2067 2.73555C16.5092 2.7078 16.8152 2.69349 17.1276 2.69349C21.1966 2.69349 26.0505 5.07657 29.4958 8.83599C32.7868 12.4479 33.4366 16.5896 33.464 16.7644L33.4646 16.7683C33.5157 17.0544 34.6208 23.7195 27.1915 28.6585C26.5814 25.8885 24.8086 23.5546 22.4109 22.1947C21.1067 23.1001 19.8819 23.4794 19.8819 23.4794C16.4269 24.7529 14.1726 23.8581 12.3306 22.4013C10.0279 23.835 8.35841 26.1901 7.8377 28.951C6.6041 27.7066 5.47704 26.1519 4.46223 24.2916C4.46044 24.2884 4.45866 24.2851 4.45687 24.2817C4.45508 24.2784 4.45329 24.275 4.4515 24.2719C1.12468 18.4101 4.76003 11.1981 4.86547 10.9889L4.86672 10.9864C7.82253 4.38035 12.2585 3.10871 16.2067 2.73555ZM2.11137 25.5874C6.2708 33.2082 11.624 35.3497 16.3275 35.3488C17.8587 35.3488 19.3227 35.1215 20.6525 34.7823C22.9658 34.1926 24.9211 33.2395 26.1543 32.5433C27.3176 31.8865 27.9843 31.4399 28.3664 31.0972C37.6742 25.1328 36.1716 16.6322 36.1144 16.3405C36.0866 16.1374 35.385 11.3139 31.4771 7.02474C27.1592 2.31316 21.0695 -0.419824 15.9544 0.0634144C11.8567 0.44911 5.99607 1.88898 2.45232 9.81589C2.26976 10.1649 -1.95142 18.414 2.11137 25.5874Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconThreePieSecond: React.FC<Partial<IconPropsModel>> = (
  props
) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="206"
    height="309"
    viewBox="0 0 206 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.45672 45.7126C3.45677 36.7792 5.31807 13.1429 6.24871 2.44141C10.4362 3.52706 19.2766 5.69838 21.1377 5.69838C23.4641 5.69838 48.5893 11.747 55.1033 14.0735C61.6172 16.3999 83.4855 27.1014 103.027 39.1987C122.569 51.296 142.576 74.5601 153.743 84.7963C164.91 95.0325 189.57 137.373 196.549 153.193C203.528 169.012 203.528 197.86 203.993 218.798C204.459 239.735 183.986 277.888 175.611 289.52C168.911 298.826 163.204 304.565 161.188 306.271C157.931 303.014 150.672 296.407 147.694 296.034C144.717 295.662 128.773 283.782 121.173 277.888L83.4855 250.902C75.1104 244.078 57.7088 229.871 55.1033 227.638C51.8463 224.846 29.978 210.422 18.8113 203.909C9.87786 198.697 4.85265 193.982 3.45672 192.276V183.901C4.38738 169.012 5.69031 134.023 3.45672 113.179C0.664728 87.1227 3.45665 56.8794 3.45672 45.7126Z"
      fill={props.fill || '#8C625E'}
      fillOpacity="0.3"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
)

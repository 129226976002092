import React, { Dispatch, useEffect, useState } from 'react'
import { ISurveyMetaData, SurveyState, UserDataModel } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './LoginScreen.module.scss'
import Box from '@mui/material/Box'
import { LoginScreenComponentProps } from './LoginScreen.model'
import { Card } from 'components/Card/Card.component'
import { Pin } from 'components/Pin/Pin.component'
import { Text } from 'components/Text/Text.component'
import { loginAPI } from 'store/actionCreators'
import { colors } from 'shared/theme/theme'
import { Alert } from 'components/Alert/Alert.component'
import {
  FIRST_TIME_DEMO_WARNING,
  FIRST_TIME_LOGIN_WARNING,
  OFFLINE_PIN_WARNING,
  OFFLINE_USER_WARNING
} from 'shared/constants/messages/Messages.d'
import {
  updateService,
  updateSurvey,
  updateSurveyMetadata,
  updateUser
} from 'store/reducer'
import { ProjectDataModel, ProjectSiteModel } from 'api/client.model'
import { validateEmail } from 'shared/utils/validateValue/validateValue'
import { Button } from 'components/Button/Button.component'
import classnames from 'classnames'

const LoginScreenComponent: React.FC<LoginScreenComponentProps> = (props) => {
  const { service, demoService, survey, offline, loggedIn, loginAttempt } =
    props
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [demoModeLoading, setDemoModeLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>()
  const [pin, setPin] = useState<string>()
  const [alertText, setAlertText] = useState<string>()
  const [demoAlertText, setDemoAlertText] = useState<string>()

  const handleFormValidation = () => {
    // Lack of either email or pin
    if (!email || !pin || (pin && pin.length !== 4)) {
      setAlertText('Please fill in both email and pin to continue')
      return
    }

    // Email is not in the correct format
    if (!validateEmail(email)) {
      setAlertText('Please fill in a valid email address')
      return
    }

    setLoading(true)

    dispatch(loginAPI({ Username: email, Pin: pin }))

    if (offline) {
      validateLogin()
    }
  }

  const handleOfflineLogin = () => {
    if (!service) {
      setAlertText(FIRST_TIME_LOGIN_WARNING)
      return false
    }

    let valid = false
    let message = OFFLINE_USER_WARNING
    let userInfo: UserDataModel | undefined = undefined
    service?.projects.some((project) => {
      return project.sites?.some((site) => {
        return site.researchAssistants?.some((ra) => {
          if (ra.email.toLowerCase() === email?.toLowerCase()) {
            if (ra.pin === pin) {
              userInfo = ra
              valid = true
              return true
            } else {
              message = OFFLINE_PIN_WARNING
              return false
            }
          }
          return false
        })
      })
    })

    if (!valid) {
      setAlertText(message)
    }

    if (valid && userInfo) {
      dispatch(updateUser(userInfo))
    }

    return valid
  }

  const narrowDownProjects = () => {
    const projects: ProjectDataModel[] = []
    service?.projects.forEach((project) => {
      const newProject = { ...project, sites: [] as ProjectSiteModel[] }
      project.sites?.forEach((site) => {
        if (
          site.researchAssistants &&
          site.researchAssistants.find(
            (ra) =>
              ra.email.toLowerCase() === email?.toLowerCase() && ra.pin === pin
          )
        ) {
          newProject.sites.push(site)
        }
      })
      if (newProject.sites.length > 0) {
        projects.push(newProject)
      }
    })

    dispatch(
      updateService({
        ...service,
        projects
      })
    )
  }

  const validateLogin = () => {
    if (offline === undefined) {
      return
    }

    let valid = false

    if (loggedIn === 'true') {
      valid = true
    } else {
      if (offline) {
        valid = handleOfflineLogin()
      } else {
        setAlertText(loggedIn)
      }
    }

    if (valid) {
      setAlertText(undefined)
      narrowDownProjects()
      navigate('/ra-dashboard/select-project')
    }
    setLoading(false)
  }

  const handleForgotPin = () => {
    navigate('/ra-dashboard/forgot-pin')
  }

  const onDemoModeClick = () => {
    setDemoModeLoading(true)
    setDemoAlertText(undefined)
    dispatch(loginAPI({ Username: 'demo', Pin: 'demo', demoMode: true }))
  }

  // Demo mode
  useEffect(() => {
    if (demoModeLoading) {
      if (!demoService) {
        setDemoAlertText(FIRST_TIME_DEMO_WARNING)
        setDemoModeLoading(false)
      } else {
        const project = demoService?.projects[0]
        if (project) {
          dispatch(updateSurvey(project.survey))
          const metadata: ISurveyMetaData = {
            surveyId: project.survey?.id,
            serviceId: demoService.id,
            projectId: project.id,
            siteId:
              project.sites && project.sites.length > 0
                ? project.sites[0].id
                : '',
            shopId:
              project.shopId != null && project.shopId !== 0
                ? project.shopId
                : undefined
          }
          dispatch(updateSurveyMetadata(metadata))
        }
        if (survey) {
          navigate('/survey/demo')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, survey, offline])

  useEffect(() => {
    if (offline !== undefined && !demoModeLoading) {
      validateLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offline, loggedIn, loginAttempt])

  useEffect(() => {
    setAlertText(undefined)
  }, [email, pin])

  return (
    <main className={styles.pinscreen}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Login
      </h1>
      <div className={styles['pinscreen-wrapper']}>
        <Card
          className={styles['pinscreen-card']}
          title="Research Assistant Login"
          buttonDisabled={loading}
          buttonText={loading ? 'Logging in ...' : 'Login'}
          extraAction={{
            title: 'forgot your PIN?',
            onActionClick: handleForgotPin
          }}
          onButtonClick={handleFormValidation}
        >
          {alertText && (
            <Alert
              className={styles['pinscreen-alert']}
              fullWidth={true}
              text={alertText}
              backgroundColor={colors.crimson}
            />
          )}
          <Box className={styles['pinscreen-info']} component="form">
            <Text
              className={styles['pinscreen-text']}
              label="Enter email"
              textFieldProps={{
                required: true,
                fullWidth: true,
                type: 'email',
                autoFocus: true
              }}
              setValue={(value) => setEmail(value)}
            />
            <Pin
              title="4-digit PIN code"
              setValue={(value) => setPin(value)}
              isPassword={true}
            />
          </Box>
        </Card>
        <div className={styles['pinscreen-demoButtonWrapper']}>
          {demoAlertText && (
            <Alert
              className={styles['pinscreen-alert']}
              fullWidth={true}
              text={demoAlertText}
              backgroundColor={colors.crimson}
            />
          )}
          <Button
            className={classnames(
              'align-center',
              styles['pinscreen-demoButton']
            )}
            style={{
              backgroundColor: `${colors.capePalliser}`,
              color: `${colors.white} !important`,
              padding: '12px 0'
            }}
            onClick={onDemoModeClick}
            disabled={demoModeLoading}
          >
            {demoModeLoading ? 'Entering demo mode' : 'Or start demo mode'}
          </Button>
        </div>
      </div>
    </main>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  service: state.service,
  demoService: state.demoService,
  survey: state.survey,
  offline: state.offline,
  loggedIn: state.loggedIn,
  loginAttempt: state.loginAttempt
})

export const LoginScreen = connect(mapStateToProps)(LoginScreenComponent)

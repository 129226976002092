import { SliderCustomLabel } from 'components/Slider/Slider.model'

export const genderOptions = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
]

export const aboriginalOrIslanderOptions = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  },
  {
    label: 'I’m not sure',
    value: 'not-sure'
  }
]

export const ageOptions: SliderCustomLabel[] = [
  {
    label: '<20',
    value: 10
  },
  {
    label: '20s',
    value: 20
  },
  {
    label: '30s',
    value: 30
  },
  {
    label: '40s',
    value: 40
  },
  {
    label: '50s',
    value: 50
  },
  {
    label: '60s',
    value: 60
  },
  {
    label: '70s',
    value: 70
  },
  {
    label: '70+',
    value: 80
  }
]

export interface DeclinedScreenDataModel {
  gender?: string
  age?: string
  aboriginalOrIslander?: string
}

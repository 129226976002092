import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconStraightFace: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="87"
    height="86"
    viewBox="0 0 87 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_22)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1813 41.2975C84.1343 22.4372 65.0806 10.4355 65.0806 10.4355C49.8676 -0.309601 37.7821 1.51171 26.958 6.4249C16.1289 11.3392 7.1442 24.1037 3.99986 38.4354C1.19876 51.4775 5.4938 62.2833 5.4938 62.2833C5.4938 62.2833 12.2228 81.3271 38.112 82.5172C38.3284 82.5752 85.6033 88.8036 84.1813 41.2975Z"
        fill={props.fill || '#C57861'}
        stroke="#222222"
        strokeWidth="5.04542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.4021 54.1652C59.628 54.0816 55.8606 53.8611 52.5481 53.8147C49.8765 53.7387 47.6056 53.7387 42.9302 53.7387C38.9228 53.8079 34.5545 53.9442 32.6444 54.0816C29.5721 54.3027 27.3012 54.6324 27.3012 54.6324"
        stroke="#222222"
        strokeWidth="5.04542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9801 34.657C26.442 36.3638 28.4586 36.9883 28.4586 36.9883C30.0969 37.5922 31.1472 37.134 32.0081 36.4265C32.8695 35.7187 33.3734 34.3448 33.3104 32.9707C33.2476 31.7217 32.5965 30.8474 32.5965 30.8474C32.5965 30.8474 31.525 29.2861 29.1517 29.8065C29.1307 29.8065 24.6986 30.3896 25.9801 34.657Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4377 34.244C57.9381 35.8085 60.1228 36.381 60.1228 36.381C61.8976 36.9345 63.0354 36.5145 63.9681 35.8659C64.9012 35.2172 65.4471 33.9577 65.3789 32.6981C65.3108 31.5532 64.6054 30.7518 64.6054 30.7518C64.6054 30.7518 63.4447 29.3206 60.8737 29.7977C60.8509 29.7977 56.0494 30.3322 57.4377 34.244Z"
        fill="#222222"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_22">
        <rect width="87" height="86" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

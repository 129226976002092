import { Grid } from '@mui/material'
import { Screen } from 'components/Screen/Screen.component'
import React, { useEffect, useMemo, useState } from 'react'
import { GLOBAL_SHORTCODES } from 'shared/constants/shortcodes/Shortcodes.d'
import { AlcoholCalc } from 'shared/utils/alcoholCalculation/alcoholCalculation'
import {
  RiskLevel,
  RiskLevelParagraphs,
  RiskLevelVoices
} from 'shared/utils/alcoholCalculation/alcoholCalculation.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { AlcoholRiskScreenPropsModel } from './AlcoholRiskScreen.model'
import styles from './AlcoholRiskScreen.module.scss'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

export const AlcoholRiskScreen: React.FC<AlcoholRiskScreenPropsModel> = (
  props
) => {
  const dispatch: Dispatch<any> = useDispatch()
  const {
    currentScreenId,
    theme,
    name,
    headerProps,
    screenData,
    footerProps,
    shortcodes
  } = props

  const { calcAlcoholRiskLevel } = AlcoholCalc()

  const { getNextScreenId } = matchCondition()

  const [risk, setRisk] = useState<keyof typeof RiskLevel>('not drinking')

  const [mounted, setMounted] = useState<boolean>(false)

  const formatParagraph = (paragraph: string) => {
    return paragraph.replace(GLOBAL_SHORTCODES.OUTRO_RISK_LEVEL, risk)
  }

  const riskLevelParagraph = useMemo<string>(
    () => screenData?.[RiskLevelParagraphs?.[risk]] || '',
    [risk, screenData]
  )

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    // Next Screen Condition Logic
    updatedFooterProps.nextScreenId = getNextScreenId(
      undefined,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    return updatedFooterProps
  }

  useEffect(() => {
    const riskLevel = calcAlcoholRiskLevel()
    setRisk(riskLevel)

    if (name) {
      dispatch(
        updateSurveyAnswer({
          [name]: riskLevel ? riskLevel : undefined
        } as ISurveyData)
      )
    }

    if (RiskLevelVoices?.[riskLevel] && !mounted) {
      footerProps?.playAudio?.(
        `${currentScreenId}-${RiskLevelVoices[riskLevel]}`
      )
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`drug-app-screen ${styles.alcoholriskscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['alcoholriskscreen']}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            className={styles['alcoholriskscreen-container']}
          >
            <Grid
              item
              xs={6}
              className={styles['alcoholriskscreen-textWrapper']}
              direction="column"
            >
              {riskLevelParagraph && (
                <h2
                  style={{ marginBottom: 30 }}
                  dangerouslySetInnerHTML={{
                    __html:
                      shortcodesRender(
                        shortcodes,
                        formatParagraph(riskLevelParagraph)
                      ) || formatParagraph(riskLevelParagraph)
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={6}
              className={styles['alcoholriskscreen-imageWrapper']}
            >
              {risk && (
                <img
                  src={(screenData as any)[RiskLevel[risk]]}
                  className={styles['alcoholriskscreen-image']}
                  alt={risk}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Screen>
    </div>
  )
}

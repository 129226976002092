import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconSyncing: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38831 15.0502C8.07784 15.0502 7.76234 14.9557 7.49831 14.7624C7.31762 14.6465 6.89975 14.3461 6.56356 13.9364C6.18764 13.4715 5.53818 12.8853 5.53147 12.8799C5.19079 12.5728 5.16898 12.055 5.4828 11.7217C5.79606 11.3896 6.32637 11.3661 6.66816 11.6742C6.69837 11.701 7.42167 12.3532 7.87758 12.9165C7.99449 13.0596 8.17462 13.2207 8.36985 13.3595C8.43754 13.3087 8.49907 13.265 8.5606 13.2207C8.69598 13.124 8.88058 12.9918 8.94603 12.9274C9.1124 12.7806 9.42092 12.4941 9.66939 12.2618C9.87967 12.0652 10.212 12.0711 10.4144 12.2759L10.8374 12.7038C11.0441 12.913 11.0381 13.2512 10.8232 13.4519C10.5683 13.6898 10.2554 13.98 10.1012 14.1156C9.97756 14.2385 9.77114 14.386 9.55298 14.5428C9.46627 14.605 9.37844 14.6668 9.30069 14.7263C9.0512 14.9399 8.72283 15.0502 8.38831 15.0502Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1171 14.8597C21.4218 14.8597 21.7314 14.9542 21.9904 15.1476C22.1677 15.2634 22.5778 15.5639 22.9077 15.9736C23.2766 16.4385 23.9139 17.0246 23.9205 17.0301C24.2548 17.3371 24.2762 17.855 23.9682 18.1882C23.6608 18.5204 23.1404 18.5439 22.8051 18.2358C22.7754 18.209 22.0656 17.5567 21.6183 16.9935C21.5036 16.8504 21.3268 16.6892 21.1352 16.5505C21.0688 16.6013 21.0084 16.645 20.948 16.6892C20.8152 16.7859 20.6341 16.9181 20.5698 16.9826C20.409 17.1272 20.1129 17.4073 19.871 17.6376C19.6598 17.8388 19.3218 17.8329 19.1187 17.6235L18.7104 17.2025C18.5083 16.9942 18.5142 16.6614 18.7244 16.4613C18.9751 16.2228 19.2842 15.9306 19.4363 15.7944C19.5576 15.6715 19.7602 15.524 19.9742 15.3672C20.0593 15.3049 20.1455 15.2432 20.2218 15.1837C20.4666 14.9701 20.7889 14.8597 21.1171 14.8597Z"
      fill="#222222"
    />
    <path
      d="M8.28817 13.0384C8.18442 12.9518 8.17143 12.3976 8.94955 10.8735C9.72766 9.34936 10.7781 8.85287 11.206 8.79514C11.9193 8.53536 13.664 8.14669 14.9799 8.23229C16.5734 8.33594 18.8099 9.14429 19.5319 9.74771C20.1635 10.2756 21.0881 11.6095 21.3215 13.0384"
      stroke="#222222"
      strokeWidth="1.57041"
      strokeLinecap="round"
    />
    <path
      d="M21.2709 16.7755C21.3746 16.8621 21.3876 17.4163 20.6095 18.9404C19.8314 20.4645 18.781 20.961 18.353 21.0187C17.6397 21.2785 15.895 21.6671 14.5792 21.5816C12.9857 21.4779 10.7491 20.6696 10.0272 20.0661C9.39558 19.5382 8.47095 18.2043 8.23752 16.7755"
      stroke="#222222"
      strokeWidth="1.57041"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.493 2.97894C13.2746 3.00876 6.92443 3.9435 3.96217 8.47112C2.21418 11.1434 1.95851 14.5999 3.20334 18.7436C3.20632 18.7541 3.2093 18.7637 3.21229 18.7734C4.6718 24.1687 11.1852 26.2432 11.2508 26.2633C16.9569 28.3654 20.2866 26.5384 22.7964 24.4758C25.5231 22.2351 27.2152 17.6792 27.0073 13.1374C26.8053 9.13156 24.7159 6.25204 24.6943 6.22297C24.534 5.99413 21.1468 1.30997 13.6182 2.95882C13.5765 2.96776 13.5355 2.97447 13.493 2.97894ZM15.9931 29.447C14.392 29.447 12.5844 29.1369 10.5337 28.3803C10.2743 28.302 2.84259 25.9309 1.05734 19.3727C-0.468517 14.2779 0.0130181 10.0067 2.48927 6.67692C5.89356 2.10011 11.8762 0.967085 13.1397 0.779987L13.1389 0.774769C22.1852 -1.20877 26.3431 4.67326 26.5146 4.92447C26.5921 5.02733 29.0035 8.31608 29.2405 13.0301C29.4835 18.3269 27.5119 23.4956 24.2165 26.2037C22.2501 27.819 19.6613 29.4462 15.9931 29.447Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.493 2.97894C13.2746 3.00876 6.92443 3.9435 3.96217 8.47112C2.21418 11.1434 1.95851 14.5999 3.20334 18.7436C3.20632 18.7541 3.2093 18.7637 3.21229 18.7734C4.6718 24.1687 11.1852 26.2432 11.2508 26.2633C16.9569 28.3654 20.2866 26.5384 22.7964 24.4758C25.5231 22.2351 27.2152 17.6792 27.0073 13.1374C26.8053 9.13156 24.7159 6.25204 24.6943 6.22297C24.534 5.99413 21.1468 1.30997 13.6182 2.95882C13.5765 2.96776 13.5355 2.97447 13.493 2.97894ZM15.9931 29.447C14.392 29.447 12.5844 29.1369 10.5337 28.3803C10.2743 28.302 2.84259 25.9309 1.05734 19.3727C-0.468517 14.2779 0.0130181 10.0067 2.48927 6.67692C5.89356 2.10011 11.8762 0.967085 13.1397 0.779987L13.1389 0.774769C22.1852 -1.20877 26.3431 4.67326 26.5146 4.92447C26.5921 5.02733 29.0035 8.31608 29.2405 13.0301C29.4835 18.3269 27.5119 23.4956 24.2165 26.2037C22.2501 27.819 19.6613 29.4462 15.9931 29.447Z"
      fill="#222222"
    />
  </svg>
)

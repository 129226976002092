import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconPlus: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    width="37"
    height="36"
    className={props.className || ''}
    style={props.style}
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {props.bgColor && (
      <ellipse
        cx="18.0361"
        cy="18.8174"
        rx="15.5215"
        ry="15.4512"
        fill={props.bgColor}
      />
    )}

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3341 2.68506C17.0218 2.68506 16.7157 2.69938 16.4133 2.72712C12.465 3.10029 8.02908 4.37192 5.07327 10.978C5.00078 11.1221 1.31564 18.3742 4.65804 24.2635C4.66162 24.2697 4.6652 24.2769 4.66878 24.2831C7.22189 28.9634 10.4855 31.7098 14.3685 32.4445C20.6255 33.6275 26.4297 29.35 26.8208 29.0502C26.8825 28.992 26.9506 28.9392 27.0239 28.8927C34.864 23.9323 33.7231 17.0507 33.6711 16.7598C33.655 16.6587 33.0241 12.4733 29.7023 8.82756C26.257 5.06815 21.4032 2.68506 17.3341 2.68506ZM16.5341 35.3404C11.8306 35.3413 6.47734 33.1998 2.31791 25.5789C-1.74487 18.4055 2.47631 10.1565 2.65886 9.80746C6.20261 1.88056 12.0632 0.440688 16.1609 0.0549916C21.2761 -0.428247 27.3658 2.30474 31.6836 7.01631C35.5916 11.3055 36.2932 16.1289 36.3209 16.3321C36.3782 16.6238 37.8807 25.1243 28.573 31.0888C28.1909 31.4315 27.5242 31.878 26.3608 32.5349C25.1277 33.2311 23.1723 34.1842 20.8591 34.7739C19.5293 35.1131 18.0652 35.3404 16.5341 35.3404Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.965 26.4389C17.9181 26.4389 17.8712 26.4367 17.8231 26.4334C16.9016 26.3563 16.2169 25.5465 16.2929 24.6238C16.2962 24.5848 16.619 20.6675 16.5118 17.9019C16.4001 15.3149 16.5062 10.7308 16.5107 10.5387C16.533 9.62615 17.2792 8.90234 18.185 8.90234H18.2252C19.1512 8.92468 19.884 9.69205 19.8616 10.6169C19.8605 10.6638 19.7533 15.2837 19.8605 17.7656C19.9755 20.7446 19.6472 24.7355 19.6326 24.9031C19.56 25.7777 18.8262 26.4389 17.965 26.4389Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70693 17.5095C9.70693 16.5846 10.4575 15.834 11.3824 15.834C11.5365 15.834 15.1734 15.8362 17.6777 16.0641C20.0993 16.2763 24.6611 16.0618 24.7069 16.0596C25.6306 16.0149 26.4158 16.7298 26.4594 17.6535C26.5197 18.5963 25.7892 19.3625 24.8655 19.4061C24.67 19.4161 20.0312 19.6328 17.3806 19.4016C15.0193 19.1872 11.4181 19.1849 11.3824 19.1849C10.4575 19.1849 9.70693 18.4343 9.70693 17.5095Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconIncomplete: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2254 27.9911C24.328 27.0033 27.2303 20.2169 27.2303 20.2169C29.9239 14.7385 28.7111 10.9204 26.5626 7.67008C24.4135 4.41821 19.8184 2.16815 15.0172 1.8894C10.6511 1.65525 7.37483 3.60275 7.37483 3.60275C7.37483 3.60275 1.55747 6.76291 2.50729 15.2044C2.49968 15.2775 2.92142 30.9012 18.2254 27.9911Z"
      fill={props.fill || '#BD862C'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0621 2.78122C7.68772 2.78122 5.21743 6.23321 5.09742 6.40465C5.05642 6.46205 2.96182 9.37362 2.75907 13.4301C2.55036 18.0159 4.24616 22.6225 6.97735 24.8878C9.48863 26.969 12.8154 28.8154 18.4716 26.7126C18.5916 26.6738 25.1206 24.5524 26.5764 19.1228C26.5786 19.1146 26.5816 19.1064 26.5839 19.099C27.8071 14.9157 27.5387 11.425 25.7848 8.72213C22.7778 4.08718 16.3173 3.16138 16.2525 3.15243C16.2219 3.1487 16.1899 3.14274 16.1593 3.13678C15.036 2.88781 14.0043 2.78122 13.0621 2.78122ZM13.7875 29.8918C10.1208 29.8918 7.52228 28.2444 5.55067 26.609C2.25522 23.8771 0.282118 18.6622 0.525867 13.3235C0.762907 8.5641 3.17356 5.24702 3.27643 5.10763C3.42402 4.88923 7.57297 -1.03231 16.5991 0.943025C17.3162 1.04738 24.1882 2.16997 27.6513 7.48996C29.7832 10.7638 30.147 14.877 28.7329 19.7147C26.9492 26.3362 19.51 28.7297 19.1947 28.8281C17.1671 29.5824 15.3767 29.8918 13.7875 29.8918Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.922 18.8585C13.3048 18.8585 12.8039 18.3576 12.8039 17.7404C12.8039 15.97 14.285 15.1523 15.342 15.0122C15.9189 14.8556 17.3941 14.2772 17.6386 13.382C17.9323 12.3749 17.8823 11.5721 17.4977 10.9765C16.8201 9.92923 15.2451 9.66461 15.2294 9.66238C15.1922 9.65716 15.1556 9.6497 15.1191 9.6415C12.5668 9.01909 11.4785 11.3649 11.4331 11.4663C11.1804 12.0291 10.5207 12.2825 9.9549 12.0276C9.39137 11.7741 9.14017 11.1122 9.39361 10.5494C10.0362 9.11897 12.1613 6.64198 15.6059 7.4582C16.0203 7.52901 18.2237 7.97999 19.3754 9.76226C20.1283 10.9266 20.2684 12.3488 19.7913 13.9887C19.154 16.3271 16.1507 17.1187 15.8108 17.2007C15.7587 17.2134 15.7057 17.2223 15.6521 17.2275C15.4329 17.2551 15.0401 17.3602 15.0401 17.7404C15.0401 18.3576 14.5392 18.8585 13.922 18.8585Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5064 22.3191C15.3574 22.99 14.5374 23.2136 14.5374 23.2136C13.9411 23.4373 13.4938 23.2882 13.1957 22.99C12.8975 22.6918 12.6739 22.1701 12.6739 21.6483C12.6739 21.1265 12.9721 20.8283 12.9721 20.8283C12.9721 20.8283 13.3448 20.232 14.3138 20.4556C14.2392 20.4556 15.9537 20.6792 15.5064 22.3191Z"
      fill="#222222"
    />
  </svg>
)

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const OptionBorder2: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="162"
    height="164"
    viewBox="0 0 162 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.5277 3.89672L61.5531 3.89678L61.5786 3.89597L92.9201 2.89637C92.9204 2.89636 92.9207 2.89635 92.921 2.89634C130.547 1.75183 139.861 1.96551 147.32 2.13664C149.71 2.19149 151.91 2.24196 154.79 2.24196C156.546 2.24196 157.736 2.95157 158.564 4.26262C159.452 5.66962 159.973 7.86817 159.973 10.8433C159.973 12.8002 160.017 25.3595 160.076 41.9008C160.206 78.2761 160.404 133.908 160.346 138.396C160.285 143.098 160.274 147.546 160.281 151.178V151.178C160.287 153.659 159.908 156.025 158.946 157.719C158.05 159.297 156.624 160.365 154.141 160.365C151.281 160.365 151.119 160.371 150.074 160.41C148.384 160.475 144.382 160.627 122.892 160.984L122.881 160.984L122.871 160.984L89.9185 161.983C39.958 162.184 22.6033 160.538 13.185 159.645C11.7071 159.505 10.4246 159.383 9.24233 159.29C6.41332 159.067 5.00424 158.159 4.20508 157.055C3.34156 155.862 2.97675 154.164 2.84207 151.929C2.54744 147.034 2.19759 140.742 2.03792 136.085L2.03791 136.084C1.96147 133.869 2.00653 104.118 2.11776 74.1412C2.22907 44.1456 2.40635 14.1307 2.58681 11.3502L2.58683 11.3499C2.75469 8.75738 3.5423 7.23744 4.69961 6.31662C5.90705 5.3559 7.79146 4.82342 10.6111 4.82342C13.1066 4.82342 15.5062 4.72359 18.7134 4.59017C20.6151 4.51106 22.8007 4.42014 25.4587 4.33121C32.6416 4.09088 43.3814 3.85939 61.5277 3.89672Z"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

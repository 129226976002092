import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconArrowDown: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64466 9.0819C7.9377 9.0819 7.22089 8.86176 6.62132 8.41074C6.1703 8.11364 4.86913 7.18385 3.87312 5.93817C2.60686 4.33453 0.455553 2.34967 0.433181 2.32909C-0.111805 1.82796 -0.147601 0.977814 0.354431 0.432828C0.857357 -0.112158 1.7066 -0.147059 2.25069 0.354078C2.34555 0.440882 4.58277 2.50538 5.97521 4.26831C6.49783 4.92247 7.32112 5.65359 8.17126 6.22095C8.43794 6.41604 8.80574 6.45094 9.04825 6.23796C9.38652 5.97307 9.69436 5.74666 10.0031 5.52115C10.549 5.12024 11.1145 4.70591 11.3991 4.42044C12.3182 3.59088 14.882 1.11742 14.9071 1.09236C15.4404 0.577799 16.2915 0.593013 16.8051 1.12547C17.3197 1.65972 17.3054 2.50896 16.772 3.02352C16.6655 3.12733 14.1482 5.55605 13.2471 6.36682C12.8685 6.74894 12.2466 7.20354 11.5897 7.68588C11.3034 7.89707 11.0152 8.10648 10.7566 8.30872C10.174 8.8197 9.41516 9.0819 8.64466 9.0819Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

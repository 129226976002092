import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconSupport: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1276 2.76413C16.8152 2.76413 16.5092 2.77845 16.2067 2.80619C12.2585 3.17936 7.82253 4.45099 4.86672 11.057C4.79424 11.2011 1.10909 18.4533 4.4515 24.3425C4.45507 24.3488 4.45865 24.356 4.46223 24.3622C7.01534 29.0425 10.279 31.7889 14.1619 32.5236C20.419 33.7066 26.2232 29.4291 26.6143 29.1293C26.676 29.0711 26.744 29.0183 26.8174 28.9718C34.6575 24.0114 33.5165 17.1297 33.4646 16.8389C33.4485 16.7378 32.8176 12.5524 29.4958 8.90663C26.0505 5.14722 21.1966 2.76413 17.1276 2.76413ZM16.3275 35.4194C11.624 35.4203 6.2708 33.2789 2.11137 25.658C-1.95142 18.4846 2.26976 10.2355 2.45232 9.88653C5.99607 1.95963 11.8567 0.519759 15.9544 0.134063C21.0695 -0.349176 27.1592 2.38381 31.4771 7.09538C35.385 11.3846 36.0866 16.208 36.1144 16.4111C36.1716 16.7029 37.6742 25.2034 28.3664 31.1678C27.9843 31.5106 27.3176 31.9571 26.1543 32.614C24.9211 33.3102 22.9658 34.2632 20.6525 34.853C19.3227 35.1921 17.8587 35.4194 16.3275 35.4194Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0234 14.6458C17.7644 14.6458 18.3657 15.2472 18.3657 15.9882C18.3657 16.0857 18.3639 18.4115 18.1778 20.0214C18.014 21.5025 18.1823 24.3419 18.1841 24.3697C18.2288 25.1097 17.665 25.746 16.9259 25.7908C16.1661 25.8427 15.5495 25.2726 15.5048 24.5325C15.4967 24.4064 15.3204 21.4362 15.5101 19.7198C15.6793 18.2549 15.6811 16.0105 15.6811 15.9882C15.6811 15.2472 16.2824 14.6458 17.0234 14.6458Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3677 9.59644C15.5467 8.79104 16.5311 8.52257 16.5311 8.52257C17.247 8.25411 17.7839 8.43308 18.1419 8.79104C18.4998 9.14899 18.7683 9.77541 18.7683 10.4018C18.7683 11.0283 18.4104 11.3862 18.4104 11.3862C18.4104 11.3862 17.9629 12.1021 16.7996 11.8337C16.889 11.8337 14.8308 11.5652 15.3677 9.59644Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../../Icon.model'

export const IconFourPieFourth: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="202"
    height="194"
    preserveAspectRatio="none"
    viewBox="0 0 202 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="img4"
        patternUnits="userSpaceOnUse"
        width="100%"
        height="100%"
      >
        <image xlinkHref={props.image} x="0" y="0" width="100%" height="100%" />
      </pattern>
    </defs>
    <path
      d="M2.59243 36.2562V2.23481H25.0247L54.8778 2.57989C62.4947 2.14524 80.64 1.53673 92.2864 2.57989C103.933 3.62305 119.253 3.01454 125.457 2.57989H142.779C150.826 2.20732 168.467 1.68572 174.659 2.57989C180.851 3.47406 194.193 2.95246 200.09 2.57989V11.7079C199.844 20.8979 198.062 42.7802 192.903 56.7888C186.453 74.2996 183.504 75.4173 172.079 93.8595C160.654 112.302 127.299 142.294 109.977 154.775C92.655 167.256 69.6202 174.335 57.8263 179.364C46.0325 184.394 15.6264 189.61 8.25523 191.1C2.35831 192.292 2.02298 191.597 2.59243 191.1C2.94437 188.554 3.64826 182.159 3.64826 176.943C3.64826 170.423 2.59243 140.058 2.59243 130.93V90.3201C2.59243 75.9762 1.60645 71.1257 1.60645 65.0821C1.60645 58.5256 2.59243 55.1569 2.59243 36.2562Z"
      fill={props.image ? 'url(#img4)' : props.fill || '#8C625E'}
      fillOpacity={props.image ? '1' : '0.3'}
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconDashboard: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4834 23.1111C29.4975 24.5915 29.5121 26.1142 29.5063 27.4157C29.499 29.1038 29.4976 30.7011 29.4985 32.0058C29.4999 33.8666 29.3888 36.152 28.6442 36.152H23.1847L21.9196 36.1896C21.9399 35.9504 21.9521 35.6625 21.9563 35.3207C21.9722 33.9986 21.9868 31.828 21.9801 30.7796C21.9732 29.6912 21.958 28.2234 21.9452 27.0843C21.9333 26.0187 21.8796 25.1593 21.6087 25.0446C21.5593 25.0237 21.5002 24.9931 21.429 24.9563C20.9565 24.7121 19.9545 24.1942 17.7653 24.417L16.339 24.6496C15.3533 24.7375 14.7729 24.9567 14.3985 25.0981C14.1998 25.1731 14.0591 25.2262 13.9465 25.2262C13.6829 25.2262 13.6155 26.2832 13.616 27.4866C13.6163 28.3304 13.6159 29.3635 13.6132 30.4552C13.6112 31.297 13.6164 32.2817 13.6214 33.2392C13.6254 34.0126 13.6294 34.7681 13.6294 35.416C13.6294 35.791 13.6356 36.1143 13.6483 36.3875C8.77275 36.3505 8.037 36.119 7.37283 35.9101C7.23145 35.8656 7.09331 35.8222 6.91919 35.7818C6.15402 35.6044 6.0022 34.2756 5.96847 32.628C5.93246 30.8665 5.88958 28.5971 5.86998 26.9141C5.85097 25.2931 5.89226 21.9368 5.93733 19.8926C5.93946 19.796 5.94145 19.7028 5.94337 19.6127C5.98247 17.7843 5.99337 17.2744 6.49904 16.7636C6.81133 16.4481 7.16696 16.0799 7.55927 15.6738C10.0285 13.1174 13.9507 9.05678 17.6639 7.16846C17.7519 7.12369 17.8485 7.10155 17.9473 7.1015C18.0875 7.10144 18.2281 7.15044 18.3397 7.23511C26.0365 13.0705 26.5642 13.7575 28.1421 15.812C28.2968 16.0134 28.4616 16.2279 28.6442 16.4617C29.2112 17.1878 29.4608 17.5093 29.4608 19.7452C29.4608 20.747 29.4719 21.9152 29.4834 23.1111Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.437 3.89642C28.58 3.89642 29.5076 4.27159 29.5076 4.73385C29.5076 4.79471 29.5048 6.2457 29.2177 7.25005C28.9651 8.17402 29.2246 9.94546 29.2274 9.96277C29.2964 10.4245 28.4267 10.8214 27.2866 10.8493C26.1146 10.8817 25.1636 10.5261 25.0945 10.0644C25.0821 9.98566 24.8102 8.13271 25.1028 7.06191C25.3637 6.148 25.3665 4.74781 25.3665 4.73385C25.3665 4.27159 26.2941 3.89642 27.437 3.89642"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3357 1.71382C18.8405 2.25616 18.77 3.14343 18.1782 3.69433C18.1003 3.76685 8.79348 12.5613 7.38087 13.622C6.08643 14.6033 3.93326 16.8375 3.91233 16.8595C3.35174 17.4424 2.45943 17.5029 1.92005 16.9951C1.36092 16.4776 1.39607 15.6025 1.95665 15.0195C2.05194 14.9198 4.30397 12.5825 5.80406 11.4452C7.0893 10.4798 16.3311 1.74595 16.349 1.72932C16.9407 1.17842 17.8308 1.17148 18.3357 1.71382Z"
      fill={props.fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7836 2.16974C17.2416 1.58731 18.1403 1.52899 18.7894 2.03954C18.8749 2.10675 29.2149 10.1171 30.5103 11.3727C31.7066 12.5219 34.2983 14.3462 34.3237 14.3639C34.9998 14.8387 35.2087 15.7202 34.791 16.3321C34.3668 16.9651 33.4862 17.0569 32.8101 16.5822C32.6946 16.5015 29.9834 14.5936 28.5969 13.2618C27.418 12.1195 17.1494 4.16519 17.1298 4.14978C16.4806 3.63922 16.3255 2.75216 16.7836 2.16974Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

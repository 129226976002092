import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconHand: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      xmlns="http://www.w3.org/2000/svg"
      cx="23.5"
      cy="23.5"
      r="14.5"
      fill={props.stroke || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9001 26.3458C33.9001 31.5719 29.6485 35.8244 24.4215 35.8244C20.8616 35.8244 17.6043 33.8288 15.9836 30.6627L13.8475 26.4881C13.3348 25.4858 13.4484 24.2616 14.1366 23.3703C14.9697 22.291 16.4695 21.9304 17.7018 22.5148L19.7681 23.4929V14.9566C19.7681 13.3628 21.0657 12.0661 22.6586 12.0661C24.2533 12.0661 25.55 13.3628 25.55 14.9566V20.0368L33.2683 22.5416C33.6451 22.6642 33.9001 23.015 33.9001 23.4114V26.3458ZM44.3175 11.3681C38.6466 2.63666 27.252 0.812877 26.222 0.664326C11.4958 -2.56979 4.7689 7.03592 4.51923 7.40372C4.35188 7.63012 0.421543 13.0388 0.0331619 20.8172C-0.365063 29.5611 2.85026 38.0885 8.22316 42.5441C11.4349 45.2064 15.6624 47.8884 21.6411 47.8884C24.2389 47.8884 27.1679 47.3828 30.4861 46.1478C31.0016 45.9867 43.1452 42.0797 46.0446 31.3088C48.3561 23.4007 47.7753 16.6917 44.3175 11.3681V11.3681Z"
      fill={props.fill || '#C57861'}
    />
  </svg>
)

import React from 'react'
import styles from './Product.module.scss'

import classnames from 'classnames'
import { ProductPropsModel } from './Product.model'

export const Product: React.FC<ProductPropsModel> = (props) => {
  const { isPack, clipPercentage, image } = props
  return (
    <div className={classnames(styles['product-wrapper'])}>
      <img
        className={classnames(styles['product-img'])}
        width="100%"
        style={{
          clipPath: `inset(${isPack ? 0 : clipPercentage}% ${
            isPack ? clipPercentage : 0
          }% 0% 0%)`
        }}
        src={image}
        role="presentation"
        alt=""
      />
      <img
        className={classnames(styles['product-background'])}
        width="100%"
        src={image}
        role="presentation"
        alt=""
      />
    </div>
  )
}

import React from 'react'
import { BorderPropsModel } from '../Border.model'

export const CardBorder1: React.FC<Partial<BorderPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    preserveAspectRatio="none"
    width="276"
    height="275"
    viewBox="0 0 276 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.929 4.7L104.954 4.70005L104.979 4.69925L158.396 3.01277C222.554 1.0809 238.449 1.44194 251.162 1.7307C255.232 1.82316 258.977 1.90821 263.875 1.90821C267.234 1.90821 269.602 3.29165 271.203 5.80363C272.866 8.41065 273.764 12.3419 273.764 17.4504C273.764 20.7431 273.84 41.8971 273.94 69.7699C274.161 131.139 274.498 225.08 274.399 232.661C274.297 240.587 274.277 248.085 274.29 254.209C274.299 258.466 273.648 262.665 271.879 265.75C270.177 268.718 267.404 270.741 262.768 270.741C257.897 270.741 257.628 270.751 255.867 270.818C253.012 270.926 246.233 271.182 209.527 271.785L209.517 271.785L209.507 271.785L153.332 273.471C68.1283 273.812 38.5163 271.032 22.4531 269.524C19.9336 269.287 17.7474 269.082 15.7324 268.925C10.6936 268.532 7.9629 266.901 6.37162 264.725C4.71729 262.464 4.08574 259.346 3.854 255.539C3.3518 247.28 2.75484 236.654 2.48223 228.782L2.48221 228.782C2.35104 225.019 2.4282 174.791 2.6177 124.238C2.80728 73.6642 3.109 22.9719 3.41926 18.2397L3.41927 18.2395C3.71711 13.686 5.13395 10.8047 7.41084 9.01137C9.74077 7.17623 13.239 6.26343 18.1494 6.26343C22.3819 6.26343 26.437 6.09637 31.8818 5.87206C35.1242 5.73849 38.8594 5.58461 43.4183 5.43361C55.675 5.02767 73.9916 4.63699 104.929 4.7Z"
      stroke={props.stroke || '#222222'}
      fill={props.fill || 'none'}
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconVolume: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="33"
    height="26"
    viewBox="0 0 33 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1568 25.5691C24.7317 25.5691 24.3138 25.3669 24.0534 24.991C23.6301 24.3825 23.7822 23.5458 24.3916 23.1243C27.0951 21.2495 29.7914 18.5147 29.2822 12.4483C29.2768 12.3105 28.9833 5.37871 24.116 2.49718C23.478 2.11953 23.2668 1.29624 23.6444 0.658186C24.022 0.0201318 24.8462 -0.189272 25.4825 0.186581C31.6348 3.82787 31.9516 11.9517 31.9615 12.2953C32.5843 19.6969 29.0603 23.1538 25.921 25.3293C25.6874 25.4913 25.4208 25.5691 25.1568 25.5691Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3572 21.8262C19.9679 21.8262 19.5831 21.6589 19.3182 21.334C18.8475 20.7613 18.9316 19.9147 19.5062 19.4458C21.1939 18.0623 22.6928 16.2466 22.2373 12.3565C22.2284 12.2473 21.8785 7.80244 18.9146 6.19165C18.2632 5.83727 18.0224 5.02293 18.3759 4.37055C18.7321 3.71908 19.5446 3.48104 20.197 3.83273C24.4701 6.15496 24.8934 11.8697 24.9104 12.1122C25.5216 17.3196 23.0902 19.9801 21.2064 21.5219C20.9577 21.7269 20.6561 21.8262 20.3572 21.8262Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0715 18.1155C15.7601 18.1155 15.4478 18.0082 15.1936 17.788C14.6334 17.303 14.5726 16.4546 15.0576 15.8944C15.3816 15.5213 15.8066 14.8653 15.7673 13.4872C15.7717 12.9798 15.6321 11.65 14.9323 10.8804C14.4339 10.3318 14.4742 9.48347 15.0227 8.98412C15.5704 8.48656 16.4214 8.52504 16.919 9.0745C18.4645 10.7748 18.4564 13.2071 18.451 13.4782C18.5011 15.1481 18.0546 16.5352 17.0872 17.652C16.8214 17.958 16.4474 18.1155 16.0715 18.1155Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97288 7.60672C5.63331 7.58256 6.27315 7.3651 6.76713 6.92571L7.95822 5.86795C8.19358 5.65855 8.47189 5.43662 8.76004 5.22095C9.62092 4.57484 10.8586 5.09477 10.9668 6.16595C11.2255 8.72175 11.5512 12.7711 11.2621 14.7891C11.042 16.3319 11.0706 18.1064 11.1539 19.9239C11.2111 21.1678 9.74531 21.8793 8.79584 21.073C8.75557 21.039 8.7153 21.005 8.67592 20.971C7.51794 19.9785 6.45124 18.7597 5.56977 18.7597C5.05432 18.7597 3.99925 18.9002 2.08061 18.595C0.609418 18.3606 0 17.4317 0 16.0885V10.1679C0 8.93473 1.09534 7.92977 2.54685 7.8546C2.54685 7.8546 2.88064 7.68368 4.97288 7.60672Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconSync: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="30"
    viewBox="0 0 37 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38632 15.1223C5.79323 15.1223 5.19052 14.9418 4.68613 14.5723C4.34096 14.3511 3.54269 13.7771 2.90044 12.9945C2.18232 12.1064 0.941639 10.9866 0.928816 10.9762C0.278019 10.3897 0.236342 9.40035 0.835845 8.76376C1.43428 8.12926 2.44734 8.08439 3.10028 8.67297C3.15798 8.72411 4.53972 9.97014 5.41066 11.0461C5.634 11.3195 5.9781 11.6274 6.35105 11.8924C6.48036 11.7954 6.59791 11.7119 6.71546 11.6274C6.97407 11.4426 7.32671 11.1901 7.45174 11.067C7.76957 10.7865 8.35895 10.2393 8.83359 9.79555C9.2353 9.41998 9.87018 9.43126 10.2568 9.82238L11.0648 10.6399C11.4597 11.0394 11.4483 11.6856 11.0377 12.0689C10.5508 12.5235 9.95313 13.0778 9.65847 13.3367C9.4223 13.5716 9.02798 13.8533 8.61121 14.1528C8.44557 14.2718 8.2778 14.3897 8.12926 14.5035C7.65265 14.9115 7.02536 15.1223 6.38632 15.1223Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7035 14.7583C31.2855 14.7583 31.8769 14.9388 32.3719 15.3083C32.7106 15.5295 33.4939 16.1035 34.1241 16.8862C34.8288 17.7743 36.0463 18.894 36.0589 18.9045C36.6975 19.4909 36.7384 20.4803 36.1501 21.1169C35.5629 21.7514 34.5688 21.7962 33.928 21.2076C33.8714 21.1565 32.5155 19.9105 31.6609 18.8345C31.4418 18.5611 31.1041 18.2533 30.7381 17.9882C30.6112 18.0852 30.4959 18.1687 30.3805 18.2533C30.1268 18.438 29.7807 18.6905 29.658 18.8137C29.3509 19.0899 28.7851 19.6249 28.3231 20.065C27.9196 20.4494 27.274 20.4381 26.886 20.0381L26.1059 19.2338C25.7199 18.8358 25.731 18.2001 26.1327 17.8179C26.6116 17.3622 27.2022 16.804 27.4926 16.5439C27.7244 16.3091 28.1113 16.0273 28.5203 15.7278C28.6828 15.6088 28.8475 15.4909 28.9932 15.3771C29.4609 14.9691 30.0764 14.7583 30.7035 14.7583Z"
      fill={props.fill || '#222222'}
    />
    <path
      d="M6.19432 11.279C5.99611 11.1136 5.97131 10.0548 7.45776 7.14329C8.94421 4.23174 10.9509 3.28328 11.7684 3.17299C13.131 2.67673 16.464 1.93426 18.9777 2.09777C22.0218 2.29578 26.2943 3.83998 27.6735 4.99273C28.88 6.00123 30.6464 8.54943 31.0923 11.279"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M30.9958 18.4181C31.1941 18.5835 31.2189 19.6422 29.7324 22.5537C28.246 25.4653 26.2393 26.4137 25.4218 26.524C24.0592 27.0203 20.7262 27.7628 18.2125 27.5993C15.1684 27.4012 10.8958 25.857 9.51671 24.7043C8.31015 23.6958 6.54381 21.1476 6.09787 18.4181"
      stroke={props.stroke || '#222222'}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

import React from 'react'
import { SurveyState } from 'store/type'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './ProfileScreen.module.scss'
import { ProfileScreenComponentProps } from './ProfileScreen.model'
import { SideMenu } from 'components/SideMenu/SideMenu.component'
import { RADashboardMenu } from '../index.model'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'

const ProfileScreenComponent: React.FC<ProfileScreenComponentProps> = (
  props
) => {
  const navigate = useNavigate()

  const { avatar, id, firstname, lastname, username, email, phone } = props

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  return (
    <div className={styles.profilescreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[4].value}
        setValue={handleMenuChange}
      />
      <main className={styles['profilescreen-content']}>
        <h1
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none'
          }}
        >
          My Profile
        </h1>
        <div className={styles['profilescreen-content-avatar']}>
          <Border
            type={BorderType.Circle2}
            fill={avatar ? 'none' : colors.white}
            image={avatar}
          />
        </div>
        <div className={styles['profilescreen-content-info']}>
          <Border type={BorderType.Modal} fill={colors.white} dropShadow />
          <div className={styles['profilescreen-content-info-content']}>
            <h5>Research Assistant ID:</h5>
            <p>{id}</p>
            <h5>User Name:</h5>
            <p>{username}</p>
            <h5>First Name:</h5>
            <p>{firstname}</p>
            <h5>Last Name:</h5>
            <p>{lastname}</p>
            <h5>4-digit PIN code:</h5>
            <p>****</p>
            <h5>Email:</h5>
            <p>{email}</p>
            <h5>Phone:</h5>
            <p>{phone}</p>
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  id: state.user?.id || '',
  avatar: state.user?.profilePhoto || undefined,
  firstname: state.user?.firstName || '',
  lastname: state.user?.lastName || '',
  username: state.user?.username || '',
  email: state.user?.email || '',
  phone: state.user?.phone || ''
})

export const ProfileScreen = connect(mapStateToProps)(ProfileScreenComponent)

import {
  EventDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel,
  SurveyVoiceModel
} from 'api/client.model'
import { FooterPropsModel } from 'components/Footer/Footer.model'
import { HeaderPropsModel } from 'components/Header/Header.model'
import { ScreenTheme } from 'components/Screen/Screen.model'
import { HTMLAttributes } from 'react'
import { ISurveyData } from 'store/type'
import { genericObject } from '../type'

export interface GrogDiaryScreenData {
  requireDiary: number
  otherDayDiary: number
  groupStdDrinksThreshold?: number
  peopleImages: string[]
  justMeImage: string
  groupImage: string
  lastDay?: genericObject
  howManyPeople?: genericObject
  lastDayType?: genericObject
  howMuchPutInIt?: genericObject
  meOrGroup?: genericObject
  share?: genericObject
  anythingMoreToDrink?: genericObject
  allYouHadToDrink?: genericObject
  whenWasTheTimeBefore?: genericObject
}

export interface GrogDiaryScreenPropsModel extends HTMLAttributes<HTMLElement> {
  currentScreenId: string
  name: string
  theme: ScreenTheme
  screenData: GrogDiaryScreenData
  headerProps: HeaderPropsModel
  footerProps: FooterPropsModel
}

export interface GrogDiaryScreenStatePropsModel {
  events?: EventDataModel[]
  birthday?: string
  surveyData?: ISurveyData
  voiceOver: SurveyVoiceModel[] | undefined
  typesCategories?: Array<GrogTypeCategoryModel | GrogSubTypeModel>
}

export enum GrogDiaryStep {
  LASTDAY,
  PEOPLE,
  TYPESCATEGORIES,
  SUBTYPE,
  BRAND,
  PRODUCT,
  PRODUCT_CONTAINER,
  CUSTOM_CONTAINER,
  IS_GROUP,
  SHARE,
  MORE,
  OTHERDATE,
  DIARY
}

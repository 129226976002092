import React from 'react'
import { IconPropsModel } from '../Icon.model'

export const IconContinue: React.FC<Partial<IconPropsModel>> = (props) => (
  <svg
    className={props.className || ''}
    style={props.style}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.484 3.18012C17.1717 3.18012 16.8656 3.19444 16.5632 3.22218C12.6149 3.59535 8.17898 4.86698 5.22317 11.473C5.15068 11.6171 1.46554 18.8693 4.80794 24.7585C4.81152 24.7648 4.8151 24.7719 4.81868 24.7782C7.37179 29.4585 10.6354 32.2049 14.5184 32.9396C20.7754 34.1226 26.5796 29.845 26.9707 29.5453C27.0324 29.4871 27.1005 29.4343 27.1738 29.3878C35.0139 24.4274 33.873 17.5457 33.821 17.2549C33.8049 17.1538 33.174 12.9684 29.8522 9.32262C26.4069 5.5632 21.5531 3.18012 17.484 3.18012ZM16.684 35.8354C11.9805 35.8363 6.62724 33.6949 2.46781 26.074C-1.59497 18.9006 2.62621 10.6515 2.80876 10.3025C6.35251 2.37562 12.2131 0.935744 16.3108 0.550048C21.426 0.0668091 27.5157 2.79979 31.8335 7.51137C35.7415 11.8006 36.4431 16.624 36.4708 16.8271C36.5281 17.1189 38.0306 25.6194 28.7229 31.5838C28.3408 31.9266 27.6741 32.3731 26.5107 33.0299C25.2776 33.7262 23.3222 34.6792 21.009 35.269C19.6792 35.6081 18.2151 35.8354 16.684 35.8354Z"
      fill={props.fill || '#222222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0629 13.0416C14.1632 12.2773 15.3677 11.8639 16.1776 12.4008C17.1789 13.0648 18.3736 13.8174 19.4681 14.4009C20.7934 15.1168 21.9863 16.1012 22.7818 16.9066C23.5774 17.6225 23.5774 18.6964 22.6494 19.4123C21.7214 20.2177 20.6609 21.2915 19.8654 21.8285C19.2676 22.2759 18.0389 23.1511 16.9615 23.9118C16.2062 24.4451 14.8719 24.1095 14.8182 23.3721C14.8182 23.3721 13.5448 16.9916 14.0629 13.0416Z"
      fill={props.fill || '#222222'}
    />
  </svg>
)

import { HTMLAttributes, HTMLElement } from 'shared/types/react.d'
import { IconAdd } from './icons/Add'
import { IconArrowDown } from './icons/ArrowDown'
import { IconArrowLeft } from './icons/ArrowLeft'
import { IconArrowRight } from './icons/ArrowRight'
import { IconBigFrown } from './icons/BigFrown'
import { IconBigSmile } from './icons/BigSmile'
import { IconCake } from './icons/Cake'
import { IconCalendar } from './icons/Calendar'
import { IconClock } from './icons/Clock'
import { IconClose } from './icons/Close'
import { IconComplete } from './icons/Complete'
import { IconContinue } from './icons/Continue'
import { IconDashboard } from './icons/Dashboard'
import { IconDecrease } from './icons/Decrease'
import { IconIncrease } from './icons/Increase'
import { IconDelete } from './icons/Delete'
import { IconFeedback } from './icons/Feedback'
import { IconHand } from './icons/Hand'
import { IconIncomplete } from './icons/Incomplete'
import { IconInformation } from './icons/Information'
import { IconLogo } from './icons/Logo'
import { IconLogout } from './icons/Logout'
import { IconMute } from './icons/Mute'
import { IconNotSync } from './icons/NotSync'
import { IconPause } from './icons/Pause'
import { IconPerson } from './icons/Person'
import { IconPlus } from './icons/Plus'
import { IconProfile } from './icons/Profile'
import { IconProgress } from './icons/Progress'
import { IconReplay } from './icons/Replay'
import { IconSmallFrown } from './icons/SmallFrown'
import { IconSmallSmile } from './icons/SmallSmile'
import { IconStop } from './icons/Stop'
import { IconStraightFace } from './icons/StraightFace'
import { IconSupport } from './icons/Support'
import { IconSurveys } from './icons/Surveys'
import { IconSync } from './icons/Sync'
import { IconSyncing } from './icons/Syncing'
import { IconTechnical } from './icons/Technical'
import { IconTick } from './icons/Tick'
import { IconVoice } from './icons/Voice'
import { IconVolume } from './icons/Volume'
import { IconPhone } from './icons/Phone'
import { IconEmail } from './icons/Email'
import { IconThreePieFirst } from './icons/PieChart/ThreePieFirst'
import { IconThreePieSecond } from './icons/PieChart/ThreePieSecond'
import { IconThreePieThird } from './icons/PieChart/ThreePieThird'
import { IconFourPieFirst } from './icons/PieChart/FourPieFirst'
import { IconFourPieSecond } from './icons/PieChart/FourPieSecond'
import { IconFourPieThird } from './icons/PieChart/FourPieThird'
import { IconFourPieFourth } from './icons/PieChart/FourPieFourth'
import { IconExistingSLK } from './icons/ExistingSLK'
import { IconInvalidSLK } from './icons/InvalidSLK'
/**
 * Icon property model
 *
 * @remarks
 * This interface describes the properties that can be passed to the Icon React component
 */
export interface IconPropsModel extends HTMLAttributes<HTMLElement> {
  /** Icon to display */
  icon: IconType

  /** Size of the icon */
  size?: number

  /** Accessible title */
  title?: string

  /** Background color of the icon */
  stroke?: string

  /** Show border of the icon with stroke color */
  border?: boolean

  /** Color of the icon */
  fill?: string

  /** Background color of the icon */
  bgColor?: string

  /** Image background of the icon */
  image?: string
}

/**
 * Standardlise Icon Types
 */
export enum IconType {
  Add = 'Add',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  BigFrown = 'BigFrown',
  BigSmile = 'BigSmile',
  Cake = 'Cake',
  Clock = 'Clock',
  Close = 'Close',
  Complete = 'Complete',
  Continue = 'Continue',
  Dashboard = 'Dashboard',
  Email = 'Email',
  Delete = 'Delete',
  Feedback = 'Feedback',
  Hand = 'Hand',
  Incomplete = 'Incomplete',
  Information = 'Information',
  Logo = 'Logo',
  Logout = 'Logout',
  Mute = 'Mute',
  NotSync = 'NotSync',
  Pause = 'Pause',
  Person = 'Person',
  Phone = 'Phone',
  Plus = 'Plus',
  Profile = 'Profile',
  Progress = 'Progress',
  Replay = 'Replay',
  SmallFrown = 'SmallFrown',
  SmallSmile = 'SmallSmile',
  Stop = 'Stop',
  StraightFace = 'StraightFace',
  Support = 'Support',
  Surveys = 'Surveys',
  Sync = 'Sync',
  Syncing = 'Syncing',
  Technical = 'Technical',
  Tick = 'Tick',
  Voice = 'Voice',
  Volume = 'Volume',
  Calendar = 'Calendar',
  Increase = 'Increase',
  Decrease = 'Decrease',
  ExistingSLK = 'ExistingSLK',
  InvalidSLK = 'InvalidSLK',

  // Pie Chart Pieces
  ThreePie1 = 'ThreePie1',
  ThreePie2 = 'ThreePie2',
  ThreePie3 = 'ThreePie3',
  FourPie1 = 'FourPie1',
  FourPie2 = 'FourPie2',
  FourPie3 = 'FourPie3',
  FourPie4 = 'FourPie4'
}

export const iconComponents = {
  Add: IconAdd,
  ArrowDown: IconArrowDown,
  ArrowLeft: IconArrowLeft,
  ArrowRight: IconArrowRight,
  BigFrown: IconBigFrown,
  BigSmile: IconBigSmile,
  Cake: IconCake,
  Clock: IconClock,
  Close: IconClose,
  Complete: IconComplete,
  Continue: IconContinue,
  Dashboard: IconDashboard,
  Email: IconEmail,
  Delete: IconDelete,
  Feedback: IconFeedback,
  Hand: IconHand,
  Incomplete: IconIncomplete,
  Information: IconInformation,
  Logo: IconLogo,
  Logout: IconLogout,
  Mute: IconMute,
  NotSync: IconNotSync,
  Pause: IconPause,
  Person: IconPerson,
  Phone: IconPhone,
  Plus: IconPlus,
  Profile: IconProfile,
  Progress: IconProgress,
  Replay: IconReplay,
  SmallFrown: IconSmallFrown,
  SmallSmile: IconSmallSmile,
  Stop: IconStop,
  StraightFace: IconStraightFace,
  Support: IconSupport,
  Surveys: IconSurveys,
  Sync: IconSync,
  Syncing: IconSyncing,
  Technical: IconTechnical,
  Tick: IconTick,
  Voice: IconVoice,
  Volume: IconVolume,
  Calendar: IconCalendar,
  Increase: IconIncrease,
  Decrease: IconDecrease,
  ExistingSLK: IconExistingSLK,
  InvalidSLK: IconInvalidSLK,

  // Pie Chart Pieces
  ThreePie1: IconThreePieFirst,
  ThreePie2: IconThreePieSecond,
  ThreePie3: IconThreePieThird,
  FourPie1: IconFourPieFirst,
  FourPie2: IconFourPieSecond,
  FourPie3: IconFourPieThird,
  FourPie4: IconFourPieFourth
}

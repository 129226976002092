export enum GLOBAL_SHORTCODES {
  OUTRO_USING_TYPES = '[OUTRO_USING_TYPES]',
  OUTRO_RISK_LEVEL = '[ALCOHOL_RISK_LEVEL]',
  OUTRO_STANDARD_DRINKS = '[ALCOHOL_STANDARD_DRINKS]'
}

export enum OUTRO_USING_TYPES_TEXT {
  DRINKING = 'drinking',
  SMOKING = 'smoking',
  DRUGS = 'drugs',
  DANDS = 'drinking and smoking',
  DANDD = 'drinking and drugs',
  SANDD = 'smoking and drugs',
  ALL = 'drinking, smoking and drugs'
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import React, { Children, useEffect, useState } from 'react'
import styles from './Carousel.module.scss'
import classnames from 'classnames'
import Slider, { Settings } from 'react-slick'
import { Icon } from 'components/Icon/Icon.component'
import { IconType } from 'components/Icon/Icon.model'

import 'slick-carousel/slick/slick.css'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
)

export const Carousel: React.FC<Settings> = (props) => {
  const { children } = props
  const count = Children.count(children)
  const [updated, setUpdated] = useState<number>(0)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: classnames(styles['carousel__slides']),
    prevArrow: (
      <SlickButtonFix>
        <div>
          <Icon icon={IconType.ArrowLeft} size={36} border={true} />
        </div>
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <div>
          <Icon icon={IconType.ArrowRight} size={36} border={true} />
        </div>
      </SlickButtonFix>
    ),

    ...props
  }

  useEffect(() => {
    setUpdated(updated + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return (
    <div className={classnames(styles.carousel)} key={updated}>
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

import React from 'react'
import TextField from '@mui/material/TextField'
import { TextPropsModel } from './Text.model'
import styles from './Text.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'

export const Text: React.FC<TextPropsModel> = (props) => {
  const { label, className, textFieldProps, setValue, style } = props

  return (
    <div className={classnames(className, styles.text)}>
      {label && <p className={styles['text-title']}>{label}</p>}
      <div style={{ position: 'relative' }}>
        <Border type={BorderType.Select2} fill={style?.backgroundColor} />
        <TextField
          {...textFieldProps}
          className={styles['text-field']}
          label=""
          onChange={(e) => setValue && setValue(e.target.value)}
        />
      </div>
    </div>
  )
}

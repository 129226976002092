import { Button } from 'components/Button/Button.component'
import { IconType } from 'components/Icon/Icon.model'
import { Modal } from 'components/Modal/Modal.component'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { To, useNavigate, useSearchParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import { CONTINUE_LATER, LEAVE_SURVEY } from 'shared/constants/Constants.d'
import {
  DELETE_LEAVE_STUDY,
  DELETE_REFUSE_TAKE_PART
} from 'shared/constants/messages/Messages.d'
import { colors } from 'shared/theme/theme'
import { calculateTimeDiff } from 'shared/utils/calculateTimeDiff/calculateTimeDiff'
import {
  addCompletedSurvey,
  updateSurveyAnswer,
  updateContinueSurveyResponse,
  updateContinueLocalIdentifier,
  updateUserJourney,
  updateAnalytics,
  updateLastQTime,
  logout
} from 'store/reducer'
import { AnalyticsData, ISurveyData, SurveyState } from 'store/type'
import { FooterPropsModel } from './Footer.model'
import styles from './Footer.module.scss'

const FooterComponent: React.FC<FooterPropsModel & SurveyState> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const {
    id,
    demoMode,
    firstscreen,
    screenName,
    followUpQuestionName,
    nextScreenId,
    surveyEnd,
    invalid,
    customNextClick,
    customBackClick,
    activeAudio,
    playAudio,
    stopAudio,
    hasAudio,
    survey,
    surveyData,
    surveyMetaData,
    userJourney,
    analytics,
    drinkingSessions,
    lastQTime,
    continueLocalIdentifier,
    toggleNextButton = false
  } = props

  const startTime = surveyMetaData?.startTime || new Date()
  const resumeTime = surveyMetaData?.resumeTime
  const duration =
    surveyMetaData?.duration != null ? surveyMetaData?.duration : 0

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalChange, setModalChange] = useState<boolean>(false)
  const [demoModalChange, setDemoModalChange] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const onReplayClick = (stop: boolean) => () => {
    if (stop) {
      stopAudio?.()
    } else {
      playAudio?.(id)
    }
  }

  const onRouteChange = (route: To) => {
    stopAudio?.()
    navigate(route)
  }

  const addAnalyticsItem = () => {
    let analyticsItem
    let timesAnswered = 1

    if (analytics) {
      const analyticsItemIndex = analytics.findIndex(
        (item) => item && item.question === screenName
      )

      analyticsItem =
        analyticsItemIndex > -1 ? analytics[analyticsItemIndex] : undefined
    }

    const timeDiff = calculateTimeDiff(
      lastQTime || startTime,
      new Date(),
      analyticsItem?.totalTime != null ? analyticsItem?.totalTime : 0,
      false,
      true
    ) as number

    timesAnswered =
      (analyticsItem?.timesAnswered != null
        ? analyticsItem?.timesAnswered
        : 0) + 1

    // Add Analytics
    if (screenName) {
      dispatch(
        updateAnalytics({
          question: screenName,
          timesAnswered,
          totalTime: timeDiff,
          avgTime: timeDiff / timesAnswered
        } as AnalyticsData)
      )
    }
  }

  const onNextClick = () => {
    if (customNextClick) {
      customNextClick()
    } else {
      onRouteChange(`/survey/${demoMode ? 'demo/' : ''}${nextScreenId}`)

      // Update screen path
      dispatch(updateUserJourney(Number(nextScreenId)))

      // Update Analytics
      dispatch(updateLastQTime(new Date()))
      addAnalyticsItem()

      playAudio?.(nextScreenId)
    }
  }

  const onBackClick = () => {
    if (customBackClick) {
      customBackClick()
    } else {
      handleClearSavedData()

      onRouteChange(-1 as To)
      if (userJourney)
        playAudio?.(userJourney[userJourney.length - 2].toString())

      // Update screen path
      dispatch(updateUserJourney(-1))

      // Update Analytics
      dispatch(updateLastQTime(new Date()))
    }
  }

  const handleClearSavedData = () => {
    if (screenName && surveyData && surveyData[screenName]) {
      const reset: ISurveyData = {
        [screenName]: undefined
      }

      if (followUpQuestionName && surveyData[followUpQuestionName]) {
        reset[followUpQuestionName] = undefined
      }

      dispatch(updateSurveyAnswer(reset))
    }
  }

  const handleModalOpen = () => () => {
    setModalChange(false)
    stopAudio?.()
    setModalOpen(true)
  }

  const handleModalClose =
    (cancel: boolean, continueLater?: boolean, showFlagDelete?: boolean) =>
    () => {
      setModalOpen(false)
      if (cancel) {
        return
      }

      const endScreen = survey?.screens.find(
        (screen) =>
          screen.surveyField === (continueLater ? CONTINUE_LATER : LEAVE_SURVEY)
      )
      if (endScreen) {
        onRouteChange(
          `/survey/${demoMode ? 'demo/' : ''}${
            endScreen.id
          }?flagDelete=${showFlagDelete}`
        )
      }
    }

  const handleSubmit = (flagContinue: boolean, flagDelete: boolean) => {
    // Update screen path to empty on submit
    dispatch(updateUserJourney(undefined))
    addAnalyticsItem()

    // Stringify survey responses
    let tempSurveyData = { ...surveyData }
    if (tempSurveyData) {
      Object.keys(tempSurveyData).forEach((key) => {
        if (tempSurveyData[key] && typeof tempSurveyData[key] !== 'string') {
          tempSurveyData = Object.assign({}, tempSurveyData, {
            [key]: JSON.stringify(tempSurveyData[key])
          })
        }
      })
    }

    if (!continueLocalIdentifier) {
      dispatch(
        addCompletedSurvey({
          save: true,
          responses: tempSurveyData,
          metadata: updateSubmissionMetadata(flagContinue, flagDelete),
          userJourney: flagContinue ? userJourney : undefined,
          analytics,
          drinkingSessions
        })
      )
    } else {
      dispatch(updateContinueLocalIdentifier(continueLocalIdentifier))
      dispatch(
        updateContinueSurveyResponse({
          responses: { ...tempSurveyData },
          metadata: updateSubmissionMetadata(flagContinue, flagDelete),
          userJourney,
          analytics,
          drinkingSessions
        })
      )
    }
  }

  const updateSubmissionMetadata = (
    flagContinue: boolean,
    flagDelete: boolean
  ) => {
    let status = 'complete'
    if (flagDelete) {
      status = 'deletion'
    } else if (flagContinue) {
      status = 'incomplete'
    }

    const showFlag = Boolean(searchParams.get('flagDelete'))

    return {
      ...surveyMetaData,
      flaggedIncomplete: flagContinue || flagDelete,
      flaggedDeleted: flagDelete,
      deletionReason: flagDelete
        ? showFlag
          ? DELETE_LEAVE_STUDY
          : DELETE_REFUSE_TAKE_PART
        : undefined,
      breakPoint:
        userJourney && userJourney.length > 0
          ? userJourney?.at(-1)?.toString()
          : surveyMetaData?.breakPoint,
      flaggedComingBack: flagContinue,
      status,
      endTime: new Date(),
      duration:
        (calculateTimeDiff(resumeTime || startTime, new Date()) as number) +
        duration
    }
  }

  const handleSurveyFinish = () => {
    if (demoMode) {
      dispatch(logout())
      navigate('/ra-dashboard')
    } else {
      onRouteChange('/ra-dashboard/dashboard')
    }
  }

  // Demo Mode Skip Sections
  const getDemoModeSections = () => {
    const sectionInfo = [] as { name: string; screenId: string }[]
    let skipSection = ''
    survey?.screens.forEach((screen) => {
      if (
        screen.sectionName &&
        !sectionInfo.some((el) => el.name === screen.sectionName)
      ) {
        sectionInfo.push({
          name: screen.sectionName,
          screenId: screen.id
        })
      }

      if (screen.sectionName && screen.id === id) {
        skipSection = screen.sectionName
      }
    })

    return sectionInfo.filter((info) => info.name !== skipSection)
  }

  useEffect(() => {
    if (toggleNextButton) onNextClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleNextButton])

  useEffect(() => {
    if ((surveyEnd || !nextScreenId) && !demoMode) {
      handleSubmit(screenName === CONTINUE_LATER, screenName === LEAVE_SURVEY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextScreenId, surveyEnd])

  // Push first screenId into screen path
  useEffect(() => {
    if (firstscreen) {
      dispatch(updateUserJourney(Number(id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstscreen])

  return (
    <footer className={styles.footer}>
      <div className={styles.icons}>
        {hasAudio?.() && (
          <>
            {activeAudio ? (
              <Button
                noBorder
                icon={IconType.Stop}
                iconPosition="top"
                onClick={onReplayClick(true)}
                style={{ minWidth: '95px', padding: '0 5px !important' }}
              >
                Stop
              </Button>
            ) : (
              <Button
                noBorder
                icon={IconType.Replay}
                iconPosition="top"
                onClick={onReplayClick(false)}
                style={{
                  minWidth: '95px',
                  padding: '0 5px !important'
                }}
              >
                Replay
              </Button>
            )}
          </>
        )}
        {nextScreenId && !surveyEnd && (
          <Button
            noBorder
            icon={IconType.Pause}
            iconPosition="top"
            onClick={handleModalOpen()}
            style={{ padding: '0 5px !important' }}
          >
            Pause
          </Button>
        )}
      </div>
      <div className={styles.buttons}>
        {!firstscreen && !surveyEnd && (
          <Button variation="secondary" width="l" onClick={onBackClick}>
            Back
          </Button>
        )}
        {nextScreenId && !surveyEnd ? (
          <Button
            variation="primary"
            width="l"
            onClick={onNextClick}
            disabled={invalid}
          >
            Next
          </Button>
        ) : (
          <Button variation="primary" width="l" onClick={handleSurveyFinish}>
            Finish
          </Button>
        )}
      </div>
      <Modal
        className="footer-modal"
        style={{
          color: colors.white,
          backgroundColor: colors.black
        }}
        open={modalOpen}
        buttons={
          demoMode ? (
            demoModalChange ? (
              <div className="footer-modal__buttonsWrapper">
                <div className="footer-modal__skipButtons">
                  {getDemoModeSections().map((section, index) => (
                    <Button
                      key={index}
                      onClick={() =>
                        onRouteChange(`/survey/demo/${section.screenId}`)
                      }
                      style={{
                        backgroundColor: colors.white
                      }}
                    >
                      {section.name}
                    </Button>
                  ))}
                </div>
                <Button
                  className="footer-modal__resumeButton"
                  onClick={() => setDemoModalChange(false)}
                  style={{
                    color: `${colors.white} !important`
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="footer-modal__buttonsWrapper">
                <div className="footer-modal__buttons">
                  <Button
                    onClick={() => setDemoModalChange(true)}
                    style={{
                      backgroundColor: colors.white
                    }}
                  >
                    Skip to ...
                  </Button>
                  <Button
                    onClick={() => onRouteChange('/survey/demo')}
                    style={{
                      backgroundColor: colors.white
                    }}
                  >
                    Start over
                  </Button>
                  <Button
                    onClick={handleSurveyFinish}
                    style={{
                      backgroundColor: colors.white
                    }}
                  >
                    Quit demo mode
                  </Button>
                </div>
                <Button
                  className="footer-modal__resumeButton"
                  onClick={handleModalClose(true)}
                  icon={IconType.Replay}
                  iconProps={{
                    icon: IconType.Replay,
                    fill: colors.white,
                    size: 20
                  }}
                  borderColor={colors.white}
                  style={{
                    color: `${colors.white} !important`
                  }}
                >
                  Resume Survey
                </Button>
              </div>
            )
          ) : (
            <div className="footer-modal__buttons">
              <Button
                onClick={handleModalClose(true)}
                style={{
                  backgroundColor: colors.white
                }}
              >
                {modalChange
                  ? 'No, I want to continue the survey'
                  : 'Continue Survey'}
              </Button>
              <Button
                onClick={handleModalClose(false, true)}
                style={{
                  backgroundColor: colors.white
                }}
              >
                {modalChange
                  ? 'No, I want to come back to my survey later'
                  : 'I want to finish it later'}
              </Button>
              <Button
                onClick={
                  modalChange
                    ? handleModalClose(false, false, true)
                    : () => setModalChange(true)
                }
                style={{
                  backgroundColor: colors.white
                }}
              >
                {modalChange ? 'Yes' : 'I want to leave the study'}
              </Button>
            </div>
          )
        }
      >
        <h2>
          {modalChange ? (
            <span>
              Are you sure you want to stop your survey
              <br />
              (and not come back)?
            </span>
          ) : (
            'Your survey is paused'
          )}
        </h2>
      </Modal>
    </footer>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  survey: state.survey,
  surveyData: state.surveyData,
  surveyMetaData: state.surveyMetaData,
  continueLocalIdentifier: state.continueLocalIdentifier,
  userJourney: state.userJourney,
  analytics: state.analytics,
  lastQTime: state.lastQTime,
  drinkingSessions: state.drinkingSessions
})

export const Footer = connect(mapStateToProps)(FooterComponent)

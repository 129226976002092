import React, { HTMLAttributes } from 'react'

export enum InputErrorMessages {
  empty = 'Please fill the required fields',
  emailFormat = 'Please fill in a valid email address',
  phoneFormat = 'Please fill in a valid phone number',
  slkFormat = 'Invalid SLK format'
}

export enum InputFormat {
  email = 'email',
  phone = 'phone',
  slk = 'slk'
}

export interface InputOption {
  label?: string | null
  value?: string | null
  required: boolean
  allowMultipleLines: boolean
  inputFormat?: InputFormat | null
}

export interface InputPropsModel extends HTMLAttributes<HTMLElement> {
  inputOption: InputOption
  setValue: (value: string, error?: string) => void
}

import React from 'react'
import { BorderPropsModel, borderComponents } from './Border.model'
import classnames from 'classnames'
import styles from './Border.module.scss'
import { TitleBorder } from './borders/TitleBorder'

export const Border: React.FC<BorderPropsModel> = (props) => {
  const {
    className,
    type,
    stroke,
    fill,
    disabled,
    dropShadow,
    transform,
    withTitle,
    titleColor,
    titleHeight = 78,
    wideTitle,
    ...componentProps
  } = props

  const Component = borderComponents[type]

  const classes = classnames(className, styles.border, {
    [styles['border-dropShadow']]: dropShadow,
    [styles['border-transform']]: transform,
    [styles['border-withTitle']]: withTitle,
    [styles['border-disabled']]: disabled
  })

  return (
    <div className={classes}>
      {withTitle && (
        <TitleBorder
          wideTitle={wideTitle}
          className={styles['border-title']}
          stroke={stroke}
          fill={titleColor}
          style={{ height: titleHeight }}
        />
      )}
      <Component stroke={stroke} fill={fill} {...componentProps} />
    </div>
  )
}

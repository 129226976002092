import React, { useRef } from 'react'
import { InputErrorMessages, InputFormat, InputPropsModel } from './Input.model'
import styles from './Input.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { v4 as uuidv4 } from 'uuid'
import {
  validateEmail,
  validatePhone,
  validateSLK
} from 'shared/utils/validateValue/validateValue'

export const Input: React.FC<InputPropsModel> = (props) => {
  const { className, inputOption, setValue, style } = props

  const idRef = useRef<string>('')

  const getId = (): string => {
    if (!idRef.current) {
      idRef.current = uuidv4()
    }
    return idRef.current
  }

  const handleValueValidation = (
    value: string,
    required: boolean,
    format?: InputFormat | null
  ) => {
    if (required && value === '') {
      setValue(value, InputErrorMessages.empty)
      return
    }

    if (value !== '' && format === InputFormat.email && !validateEmail(value)) {
      setValue(value, InputErrorMessages.emailFormat)
      return
    }

    if (value !== '' && format === InputFormat.phone && !validatePhone(value)) {
      setValue(value, InputErrorMessages.phoneFormat)
      return
    }

    if (value !== '' && format === InputFormat.slk && !validateSLK(value)) {
      setValue(value, InputErrorMessages.slkFormat)
      return
    }

    setValue(value)
  }

  return (
    <div className={classnames(className, styles.input)}>
      <div style={{ position: 'relative' }}>
        <Border
          type={
            inputOption.allowMultipleLines
              ? BorderType.Modal
              : BorderType.Select2
          }
          fill={style?.backgroundColor}
        />
        <label id={'label' + getId()}>{inputOption.label}</label>
        <TextareaAutosize
          maxRows={inputOption.allowMultipleLines ? undefined : 1}
          minRows={inputOption.allowMultipleLines ? 12 : undefined}
          placeholder={`${inputOption.label ? inputOption.label : ''}${
            inputOption.required ? ' (required)' : ''
          }`}
          aria-labelledby={'label' + getId()}
          onChange={(e) =>
            handleValueValidation(
              e.target.value,
              inputOption.required,
              inputOption.inputFormat
            )
          }
        />
      </div>
    </div>
  )
}

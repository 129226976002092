import React, { useEffect, useState } from 'react'
import {
  GrogDiaryData,
  GrogDiaryDataDetail,
  IDrinkingSessionConsumption,
  ISurveyDrinkingSessions,
  SurveyState
} from 'store/type'
import {
  GrogDiaryScreenPropsModel,
  GrogDiaryScreenStatePropsModel,
  GrogDiaryStep
} from './GrogDiaryScreen.model'
import { connect, useDispatch } from 'react-redux'
import { Screen } from 'components/Screen/Screen.component'
import styles from './GrogDiaryScreen.module.scss'
import { CalendarMultiSelect } from 'components/GrogConsumptions/CalendarMultiSelect/CalendarMultiSelect.component'
import { MainColor, SecondaryColor } from 'components/Screen/Screen.model'
import { CalendarEvent } from 'components/GrogConsumptions/CalendarMultiSelect/CalendarMultiSelect.model'
import { ordinal_suffix_of } from 'shared/utils/ordinalSuffix/ordinalSuffix'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import {
  GrogAudioFragmentModel,
  GrogBrandModel,
  GrogContainerModel,
  GrogProductModel,
  GrogShopDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel
} from 'api/client.model'
import { GrogSlider } from 'components/GrogConsumptions/GrogSlider/GrogSlider.component'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Dispatch } from 'redux'
import { updateSurveyAnswer, updateDrinkingSession } from 'store/reducer'
import { AddMultiDate } from 'components/GrogConsumptions/AddMultiDate/AddMultiDate.component'
import { v4 as uuidv4 } from 'uuid'
import { GrogDiary } from 'components/GrogConsumptions/GrogDiary/GrogDiary.component'
import { VoiceOverTypes } from 'components/Header/Header.model'
import { genericObject } from '../type'
import { DrinkingCircleSlider } from 'components/GrogConsumptions/DrinkingCircleSlider/DrinkingCircleSlider.component'
import { AlcoholCalc } from 'shared/utils/alcoholCalculation/alcoholCalculation'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'

export const GrogDiaryScreenComponent: React.FC<
  GrogDiaryScreenPropsModel & GrogDiaryScreenStatePropsModel
> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()

  const {
    currentScreenId,
    name,
    theme,
    headerProps,
    screenData: {
      requireDiary,
      otherDayDiary,
      groupStdDrinksThreshold,
      peopleImages,
      justMeImage,
      groupImage,
      lastDay,
      howManyPeople,
      lastDayType,
      howMuchPutInIt,
      meOrGroup,
      share,
      anythingMoreToDrink,
      allYouHadToDrink,
      whenWasTheTimeBefore
    },
    footerProps,
    surveyData,
    events,
    birthday,
    voiceOver,
    typesCategories = []
  } = props

  const { getNextScreenId } = matchCondition()

  // Current Active Step
  const [step, setStep] = useState<GrogDiaryStep>(GrogDiaryStep.LASTDAY)

  const [date, setDate] = useState<Date>()
  const [people, setPeople] = useState<number>()

  const [activeTypesCats, setActiveTypesCats] = useState<string[]>([])
  const [activeSubType, setActiveSubType] = useState<string>()

  const [curTypeCat, setCurTypeCat] = useState<GrogTypeCategoryModel>()
  const [curSubType, setCurSubType] = useState<GrogSubTypeModel>()
  const [curBrand, setCurBrand] = useState<GrogBrandModel>()
  const [curProduct, setCurProduct] = useState<GrogProductModel>()
  const [curContainer, setCurContainer] = useState<GrogContainerModel>()
  const [curDrinkAmount, setCurDrinkAmount] = useState<number>()
  const [curContainerAmt, setCurContainerAmt] = useState<number | number[]>()
  const [curIsGroup, setCurIsGroup] = useState<string[]>()
  const [curShare, setCurShare] = useState<number>()
  const [curMore, setCurMore] = useState<string[]>()

  const [audio, setAudio] = useState<HTMLAudioElement>()
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const [moreThan12Months, setMoreThan12Months] = useState<boolean>(false)

  let audioFragments: genericObject | undefined

  useEffect(() => {
    setAudio(undefined)

    if (audio && !audio.paused) {
      audio.pause()
    }

    if (headerProps.voice && audioFragments) {
      const currentAudio = new Audio(audioFragments[headerProps.voice])
      setAudio(currentAudio)
      currentAudio.currentTime = 0
      currentAudio.play()
      currentAudio.onended = () => {
        setIsPlaying(false)
      }

      currentAudio.onpause = () => {
        setIsPlaying(false)
      }

      currentAudio.onplay = () => {
        setIsPlaying(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, audioFragments])

  useEffect(() => {
    if (audio) {
      audio.muted = !!headerProps.mute
    }
  }, [audio, headerProps.mute])

  const playAudioProxy = (key?: string, newVoice?: string) => {
    if (audio) {
      audio.pause()
    }
    footerProps?.playAudio?.(key, newVoice)
  }

  const diary =
    surveyData !== undefined && surveyData[name]
      ? typeof surveyData[name] == 'string'
        ? (JSON.parse(surveyData[name]) as { [key: string]: GrogDiaryData })
        : (surveyData[name] as { [key: string]: GrogDiaryData })
      : undefined

  const selectedDates = diary ? Object.keys(diary) : []

  /**
   * Get previous day based on the input date object
   * @param d Input date object
   * @returns Previous date object
   */
  const getPrevDate = (d: Date) => {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)
  }

  const maxDate = getPrevDate(new Date())
  const minDate = new Date(
    maxDate.getFullYear() - 1,
    maxDate.getMonth(),
    maxDate.getDate()
  )

  const eventList: CalendarEvent[] = (events ? events : []).map((e) => {
    const dateParts = e.date.split('/')
    return {
      ...e,
      date: new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0])
    } as CalendarEvent
  })

  const selectedDateList: CalendarEvent[] =
    selectedDates && selectedDates.length > 0
      ? selectedDates.map((d, index) => {
          return {
            name:
              index === 0
                ? 'Last day'
                : `${ordinal_suffix_of(index + 1)} last day`,
            date: new Date(d),
            selectedDate: true
          } as CalendarEvent
        })
      : []

  const getMaxDate = (): Date => {
    if (
      selectedDates &&
      selectedDates.length > 0 &&
      selectedDates.length < requireDiary + otherDayDiary
    ) {
      return getPrevDate(new Date(selectedDates[selectedDates.length - 1]))
    }
    return maxDate
  }

  const handleHeaderProp = () => {
    const updatedHeaderProps = { ...headerProps }
    let headerAudioFragments: genericObject | undefined

    switch (step) {
      case GrogDiaryStep.LASTDAY: {
        headerAudioFragments = lastDay || undefined
        break
      }

      case GrogDiaryStep.PEOPLE: {
        headerAudioFragments = howManyPeople || undefined
        break
      }

      case GrogDiaryStep.TYPESCATEGORIES: {
        headerAudioFragments = lastDayType || undefined
        break
      }

      case GrogDiaryStep.SUBTYPE: {
        if (curTypeCat?.audioFragments) {
          headerAudioFragments = getCurrentAudioFragments(
            curTypeCat.audioFragments,
            'what-types'
          )
        }

        break
      }

      case GrogDiaryStep.BRAND: {
        if (curSubType?.audioFragments) {
          headerAudioFragments = getCurrentAudioFragments(
            curSubType.audioFragments,
            'what-types'
          )
        }

        break
      }

      case GrogDiaryStep.PRODUCT: {
        if (curTypeCat?.audioFragments) {
          headerAudioFragments = getCurrentAudioFragments(
            curTypeCat?.audioFragments,
            'drink-out-of'
          )
        }

        if (curSubType?.audioFragments) {
          headerAudioFragments = getCurrentAudioFragments(
            curSubType?.audioFragments,
            'drink-out-of'
          )
        }
        break
      }

      case GrogDiaryStep.PRODUCT_CONTAINER: {
        if (curTypeCat?.audioFragments) {
          headerAudioFragments = getCurrentAudioFragments(
            curTypeCat?.audioFragments,
            'how-much'
          )
        }

        if (curSubType?.audioFragments) {
          headerAudioFragments = getCurrentAudioFragments(
            curSubType?.audioFragments,
            'how-much'
          )
        }
        break
      }

      case GrogDiaryStep.CUSTOM_CONTAINER: {
        headerAudioFragments = howMuchPutInIt || undefined
        break
      }

      case GrogDiaryStep.IS_GROUP: {
        headerAudioFragments = meOrGroup || undefined
        break
      }

      case GrogDiaryStep.SHARE: {
        headerAudioFragments = share || undefined
        break
      }

      case GrogDiaryStep.MORE: {
        headerAudioFragments = anythingMoreToDrink || undefined
        break
      }

      case GrogDiaryStep.OTHERDATE: {
        headerAudioFragments = allYouHadToDrink || undefined
        break
      }

      case GrogDiaryStep.DIARY: {
        headerAudioFragments = whenWasTheTimeBefore || undefined
        break
      }

      default:
        break
    }

    if (headerAudioFragments) {
      updatedHeaderProps.voiceOver = voiceOver?.filter((v) =>
        Object.keys(headerAudioFragments as genericObject).includes(v.value)
      ) as VoiceOverTypes[]
    }

    return updatedHeaderProps
  }

  const getCurrentAudioFragments = (
    currentAudioFragments: GrogAudioFragmentModel[],
    key: string
  ) => {
    return currentAudioFragments.find((a) => a.name === key)?.voices
  }

  const { calcStandardDrinks } = AlcoholCalc()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    updatedFooterProps.hasAudio = () => !!audio

    updatedFooterProps.stopAudio = () => {
      audio?.pause()
    }

    if (isPlaying) {
      updatedFooterProps.activeAudio = audio
    }

    updatedFooterProps.invalid = true
    let lastScreen = false

    switch (step) {
      case GrogDiaryStep.LASTDAY: {
        if (date || selectedDateList.length === requireDiary + otherDayDiary) {
          updatedFooterProps.invalid = false
        }

        if (lastDay) {
          audioFragments = lastDay
        }

        if (selectedDateList.length > 0) {
          // Hide back button on the screen
          updatedFooterProps.firstscreen = true
        }

        updatedFooterProps.customNextClick = () => {
          if (date && selectedDateList.length <= requireDiary - 1) {
            if (selectedDateList.length == 0) {
              setStep(GrogDiaryStep.PEOPLE)
            } else {
              setStep(GrogDiaryStep.TYPESCATEGORIES)
            }
            return
          } else if (
            date &&
            selectedDateList.length < requireDiary + otherDayDiary
          ) {
            dispatch(
              updateSurveyAnswer({
                [name]: {
                  ...diary,
                  [date?.toISOString()]: {}
                }
              })
            )
          }
          setStep(GrogDiaryStep.OTHERDATE)
        }
        break
      }

      case GrogDiaryStep.PEOPLE: {
        if (people != null && people !== 0) {
          updatedFooterProps.invalid = false
        }

        if (howManyPeople) {
          audioFragments = howManyPeople
        }

        updatedFooterProps.customNextClick = () => {
          setStep(GrogDiaryStep.TYPESCATEGORIES)
        }
        updatedFooterProps.customBackClick = () => {
          setDate(undefined)
          setPeople(undefined)
          setStep(GrogDiaryStep.LASTDAY)
        }
        break
      }

      case GrogDiaryStep.TYPESCATEGORIES: {
        if (activeTypesCats.length > 0) {
          updatedFooterProps.invalid = false
        }

        if (lastDayType) {
          audioFragments = lastDayType
        }

        if (
          date &&
          diary &&
          diary[date.toISOString()] &&
          diary[date.toISOString()].consumptions.length > 0
        ) {
          // Hide back button on the screen
          updatedFooterProps.firstscreen = true
        }

        updatedFooterProps.customNextClick = () => {
          const [activeTypesCategoriesKey] = activeTypesCats

          const activeTypesCategories = typesCategories.reduce((acc, cv) => {
            if (cv.name === activeTypesCategoriesKey) {
              acc = cv
            }
            return acc
          })

          if ((activeTypesCategories as GrogTypeCategoryModel).subTypes) {
            setCurTypeCat(activeTypesCategories as GrogTypeCategoryModel)
            setStep(GrogDiaryStep.SUBTYPE)
          } else {
            setCurSubType(activeTypesCategories as GrogSubTypeModel)
            setStep(GrogDiaryStep.BRAND)
          }
        }
        updatedFooterProps.customBackClick = () => {
          if (selectedDateList.length !== 0) {
            setDate(undefined)
            setPeople(undefined)
            setStep(GrogDiaryStep.LASTDAY)
          } else {
            setCurTypeCat(undefined)
            setActiveTypesCats([])
            setPeople(undefined)
            setStep(GrogDiaryStep.PEOPLE)
          }
        }
        break
      }

      case GrogDiaryStep.SUBTYPE: {
        if (activeSubType) {
          updatedFooterProps.invalid = false
        }

        if (curTypeCat?.audioFragments) {
          audioFragments = getCurrentAudioFragments(
            curTypeCat.audioFragments,
            'what-types'
          )
        }

        updatedFooterProps.customNextClick = () => {
          const updatedCurSubType = curTypeCat?.subTypes?.reduce((acc, cv) => {
            if (cv.name === activeSubType) {
              acc = cv
            }
            return acc
          })
          setCurSubType(updatedCurSubType)

          if (updatedCurSubType?.hasBrand || updatedCurSubType?.brands) {
            setStep(GrogDiaryStep.BRAND)
          } else {
            setStep(GrogDiaryStep.PRODUCT)
          }
        }
        updatedFooterProps.customBackClick = () => {
          setActiveSubType(undefined)
          setCurSubType(undefined)
          setCurTypeCat(undefined)
          setActiveTypesCats([])
          setStep(GrogDiaryStep.TYPESCATEGORIES)
        }
        break
      }

      case GrogDiaryStep.BRAND: {
        if (curBrand) {
          updatedFooterProps.invalid = false
        }

        if (curSubType?.audioFragments) {
          audioFragments = getCurrentAudioFragments(
            curSubType?.audioFragments,
            'what-types'
          )
        }

        updatedFooterProps.customNextClick = () => {
          setStep(GrogDiaryStep.PRODUCT)
        }

        updatedFooterProps.customBackClick = () => {
          setCurBrand(undefined)
          if (curTypeCat) {
            setCurSubType(undefined)
            setActiveSubType(undefined)
            setStep(GrogDiaryStep.SUBTYPE)
          } else {
            setActiveTypesCats([])
            setCurTypeCat(undefined)
            setStep(GrogDiaryStep.TYPESCATEGORIES)
          }
        }

        break
      }

      case GrogDiaryStep.PRODUCT: {
        if (curContainer || curProduct) {
          updatedFooterProps.invalid = false
        }

        if (curTypeCat?.audioFragments) {
          audioFragments = getCurrentAudioFragments(
            curTypeCat?.audioFragments,
            'drink-out-of'
          )
        }

        if (curSubType?.audioFragments) {
          audioFragments = getCurrentAudioFragments(
            curSubType?.audioFragments,
            'drink-out-of'
          )
        }

        updatedFooterProps.customNextClick = () => {
          if (curContainer) {
            setStep(GrogDiaryStep.CUSTOM_CONTAINER)
          } else {
            setStep(GrogDiaryStep.PRODUCT_CONTAINER)
          }
        }

        updatedFooterProps.customBackClick = () => {
          setCurContainer(undefined)
          setCurProduct(undefined)
          if (curBrand) {
            setCurBrand(undefined)
            setStep(GrogDiaryStep.BRAND)
          } else {
            if (curTypeCat) {
              setCurSubType(undefined)
              setActiveSubType(undefined)
              setStep(GrogDiaryStep.SUBTYPE)
            } else {
              setActiveTypesCats([])
              setCurTypeCat(undefined)
              setStep(GrogDiaryStep.TYPESCATEGORIES)
            }
          }
        }

        break
      }

      case GrogDiaryStep.PRODUCT_CONTAINER: {
        if (curDrinkAmount != null && curDrinkAmount !== 0) {
          updatedFooterProps.invalid = false
        }

        if (curTypeCat?.audioFragments) {
          audioFragments = getCurrentAudioFragments(
            curTypeCat?.audioFragments,
            'how-much'
          )
        }

        if (curSubType?.audioFragments) {
          audioFragments = getCurrentAudioFragments(
            curSubType?.audioFragments,
            'how-much'
          )
        }

        updatedFooterProps.customNextClick = () => {
          // Calc standard drink for current consumption and check if more than threshold
          const standardDrinks = calcStandardDrinks({
            uuid: '',
            containerGrog: Array.isArray(curContainerAmt)
              ? curContainerAmt[0]
              : curContainerAmt,
            drinkAmount:
              curShare != null && curShare !== 0 ? curShare : curDrinkAmount,
            containerCapacity: curContainer?.capacity,
            productCapacity: curProduct?.capacity,
            stepsPerSubContainer: curProduct?.stepsPerSubContainer,
            subContainer: curProduct?.subContainer,
            alcoholPecentage: curBrand?.alcoholPercent
              ? parseFloat(curBrand.alcoholPercent)
              : curSubType?.alcoholPercent
              ? parseFloat(curSubType.alcoholPercent)
              : undefined
          })

          if (
            people != null &&
            people > 1 &&
            standardDrinks >
              (groupStdDrinksThreshold != null ? groupStdDrinksThreshold : 0)
          ) {
            setStep(GrogDiaryStep.IS_GROUP)
          } else {
            addToDiary()
            goToNextCycleOrMore()
          }
        }

        updatedFooterProps.customBackClick = () => {
          setCurDrinkAmount(undefined)

          if (curContainer) {
            setCurContainerAmt(undefined)
            setStep(GrogDiaryStep.CUSTOM_CONTAINER)
          } else {
            setCurContainer(undefined)
            setCurProduct(undefined)

            setStep(GrogDiaryStep.PRODUCT)
          }
        }

        break
      }

      case GrogDiaryStep.CUSTOM_CONTAINER: {
        if (curContainerAmt) {
          updatedFooterProps.invalid = false
        }

        if (howMuchPutInIt) {
          audioFragments = howMuchPutInIt
        }

        updatedFooterProps.customNextClick = () => {
          setStep(GrogDiaryStep.PRODUCT_CONTAINER)
        }

        updatedFooterProps.customBackClick = () => {
          setCurContainerAmt(undefined)
          setCurContainer(undefined)
          setCurProduct(undefined)
          setStep(GrogDiaryStep.PRODUCT)
        }

        break
      }

      case GrogDiaryStep.IS_GROUP: {
        if (curIsGroup) {
          updatedFooterProps.invalid = false
        }

        if (meOrGroup) {
          audioFragments = meOrGroup
        }

        updatedFooterProps.customNextClick = () => {
          if (curIsGroup?.includes('group')) {
            setStep(GrogDiaryStep.SHARE)
          } else {
            addToDiary()
            goToNextCycleOrMore()
          }
        }

        updatedFooterProps.customBackClick = () => {
          setCurDrinkAmount(undefined)
          setCurIsGroup(undefined)
          setStep(GrogDiaryStep.PRODUCT_CONTAINER)
        }

        break
      }

      case GrogDiaryStep.SHARE: {
        if (curShare != null && curShare !== 0) {
          updatedFooterProps.invalid = false
        }

        if (share) {
          audioFragments = share
        }

        updatedFooterProps.customNextClick = () => {
          addToDiary()
          goToNextCycleOrMore()
        }

        updatedFooterProps.customBackClick = () => {
          setStep(GrogDiaryStep.IS_GROUP)
          setCurIsGroup(undefined)
          setCurShare(undefined)
        }

        break
      }

      case GrogDiaryStep.MORE: {
        if (curMore) {
          updatedFooterProps.invalid = false
        }

        if (anythingMoreToDrink) {
          audioFragments = anythingMoreToDrink
        }

        // Hide back button on the screen
        updatedFooterProps.firstscreen = true

        updatedFooterProps.customNextClick = () => {
          if (curMore?.includes('yes')) {
            resetState()
            setStep(GrogDiaryStep.TYPESCATEGORIES)
          } else {
            setStep(GrogDiaryStep.DIARY)
          }
        }
        break
      }

      case GrogDiaryStep.DIARY: {
        // disable the next button when item's drinkAmount in consumptions list is 0
        if (date && diary && diary[date.toISOString()]) {
          updatedFooterProps.invalid =
            diary[date.toISOString()].consumptions?.filter((item) =>
              Boolean(item?.drinkAmount)
            ).length === 0
        }
        if (allYouHadToDrink) {
          audioFragments = allYouHadToDrink
        }

        // Hide back button on the screen
        updatedFooterProps.firstscreen = true

        updatedFooterProps.customNextClick = () => {
          resetState(true)
          if (selectedDateList.length === requireDiary) {
            setStep(GrogDiaryStep.OTHERDATE)
          } else {
            setStep(GrogDiaryStep.LASTDAY)
          }
        }
        break
      }

      case GrogDiaryStep.OTHERDATE: {
        if (
          (diary &&
            Object.keys(diary).length === requireDiary + otherDayDiary) ||
          moreThan12Months
        ) {
          updatedFooterProps.invalid = false

          // Next Screen Condition Logic
          updatedFooterProps.nextScreenId = getNextScreenId(
            undefined,
            footerProps.conditions,
            footerProps.nextScreenId
          )

          lastScreen = true
        }

        if (whenWasTheTimeBefore) {
          audioFragments = whenWasTheTimeBefore
        }

        if (selectedDateList.length > 0) {
          // Hide back button on the screen
          updatedFooterProps.firstscreen = true
        }

        break
      }

      default:
        break
    }

    if (!lastScreen) {
      updatedFooterProps.playAudio = () => {
        if (audio) {
          audio.currentTime = 0
          audio.play()
        }
      }
    }

    // Update drinking sessions data model with grog diary on screen change to catch all dates
    if (diary) {
      const drinkingSessions = Object.keys(diary).map(
        (key): ISurveyDrinkingSessions => {
          // Sum all alcohol in drinking session
          let gramsAlcohol = 0

          const consumptions = diary[key]?.consumptions ?? []

          const transformedConsumptions = consumptions?.map(
            (consumption: GrogDiaryDataDetail): IDrinkingSessionConsumption => {
              // Calc grams alcohol for each consumption by multiplying standard drinks by 10 and add to total for drinking session
              const consumptionGramsAlcohol =
                calcStandardDrinks(consumption) * 10
              gramsAlcohol += consumptionGramsAlcohol

              return {
                category: consumption?.typecategory ?? null,
                subcategory: consumption?.subtype ?? null,
                unitsPerItem: consumption?.subContainer ?? null,
                units: consumption?.drinkAmount ?? null,
                fullness: consumption?.containerGrog ?? null,
                mixer: consumption?.containerFizzy ?? null,
                productCode: consumption?.uuid ?? null,
                brand: consumption?.brand ?? null,
                container: consumption?.container ?? null,
                displayText: consumption?.product ?? null,
                gramsAlcohol: consumptionGramsAlcohol ?? null
              }
            }
          )

          return {
            drinkingDate: key,
            gramsAlcohol: gramsAlcohol ?? null,
            consumption: transformedConsumptions
          }
        }
      )

      dispatch(updateDrinkingSession(drinkingSessions))
    }

    return updatedFooterProps
  }

  // reset react state
  const resetState = (newDate = false) => {
    if (newDate) {
      setDate(undefined)
      setPeople(undefined)
    }

    setActiveSubType(undefined)
    setCurTypeCat(undefined)
    setCurSubType(undefined)
    setCurBrand(undefined)
    setCurProduct(undefined)
    setCurContainer(undefined)
    setCurDrinkAmount(undefined)
    setCurContainerAmt(undefined)
    setCurIsGroup(undefined)
    setCurShare(undefined)
    setCurMore(undefined)
  }

  // add to diary redux state
  const addToDiary = () => {
    if (!date) return

    const updatedDiary = { ...diary }

    if (!(date.toISOString() in updatedDiary)) {
      updatedDiary[date.toISOString()] = {
        people,
        consumptions: []
      }
    }

    updatedDiary[date.toISOString()] = {
      ...updatedDiary[date.toISOString()],
      consumptions: [
        ...updatedDiary[date.toISOString()].consumptions,
        {
          uuid: uuidv4(),
          typecategory: curTypeCat?.name,
          subtype: curSubType?.name,
          brand: curBrand?.name,
          product: curProduct?.name,
          productImage: curProduct?.image,
          productCapacity: curProduct?.capacity,
          stepsPerSubContainer: curProduct?.stepsPerSubContainer,
          subContainer: curProduct?.subContainer,
          container: curContainer?.localDisplayName || curContainer?.name,
          containerCapacity: curContainer?.capacity,
          containerImage: curContainer?.image,
          containerTopBorder: curContainer?.topBorder,
          containerBottomBorder: curContainer?.bottomBorder,
          containerColor: curBrand?.colour || curSubType?.colour,
          containerFillMaskImage: curContainer?.fillMaskImage,
          drinkAmount:
            curShare != null && curShare !== 0 ? curShare : curDrinkAmount,
          containerGrog: Array.isArray(curContainerAmt)
            ? curContainerAmt[0]
            : curContainerAmt,
          containerFizzy: Array.isArray(curContainerAmt)
            ? curContainerAmt[1] - curContainerAmt[0]
            : undefined,
          alcoholPecentage: curBrand?.alcoholPercent
            ? parseFloat(curBrand.alcoholPercent)
            : curSubType?.alcoholPercent
            ? parseFloat(curSubType.alcoholPercent)
            : undefined
        }
      ]
    }

    dispatch(
      updateSurveyAnswer({
        [name]: updatedDiary
      })
    )
  }

  const goToNextCycleOrMore = () => {
    const newState = [...activeTypesCats]
    newState.shift()

    setActiveTypesCats(newState)
    resetState()
    if (newState.length > 0) {
      const [activeTypesCategoriesKey] = newState

      const activeTypesCategories = typesCategories.reduce((acc, cv) => {
        if (cv.name === activeTypesCategoriesKey) {
          acc = cv
        }
        return acc
      })

      if ((activeTypesCategories as GrogTypeCategoryModel).subTypes) {
        setCurTypeCat(activeTypesCategories as GrogTypeCategoryModel)
        setStep(GrogDiaryStep.SUBTYPE)
      } else {
        setCurSubType(activeTypesCategories as GrogSubTypeModel)
        setStep(GrogDiaryStep.BRAND)
      }
    } else {
      setStep(GrogDiaryStep.MORE)
    }
  }

  const onDiaryIncrease = (uuid: string) => {
    if (date && diary) {
      dispatch(
        updateSurveyAnswer({
          [name]: {
            ...diary,
            [date?.toISOString()]: {
              ...diary[date.toISOString()],
              consumptions: diary[date.toISOString()].consumptions.map((c) => {
                if (c.uuid === uuid) {
                  return {
                    ...c,
                    drinkAmount:
                      c.drinkAmount != null && c.drinkAmount !== 0
                        ? c.drinkAmount + 1
                        : 1
                  }
                }
                return c
              })
            }
          }
        })
      )
    }
  }

  const onDiaryDecrease = (uuid: string) => {
    if (date && diary) {
      dispatch(
        updateSurveyAnswer({
          [name]: {
            ...diary,
            [date?.toISOString()]: {
              ...diary[date.toISOString()],
              consumptions: diary[date.toISOString()].consumptions.map((c) => {
                if (c.uuid === uuid) {
                  return {
                    ...c,
                    drinkAmount:
                      c.drinkAmount != null && c.drinkAmount !== 0
                        ? c.drinkAmount - 1
                        : 0
                  }
                }
                return c
              })
            }
          }
        })
      )
    }
  }

  /**
   * Render component based on current step
   */
  const renderStep = () => {
    switch (step) {
      case GrogDiaryStep.LASTDAY:
        return (
          <CalendarMultiSelect
            title={'When was the last day you had any grog?'}
            notice={
              selectedDates.length < requireDiary + otherDayDiary
                ? 'please select the last date when you had a drink'
                : `You've selected all ${selectedDates.length} dates`
            }
            selectRange={false}
            setValue={setDate}
            color={SecondaryColor[theme]}
            maxDate={getMaxDate()}
            minDate={minDate}
            events={[...eventList, ...selectedDateList]}
            birthday={birthday}
            isMoreThan12MonthsShow={
              selectedDates.length >= 1 &&
              selectedDates.length < requireDiary + otherDayDiary
                ? true
                : false
            }
            moreThan12MonthsEvent={() => {
              setMoreThan12Months(true)
              setStep(GrogDiaryStep.OTHERDATE)
            }}
            selectionDisabled={
              selectedDates.length === requireDiary + otherDayDiary
            }
          />
        )

      case GrogDiaryStep.PEOPLE:
        return (
          <DrinkingCircleSlider
            title="How many people were you drinking grog with?"
            subtitle="(Drag how many people were in your drinking circle, including you)"
            images={peopleImages}
            defaultValue={people != null && people !== 0 ? people : 1}
            setValue={(v) => {
              setPeople(v as number)
            }}
            color={SecondaryColor[theme]}
          />
        )

      case GrogDiaryStep.TYPESCATEGORIES:
        return (
          <MultipleChoice
            title="On that last day, what types of grog were you drinking that day?"
            subtitle="Choose all the types of grog that you drank that time"
            isMultiple={true}
            options={typesCategories.map(
              (v) =>
                ({
                  id: v?.id ? `${currentScreenId}-${v?.id}` : undefined,
                  image: v.image,
                  label: v.name,
                  value: v.name,
                  voices: v?.voices,
                  selected: activeTypesCats.includes(v.name)
                } as MultipleChoiceModel)
            )}
            isCarousel={true}
            columnNum={typesCategories.length > 4 ? typesCategories.length : 4}
            setValue={setActiveTypesCats}
            color={SecondaryColor[theme]}
            playAudio={playAudioProxy}
          />
        )

      case GrogDiaryStep.SUBTYPE:
        return (
          curTypeCat && (
            <MultipleChoice
              title={`What types of <u>${curTypeCat.name.toLocaleLowerCase()}</u> were you drinking that day?`}
              options={
                curTypeCat.subTypes?.map(
                  (v) =>
                    ({
                      id: v?.id ? `${currentScreenId}-${v?.id}` : undefined,
                      image: v.image,
                      label: v.name,
                      value: v.name,
                      selected: v.name === activeSubType,
                      voices: v?.voices
                    } as MultipleChoiceModel)
                ) ?? []
              }
              setValue={([value]) => {
                setActiveSubType(value)
              }}
              columnNum={4}
              isCarousel={true}
              color={SecondaryColor[theme]}
              playAudio={playAudioProxy}
            />
          )
        )

      case GrogDiaryStep.BRAND:
        return (
          <MultipleChoice
            title={`What types of <u>${curSubType?.name.toLowerCase()}</u> were you drinking that day?`}
            options={(curSubType as GrogSubTypeModel).brands.map(
              (v, index) =>
                ({
                  image: v.image,
                  label: v.name,
                  value: index.toString(),
                  selected: v === curBrand
                } as MultipleChoiceModel)
            )}
            setValue={([v]) => {
              setCurBrand((curSubType as GrogSubTypeModel).brands[parseInt(v)])
            }}
            columnNum={4}
            isCarousel={true}
            color={SecondaryColor[theme]}
          />
        )
      case GrogDiaryStep.PRODUCT: {
        const brandOptions =
          curBrand && curBrand.products
            ? curBrand.products.map(
                (v, index) =>
                  ({
                    image: v.image,
                    label: v.name,
                    value: `product_${index.toString()}`,
                    selected: v === curProduct
                  } as MultipleChoiceModel)
              )
            : []

        const subtypeOptions =
          curSubType && curSubType.containers
            ? curSubType.containers.map(
                (v, index) =>
                  ({
                    image: v.carouselImage,
                    label: `${v.localDisplayName || v.name} (${
                      v.displayCapacity
                    })`,
                    value: `container_${index.toString()}`,
                    selected: v === curContainer
                  } as MultipleChoiceModel)
              )
            : []

        return (
          <MultipleChoice
            title={`What did you mostly drink <u>${curSubType?.name.toLowerCase()}</u> out of?`}
            options={[...brandOptions, ...subtypeOptions]}
            isCarousel={true}
            setValue={([v]) => {
              const [type, index] = v.split('_')

              if (type === 'product' && curBrand) {
                setCurProduct(curBrand.products[Number(index)])
                setCurContainer(undefined)
              } else if (type === 'container' && curSubType) {
                setCurProduct(undefined)
                setCurContainer(curSubType.containers[Number(index)])
              }
            }}
            columnNum={4}
            color={SecondaryColor[theme]}
          />
        )
      }

      case GrogDiaryStep.PRODUCT_CONTAINER:
        if (curProduct) {
          return (
            <GrogSlider
              key={'product_slider'}
              color={SecondaryColor[theme]}
              title={`How much <u>${curSubType?.name.toLowerCase()}</u> were you drinking that day?`}
              image={curProduct?.image}
              min={1}
              max={20}
              vertical={false}
              subContainer={curProduct?.subContainer}
              stepsPerSubContainer={curProduct?.stepsPerSubContainer}
              setValue={(value) => {
                setCurDrinkAmount(Number(value))
              }}
              defaultValue={curDrinkAmount}
            />
          )
        } else {
          return (
            <GrogSlider
              key={'container_slider'}
              color={SecondaryColor[theme]}
              title={`How many ${
                curContainer?.localDisplayNamePlural
                  ? curContainer?.localDisplayNamePlural.toLowerCase()
                  : curContainer?.displayNamePlural.toLowerCase()
              } of <u>${curSubType?.name.toLowerCase()}</u> were you drinking that day?`}
              image={curContainer?.carouselImage}
              min={1}
              max={20}
              vertical={false}
              setValue={(value) => {
                setCurDrinkAmount(Number(value))
              }}
              defaultValue={curDrinkAmount}
              customComponent={
                <Container
                  image={curContainer?.image}
                  fillMaskImage={curContainer?.fillMaskImage}
                  topBorder={curContainer?.topBorder}
                  bottomBorder={curContainer?.bottomBorder}
                  min={0}
                  max={100}
                  maxHeight={100}
                  drinkValue={
                    (curContainerAmt as number[])[0] ||
                    (curContainerAmt as number)
                  }
                  fizzyDrinkValue={(curContainerAmt as number[])[1]}
                  drinkColor={curBrand?.colour || curSubType?.colour}
                />
              }
            />
          )
        }

      case GrogDiaryStep.CUSTOM_CONTAINER:
        return (
          <GrogSlider
            key={'container_vertical_slider'}
            color={SecondaryColor[theme]}
            title={`How much <u>${curSubType?.name.toLowerCase()}</u> did you put in it?`}
            subtitle={
              curSubType?.addFizzyDrink
                ? 'If you put any fizzy drink or juice, please add it as well.'
                : undefined
            }
            image={curContainer?.image}
            fillMaskImage={curContainer?.fillMaskImage}
            topBorder={curContainer?.topBorder}
            bottomBorder={curContainer?.bottomBorder}
            vertical={true}
            addFizzyDrink={curSubType?.addFizzyDrink}
            setValue={setCurContainerAmt}
            drinkColor={curBrand?.colour || curSubType?.colour}
            bgColor={MainColor[theme]}
            defaultValue={curContainerAmt}
          />
        )

      case GrogDiaryStep.IS_GROUP:
        return (
          <MultipleChoice
            title={
              'Just want to check, is this what you had or what the whole group had?'
            }
            options={[
              {
                image: justMeImage,
                label: 'Just me',
                value: 'me',
                selected: curIsGroup?.includes('me')
              },
              {
                image: groupImage,
                label: 'The group',
                value: 'group',
                selected: curIsGroup?.includes('group')
              }
            ]}
            setValue={setCurIsGroup}
            color={SecondaryColor[theme]}
          />
        )

      case GrogDiaryStep.SHARE:
        if (
          curProduct &&
          curDrinkAmount != null &&
          curDrinkAmount !== 0 &&
          curProduct.subContainer &&
          curProduct.stepsPerSubContainer
        ) {
          const newMax = Math.ceil(
            curDrinkAmount /
              (curProduct.stepsPerSubContainer === 1
                ? curProduct.subContainer
                : curProduct.stepsPerSubContainer)
          )

          return (
            <GrogSlider
              key={'product_slider'}
              color={SecondaryColor[theme]}
              title={
                'Sometimes when people drink together, they have more than others. That day, what was your share?'
              }
              subtitle={`(${curProduct.displayCapacity})`}
              image={curProduct?.image}
              min={1}
              max={newMax}
              isShare={true}
              shareMax={curDrinkAmount}
              vertical={false}
              subContainer={curProduct?.subContainer}
              stepsPerSubContainer={curProduct?.stepsPerSubContainer}
              setValue={(value) => {
                setCurShare(Number(value))
              }}
            />
          )
        } else {
          return (
            <GrogSlider
              key={'container_slider'}
              color={SecondaryColor[theme]}
              title={
                'Sometimes when people drink together, they have more than others. That day, what was your share?'
              }
              subtitle={`(${curContainer?.displayCapacity})`}
              image={curContainer?.carouselImage}
              min={1}
              max={curDrinkAmount}
              isShare={true}
              shareMax={curDrinkAmount}
              vertical={false}
              setValue={(value) => {
                setCurShare(Number(value))
              }}
              customComponent={
                <Container
                  image={curContainer?.image}
                  fillMaskImage={curContainer?.fillMaskImage}
                  topBorder={curContainer?.topBorder}
                  bottomBorder={curContainer?.bottomBorder}
                  min={0}
                  max={100}
                  maxHeight={100}
                  drinkValue={
                    Array.isArray(curContainerAmt)
                      ? curContainerAmt[0]
                      : curContainerAmt
                  }
                  fizzyDrinkValue={(curContainerAmt as number[])[1]}
                  drinkColor={curBrand?.colour || curSubType?.colour}
                />
              }
            />
          )
        }

      case GrogDiaryStep.MORE:
        return (
          <MultipleChoice
            title={'Did you have anything more to drink on this day?'}
            options={[
              {
                label: 'Yes',
                value: 'yes',
                selected: curMore?.includes('yes')
              },
              {
                label: 'No',
                value: 'no',
                selected: curMore?.includes('no')
              }
            ]}
            setValue={setCurMore}
            color={SecondaryColor[theme]}
          />
        )

      case GrogDiaryStep.DIARY: {
        if (date && diary && diary[date.toISOString()]) {
          return (
            <GrogDiary
              title="On the same day, was this all you had to drink?"
              date={date.toISOString()}
              consumptions={diary[date.toISOString()].consumptions}
              onDiaryIncrease={onDiaryIncrease}
              onDiaryDecrease={onDiaryDecrease}
              theme={theme}
              onYesClick={() => {
                resetState(true)
                if (selectedDateList.length === requireDiary) {
                  setStep(GrogDiaryStep.OTHERDATE)
                } else {
                  setStep(GrogDiaryStep.LASTDAY)
                }
              }}
              onNoClick={() => {
                resetState()
                setStep(GrogDiaryStep.TYPESCATEGORIES)
              }}
            />
          )
        }
        break
      }

      case GrogDiaryStep.OTHERDATE:
        return (
          <AddMultiDate
            title={
              'Thanks. When was the time before that when you had a drink?'
            }
            dates={selectedDates.map((d) => new Date(d))}
            color={SecondaryColor[theme]}
            addDate={() => {
              setStep(GrogDiaryStep.LASTDAY)
            }}
            requiredDate={requireDiary + otherDayDiary}
            disabled={moreThan12Months}
          />
        )

      default:
        break
    }
  }

  return (
    <div className={`drug-app-screen  ${styles.grogdiary}`}>
      <Screen
        currentScreenId={currentScreenId}
        theme={theme}
        headerProps={handleHeaderProp()}
        footerProps={handleFooterProps()}
      >
        {renderStep()}
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  birthday: (state.surveyData && state.surveyData['birthday']) as string,
  events: state.service?.projects.find(
    (project) => project.id === state.surveyMetaData?.projectId
  )?.events,
  surveyData: state.surveyData,
  voiceOver: state.survey?.voiceOver,
  typesCategories: (
    (state.user?.id === 'demo' ? state.demoGrogShops : state.grogShops)?.find(
      (shop) => shop.Id === state.surveyMetaData?.shopId
    ) as GrogShopDataModel
  ).typesCategories
})

export const GrogDiaryScreen = connect(mapStateToProps)(
  GrogDiaryScreenComponent
)
